import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllInventoryItems } from "../../../redux/actions/InventoryAction";
import { API_URI, invParents } from "../../../constants/GlobalConstants";
import AddCustomMaterialModal from "../ui/AddCustomMaterialModal";
import Loader from "../../layout/Loader";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const MaterialOrdersTable = ({
  materialOrders = [],
  handleAddMaterialOrder,
  handleRemoveMaterialOrder,
  showAddMaterialModal,
  setShowAddMaterialModal,
  dealer,
  setMaterialOrders,
}) => {
  const dispatch = useDispatch();
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const { data: inventoryData = [], loading } = inventoryItems;
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const [currentCategories, setCurrentCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dealerName, dealerId] = dealer.includes('_') ? dealer.split('_') : [dealer, dealer];
  const [pricingData, setPricingData] = useState([]);
  const [showPriceNotification, setShowPriceNotification] = useState(false);
  const [priceEditLink, setPriceEditLink] = useState("");

  useEffect(() => {
    if (userInfo?.token && (!inventoryData.length || loading)) {
      dispatch(getAllInventoryItems(userInfo.token));
    }
  }, [dispatch, userInfo?.token, inventoryData.length, loading]);

  useEffect(() => {
    const categories = materialOrders.map((order) => order.category || "");
    setCurrentCategories(categories);
  }, [materialOrders]);

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          `${API_URI}/api/dealer/materials/prices/${dealerId}`,
          { headers: { Authorization: `Bearer ${userInfo.token}` } }
        );
        setPricingData(response.data);
      } catch (error) {
        console.error("Failed to fetch pricing data:", error);
      }
    };

    if (dealerId || dealer && userInfo?.token) {
      fetchPricingData();
    }
  }, [dealerId, userInfo?.token]);

  const handleCategoryChange = (index, category) => {
    handleAddMaterialOrder(index, "category", category);
    const updatedCategories = [...currentCategories];
    updatedCategories[index] = category;
    setCurrentCategories(updatedCategories);
  };

  const handleMaterialChange = (index, value) => {
    if (value === "custom") {
      setSelectedCategory(currentCategories[index]);
      setShowAddMaterialModal(true);
    } else {
      const updatedOrders = [...materialOrders];
      updatedOrders[index] = {
        ...updatedOrders[index],
        material: value,
      };

      const pricingItem = pricingData.find(
        (item) => item.inventoryItem && item.inventoryItem._id === value
      );

      if (pricingData.length === 0) {
        setPriceEditLink(`/dealer/edit-material-rate/${dealerId}`);
        setShowPriceNotification(true);
      } else if (!pricingItem || pricingItem.price === 0) {
        setPriceEditLink(`/dealer/edit-material-rate/${dealerId}`);
        setShowPriceNotification(true);
      } else {
        updatedOrders[index] = {
          ...updatedOrders[index],
          customCost: pricingItem.price * updatedOrders[index].quantity || 0,
        };
      }

      setMaterialOrders(updatedOrders);
    }
  };

  const handleQuantityChange = (index, value) => {
    const updatedOrders = [...materialOrders];
    updatedOrders[index] = {
      ...updatedOrders[index],
      quantity: value,
    };

    if (updatedOrders[index].material) {
      const pricingItem = pricingData.find(
        (item) =>item.inventoryItem && item.inventoryItem._id === updatedOrders[index].material
      );

      if (pricingItem) {
        updatedOrders[index] = {
          ...updatedOrders[index],
          customCost: pricingItem.price * value || 0,
        };
      }
    }

    setMaterialOrders(updatedOrders);
  };

  const handleNewMaterialAdded = () => {
    if (userInfo?.token) {
      dispatch(getAllInventoryItems(userInfo.token));
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="container-fluid bg-dark text-white">
      <div className="table-card-container">
        <div className="table-responsive" style={{ overflowX: "auto" }}>
          <table
            className="table table-bordered table-sm"
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <thead>
              <tr>
                <th
                  className="text-black"
                  style={{ border: "1px solid black" }}
                >
                  Category
                </th>
                <th
                  className="text-black"
                  style={{ border: "1px solid black" }}
                >
                  Material
                </th>
                <th
                  className="text-black"
                  style={{ border: "1px solid black" }}
                >
                  Quantity
                </th>
                <th
                  className="text-black"
                  style={{ border: "1px solid black" }}
                >
                  Custom Cost
                </th>
                <th
                  className="text-black"
                  style={{ border: "1px solid black" }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {materialOrders.map((order, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid black" }}>
                    <select
                      className="form-select bg-secondary text-white border-light"
                      value={order.category || ""}
                      onChange={(e) =>
                        handleCategoryChange(index, e.target.value)
                      }
                    >
                      <option value="">Select Category</option>
                      {invParents.map((cat, i) => (
                        <option key={i} value={cat}>
                          {cat}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <select
                      className="form-select bg-secondary text-white border-light"
                      value={order.material || ""}
                      onChange={(e) =>
                        handleMaterialChange(index, e.target.value)
                      }
                      disabled={!order.category}
                    >
                      <option value="">Select Material</option>
                      <option value="custom">Create Material</option>
                      {order.category &&
                        inventoryData
                          .filter((item) => item.category === order.category)
                          .map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <input
                      type="number"
                      className="form-control bg-secondary text-white border-light"
                      value={order.quantity || ""}
                      onChange={(e) =>
                        handleQuantityChange(index, e.target.value)
                      }
                    />
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <input
                      type="number"
                      className="form-control bg-secondary text-white border-light"
                      value={order.customCost || ""}
                      onChange={(e) =>
                        handleAddMaterialOrder(
                          index,
                          "customCost",
                          e.target.value
                        )
                      }
                      // disabled
                    />
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    <button
                      className="btn btn-danger"
                      onClick={() => handleRemoveMaterialOrder(index)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-primary mt-3"
            onClick={() => handleAddMaterialOrder(null, null, null)}
          >
            Add New Material Order
          </button>
        </div>
      </div>

      {/* Custom Material Modal */}
      <AddCustomMaterialModal
        show={showAddMaterialModal}
        handleClose={() => {
          setShowAddMaterialModal(false);
          setSelectedCategory("");
        }}
        category={selectedCategory}
        onMaterialAdded={handleNewMaterialAdded}
      />

      {/* MUI Price Notification Modal */}
      <Dialog
        open={showPriceNotification}
        onClose={() => setShowPriceNotification(false)}
      >
        <DialogTitle>Price Not Set</DialogTitle>
        <DialogContent>
          <p>
            The price for the selected material is not set. Please set the price
            first.
          </p>
          <p>
            <Link
              to={priceEditLink}
              // target="_blank"
              rel="noopener noreferrer"
            >
              Click here to set the price
            </Link>
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowPriceNotification(false)}
            color="primary"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MaterialOrdersTable;
