import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import CreditSystemPage from "../../components/dealer/creditSystem/CreditSystemPage";


const CreditSystem = ({ nav }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;

  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Credit System</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dealer/list-dealer">List All Dealers</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Credit System View
                  </li>
                </ol>
              </nav>
            </div>
          </div>
            <div>
                <CreditSystemPage />
            </div>
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
};

export default CreditSystem;
