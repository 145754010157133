import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AppVersion } from "../../constants/GlobalConstants";

const Sidebar = ({ nav }) => {
  const { userInfo } = useSelector((state) => state.userSignIn);
  const toggleHandle = () => {
    nav.isSidebar === "wrapper"
      ? nav.setIsSidebar("wrapper toggled")
      : nav.setIsSidebar("wrapper");
  };

  const toggleMainMenu = () => {
    if (window.innerWidth > 1200) {
      nav.setIsSidebar("wrapper");
    }
  };

  return (
    <>
      <aside className="sidebar-wrapper">
        <div className="iconmenu">
          <button onClick={toggleHandle} style={{ marginRight: "-14px" }}>
            <div className="nav-toggle-box">
              <div className="nav-toggle-icon">
                <i className="bi bi-list" />
              </div>
            </div>
          </button>
          <ul className="nav nav-pills flex-column">
            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Dashboards"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-dashboards"
                  type="button"
                >
                  <i className="bi bi-house-door-fill" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") ||
              userInfo?.roles?.includes("inhouse_installer") ||
              userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Actions"
              >
                <button
                  onClick={toggleMainMenu}
                  class="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-action"
                  type="button"
                >
                  <i className="fadeIn animated bx bx-command" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") ||
              userInfo?.roles?.includes("inhouse_installer") ||
              userInfo?.isAdmin) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Appointments"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-application"
                  type="button"
                >
                  <i className="bx bx-calendar" />
                </button>
              </li>
            )}

            {(userInfo?.roles?.includes("admin") ||
              userInfo?.roles?.includes("production_manager") ||
              userInfo?.roles?.includes("production_associate") ||
              userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Order"
              >
                <button
                  onClick={toggleMainMenu}
                  class="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-widgets"
                  type="button"
                >
                  <i className="lni lni-cart" />
                </button>
              </li>
            )}

            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Dealer"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#dealer"
                  type="button"
                >
                  <i class="fadeIn animated bx bx-store" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Charts"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-charts"
                  type="button"
                >
                  <i class="bi bi-graph-up" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Inventory"
              >
                <button
                  onClick={toggleMainMenu}
                  class="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-inventory"
                  type="button"
                >
                  <i class="fadeIn animated bx bx-box" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Product Management"
              >
                <button
                  onClick={toggleMainMenu}
                  class="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#products-management"
                  type="button"
                >
                  <i class="fadeIn animated bx bx-shopping-bag"></i>
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") || userInfo?.isAdmin) && (
              <li
                class="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Invoice  "
              >
                <button
                  onClick={toggleMainMenu}
                  class="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-apps"
                  type="button"
                >
                  <i class="lni lni-app-store"></i>
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") ||
              userInfo?.isAdmin == true) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="User Management"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#user-management"
                  type="button"
                >
                  <i className="bi bi-person" />
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") ||
              userInfo?.isAdmin == true) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="App settings"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#app-settings"
                  type="button"
                >
                  <i class="bi bi-gear"></i>{" "}
                </button>
              </li>
            )}
            {(userInfo?.roles?.includes("admin") ||
              userInfo?.isAdmin == true) && (
              <li
                className="nav-item"
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Matrix Config"
              >
                <button
                  onClick={toggleMainMenu}
                  className="nav-link"
                  data-bs-toggle="pill"
                  data-bs-target="#matrix-config"
                  type="button"
                >
                  <i class="bi bi-calculator-fill"></i>{" "}
                </button>
              </li>
            )}
          </ul>
        </div>
        <div className="textmenu">
          <div className="brand-logo">
            <img src="/assets/images/brand-logo-2.png" width={140} alt="" />
        
          </div>
          <div className="tab-content">
            <div className="tab-pane fade" id="pills-dashboards">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">Dashboards</h5>
                  </div>
                  <small className="mb-0">An Overview Management</small>
                </div>
                <Link
                  to="/"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="lni lni-home" />
                  Home
                </Link>
                {/* <a href="/add-action" className="list-group-item">
                                <i class="fadeIn animated bx bx-add-to-queue" />
                                Add Action
                            </a>
                            <a href="/add-appointment" className="list-group-item">
                                <i className="bx bx-calendar-plus" />
                                Add Appointment
                            </a> */}
              </div>
            </div>

            <div class="tab-pane fade" id="pills-action">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Action/Task</h5>
                  </div>
                  <small class="mb-0">Manage all actions</small>
                </div>
                <Link
                  to="/action/add-action"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-file" />
                  Create Action
                </Link>
                <Link
                  to="/action/list-action"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-list-ul" />
                  List Action
                </Link>
              </div>
            </div>

            <div className="tab-pane fade" id="pills-application">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">Appointments</h5>
                  </div>
                  <small className="mb-0">View all listed Schedules</small>
                </div>
                <Link
                  to="/appointments"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bx bx-calendar-event" />
                  All Appointments
                </Link>
                <Link
                  to="/add-appointment"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bx bx-calendar-plus" />
                  Add Appointment
                </Link>
                <Link
                  to="/appointment-dashboard"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bx bx-calendar-plus" />
                  Dashboard
                </Link>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-widgets">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Order System</h5>
                  </div>
                  <small class="mb-0">Order Management</small>
                </div>
                {(userInfo?.roles?.includes("admin") ||
                  userInfo?.roles?.includes("production_manager") ||
                  userInfo?.isAdmin) && (
                  <Link
                    to="/order/add-order"
                    class="list-group-item"
                    onClick={(e) => nav.setIsSidebar("wrapper")}
                  >
                    <i class="bi bi-box"></i>Add Order
                  </Link>
                )}
                <Link
                  to="/order/list"
                  class="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="bi bi-bar-chart"></i>Order List
                </Link>
              </div>
            </div>

            <div className="tab-pane fade" id="dealer">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">Dealer Manager</h5>
                  </div>
                  <small className="mb-0">Dealer Panel</small>
                </div>
                <Link
                  to="/dealer/list-dealer"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bx bx-user-circle" />
                  All Dealer
                </Link>
                <Link
                  to="/dealer/add-dealer"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bx bx-user-plus" />
                  Add Dealer
                </Link>
                {/* <Link to="/dealer/test" className="list-group-item"
                                    onClick={(e) => nav.setIsSidebar('wrapper')}>
                                    <i className="bx bx-user-plus" />
                                    Test
                                </Link> */}
              </div>
            </div>

            <div class="tab-pane fade" id="pills-charts">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Analytics</h5>
                  </div>
                  <small class="mb-0">
                    Analytics, statistics & Reports Management
                  </small>
                </div>
                <Link
                  to="/analytics/annual-sales"
                  class="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="bi bi-bar-chart" />
                  Annual Sales{" "}
                </Link>
                <Link to="/analytics/productions" class="list-group-item">
                  <i class="bi bi-pie-chart"></i>Production Stat
                </Link>
                {/* <a href="charts-highcharts.html" class="list-group-item"><i class="bi bi-graph-up"></i>Highcharts</a> */}
              </div>
            </div>

            <div class="tab-pane fade" id="pills-inventory">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Inventory</h5>
                  </div>
                  <small class="mb-0">Inventory Management</small>
                </div>
                <Link
                  to="/inventory/add-item"
                  class="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-book-add" />
                  Add Inventory Item
                </Link>
                <Link
                  to="/inventory/inventory-list"
                  className="list-group-item"
                >
                  <i class="fadeIn animated bx bx-file" />
                  List Inventory
                </Link>
              </div>
            </div>
            <div class="tab-pane fade" id="products-management">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Products Management</h5>
                  </div>
                  <small class="mb-0">Products & Attributes</small>
                </div>
                <Link
                  to="/product-management/add-product-attributes"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-message-square-add" />
                  Add Product/Attribute
                </Link>
              </div>
            </div>
            <div class="tab-pane fade" id="pills-apps">
              <div class="list-group list-group-flush">
                <div class="list-group-item">
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-0">Invoice</h5>
                  </div>
                  <small class="mb-0">ERP Applications</small>
                </div>
                <Link
                  to="/app/invoice-generator"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-file" />
                  Invoice/Estimate Generator
                </Link>
                <Link
                  to="/app/list-invoices"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-file" />
                  Invoice List
                </Link>
                <Link
                  to="/app/list-estimates"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i class="fadeIn animated bx bx-file" />
                  Estimated List
                </Link>
              </div>
            </div>
            <div className="tab-pane fade" id="user-management">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">User Management</h5>
                  </div>
                  <small className="mb-0">Manage Users</small>
                </div>
                <Link
                  to="/user/add-user"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  Add User
                </Link>
                <Link
                  to="/user/manage-user"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-lines-fill" />
                  Manage Users
                </Link>
              </div>
            </div>
            <div className="tab-pane fade" id="app-settings">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">App Settings</h5>
                  </div>
                  <small className="mb-0">Manage Settings</small>
                </div>
                <Link
                  to="/erp/settings/production-calculation"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  Production Calculation
                </Link>
                <Link
                  to="/erp/settings/all-production-calculations"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  List All Calculation
                </Link>
                <Link
                  to="/erp/settings/app-settings"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  App Settings
                </Link>
              </div>
            </div>
            <div className="tab-pane fade" id="matrix-config">
              <div className="list-group list-group-flush">
                <div className="list-group-item">
                  <div className="d-flex w-100 justify-content-between">
                    <h5 className="mb-0">Matrix Configuration</h5>
                  </div>
                  <small className="mb-0">Manage Matrix</small>
                </div>
                <Link
                  to="/matrix/add-matrix"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  Matrix Configuration
                </Link>
                <Link
                  to="/matrix/list-matrix"
                  className="list-group-item"
                  onClick={(e) => nav.setIsSidebar("wrapper")}
                >
                  <i className="bi bi-person-plus" />
                  List Matrix
                </Link>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
