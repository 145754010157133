import React from "react";
import MaterialInvoiceTable from "./MaterialInvoiceTable";

const ProductSection = ({
  products,
  productTypes,
  settings,
  materialOrders,
  invParents,
  inventoryData,
  handleCategoryChange,
  handleMaterialChange,
  handleAddMaterial,
  handleRemoveMaterial,
  handleProductChange,
  handleTaxRateChange,
  handleAddProduct,
  handleRemoveProduct,
  handleMaterialTaxChange,
}) => {
  const renderMaterialSection = (index) => (
    <MaterialInvoiceTable
    materialOrders={products[index].materialOrders || []}
      invParents={invParents}
      inventoryData={inventoryData}
      handleCategoryChange={(materialindex,category) => handleCategoryChange(index,materialindex,category)}
      handleMaterialChange={(materialindex,field,material) => handleMaterialChange(index,materialindex,field, material)}
      handleAddMaterial={() => handleAddMaterial(index)}
      handleRemoveMaterial={(materialId) => handleRemoveMaterial(index, materialId)}
      taxRates={settings.taxRates}
      handleMaterialTaxChange={(materialId, taxId, isSelected) =>
        handleMaterialTaxChange(index, materialId, taxId, isSelected)
      }
    />
  );

  const renderTaxRates = (item, index) =>
    settings.taxRates.map((taxRate) => (
      <div className="form-check form-check-inline" key={taxRate._id}>
        <input
          className="form-check-input"
          type="checkbox"
          checked={item.selectedTaxRates.some((rate) => rate._id === taxRate._id)}
          onChange={(e) =>
            handleTaxRateChange(index, taxRate, e.target.checked)
          }
        />
        <label className="form-check-label">
          {taxRate.type} ({taxRate.value}%)
        </label>
      </div>
    ));

  return (
    <div className="row">
      {products.map((item, index) => (
        <div className="col-lg-12" key={index}>
          <div className="row g-3 align-items-center">
            {/* Product Selection */}
            <div className="col-lg-3">
              <label className="form-label">Product</label>
              <select
                className="form-select"
                value={item.product}
                onChange={(e) =>
                  handleProductChange(index, "product", e.target.value)
                }
              >
                <option value="">Select Product</option>
                {productTypes.map((product) => (
                  <option key={product._id} value={product.name}>
                    {product.name}
                  </option>
                ))}
                <option value="Materials">Materials/Components</option>
              </select>
            </div>

            {/* Tax Rates for Regular Products */}
            {item.product !== "Materials" && (
              <div className="col-lg-3">
                <label className="form-label">Tax Rates</label>
                <div className="d-flex flex-wrap">
                  {settings.taxRates &&
                    settings.taxRates.map((taxRate) => (
                      <div
                        className="form-check form-check-inline"
                        key={taxRate._id}
                      >
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={item.selectedTaxRates.some(
                            (rate) => rate._id === taxRate._id
                          )}
                          onChange={(e) =>
                            handleTaxRateChange(
                              index,
                              taxRate,
                              e.target.checked
                            )
                          }
                        />
                        <label className="form-check-label">
                          {taxRate.type} ({taxRate.value}%)
                        </label>
                      </div>
                    ))}
                </div>
              </div>
            )}

            {/* If "Materials" is selected, show material table */}
            {item.product === "Materials" ? (
              renderMaterialSection(index)
            ) : (
              <>
                {/* Quantity */}
                <div className="col-lg-2">
                  <label className="form-label">Quantity</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Quantity"
                    value={item.quantity || 1}
                    onChange={(e) =>
                      handleProductChange(index, "quantity", e.target.value)
                    }
                  />
                </div>

                {/* Price */}
                <div className="col-lg-1">
                  <label className="form-label">Price $</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Price"
                    value={item.price}
                    onChange={(e) =>
                      handleProductChange(index, "price", e.target.value)
                    }
                  />
                </div>

                {/* Tax */}
                <div className="col-lg-1">
                  <label className="form-label">Tax $</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={item.tax ? item.tax.toFixed(2) : 0}
                  />
                </div>

                {/* Total */}
                <div className="col-lg-2">
                  <label className="form-label">Total $</label>
                  <input
                    type="text"
                    className="form-control"
                    readOnly
                    value={item.total}
                  />
                </div>
              </>
            )}

            {/* Remove Button */}
            <div className="col-12 text-end">
              <button
                type="button"
                className="btn btn-danger mt-2"
                onClick={() => handleRemoveProduct(index)}
                disabled={products.length === 1}
              >
                Remove
              </button>
            </div>
          </div>
        </div>
      ))}

      {/* Add Product Button */}
      <div className="col-lg-12 mt-3">
        <button
          type="button"
          className="btn btn-primary"
          onClick={handleAddProduct}
        >
          Add Product
        </button>
      </div>
    </div>
  );
};

export default ProductSection;
