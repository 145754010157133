import React from "react";

const HeadrailConfigTable = ({
  headrailConfigList,
  handleHeadrailConfigChange,
}) => {
  return (
    <div className="table-responsive mt-4">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Headrail Configuration</h4>
        <button type="submit" className="btn btn-primary">
          Update Headrail Config
        </button>
      </div>
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Headrail</th>
            <th>Product Type</th>
            <th>Sleeve Option</th>
            <th>Add On</th>
            <th>Unit Rate</th>
          </tr>
        </thead>
        <tbody>
          {headrailConfigList.map((headrail, index) => {
            const products = Object.entries(headrail.products);
            return products.length > 0 ? (
              products.map(([productName, productConfig], productIndex) => (
                <React.Fragment key={`${headrail.id}-${productName}`}>
                  <tr>
                    {productIndex === 0 && (
                      <td rowSpan={products.length * 2}>{headrail.name}</td>
                    )}
                    {!productConfig.sleeveOff.isTrue ? (
                      <>
                        <td>{productName}</td>
                        <td>Sleeve On</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={productConfig.sleeveOn?.addonPrice ?? ""}
                            onChange={(e) => {
                              const newValue =
                                e.target.value === ""
                                  ? ""
                                  : parseFloat(e.target.value);
                              handleHeadrailConfigChange(
                                index,
                                productName,
                                "sleeveOn.addonPrice",
                                isNaN(newValue) ? 0 : newValue
                              );
                            }}
                            required={true}
                          />
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={productConfig.sleeveOn?.unitRate || "sqft"}
                            onChange={(e) =>
                              handleHeadrailConfigChange(
                                index,
                                productName,
                                "sleeveOn.unitRate",
                                e.target.value
                              )
                            }
                            disabled
                          >
                            <option value="individual">Individual</option>
                            <option value="sqft">SQFT</option>
                            <option value="meter">Meter</option>
                          </select>
                        </td>
                      </>
                    ) : null}
                  </tr>

                  {/* Render Sleeve Off row if it exists */}
                  {productConfig.sleeveOff && (
                    <tr>
                      <td>{productName}</td>
                      <td>Sleeve Off</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={productConfig.sleeveOff?.addonPrice ?? ""}
                          onChange={(e) => {
                            const newValue =
                              e.target.value === ""
                                ? ""
                                : parseFloat(e.target.value);
                            handleHeadrailConfigChange(
                              index,
                              productName,
                              "sleeveOff.addonPrice",
                              isNaN(newValue) ? 0 : newValue
                            );
                          }}
                          required={true}
                        />
                      </td>
                      <td>
                        <select
                          className="form-control"
                          value={productConfig.sleeveOff?.unitRate || "sqft"}
                          onChange={(e) =>
                            handleHeadrailConfigChange(
                              index,
                              productName,
                              "sleeveOff.unitRate",
                              e.target.value
                            )
                          }
                          disabled
                        >
                          <option value="individual">Individual</option>
                          <option value="sqft">SQFT</option>
                          <option value="meter">Meter</option>{" "}
                        </select>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))
            ) : (
              <tr key={headrail.id}>
                <td colSpan={4}>No products available for this headrail.</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default HeadrailConfigTable;
