import axios from "axios"
import { API_URI } from "../../constants/GlobalConstants"
import { FETCH_CALCULATIONS_FAILURE, FETCH_CALCULATIONS_REQUEST, FETCH_CALCULATIONS_SUCCESS, FETCH_FABRICS_FAILURE, FETCH_FABRICS_REQUEST, FETCH_FABRICS_SUCCESS, FETCH_ITEMS_FAILURE, FETCH_ITEMS_REQUEST, FETCH_ITEMS_SUCCESS, FETCH_LIFT_STYLE_FAILURE, FETCH_LIFT_STYLE_REQUEST, FETCH_LIFT_STYLE_SUCCESS, INV_ITEMS_GET_FAIL, INV_ITEMS_GET_REQUEST, INV_ITEMS_GET_SUCCESS } from "../../constants/InventoryConstants"

export const createInvItemAction = (val,token) => async (dispatch) => {
    try {
        dispatch({ type: 'INV_ITEM_CREATE_REQUEST' })
        const { data } = await axios.post(`${API_URI}/api/inventory/add-item`, val,
            {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
        )
        dispatch({ type: 'INV_ITEM_CREATE_SUCCESS', payload: data })
    } catch (error) {
        dispatch({
            type: 'INV_ITEM_CREATE_FAIL',
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const resetCreateInvItem = () => {
  return {
    type: "INV_ITEM_CREATE_RESET",
  };
};

export const getInvItemsByCat = (val, token, productType) => async (dispatch) => {
  try {
      dispatch({ type: INV_ITEMS_GET_REQUEST });

      const config = {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          params: {
              category: val,
              ...(productType && { productType }),
          },
      };

      const { data } = await axios.get(`${API_URI}/api/inventory/get-items-by-cat`, config);

      dispatch({ type: INV_ITEMS_GET_SUCCESS, payload: data });
  } catch (error) {
      dispatch({
          type: INV_ITEMS_GET_FAIL,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
};
//all inventory items fetching
export const getAllInventoryItems = (token, category ) => async (dispatch) => {
  try {
    dispatch({ type: INV_ITEMS_GET_REQUEST });

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        category,
      },
    };

    const { data } = await axios.get(`${API_URI}/api/inventory/all-items`, config);

    dispatch({ type: INV_ITEMS_GET_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: INV_ITEMS_GET_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};



export const fetchItems = (token) => async (dispatch) => {
    dispatch({ type: FETCH_ITEMS_REQUEST });

    try {
        const response = await axios.get(`${API_URI}/api/inventory/get-items-by-cat`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        dispatch({
            type: FETCH_ITEMS_SUCCESS,
            payload: response.data
        });
    } catch (error) {
        dispatch({
            type: FETCH_ITEMS_FAILURE,
            payload: 'Failed to load data. Please try again later.'
        });
    }
};


  export const fetchCalculations = (productType, inventoryId, token) => async (dispatch) => {
    dispatch({ type: FETCH_CALCULATIONS_REQUEST });
  
    try {
        const response = await axios.get(`${API_URI}/api/inventory/fetch-calculations`, {
            params: { productType, inventoryId },
            headers: {
                Authorization: `Bearer ${token}`
            }
          });
      dispatch({
        type: FETCH_CALCULATIONS_SUCCESS,
        payload: response.data
      });
    } catch (error) {
      dispatch({
        type: FETCH_CALCULATIONS_FAILURE,
        payload: error.message
      });
    }
  };

  export const fetchInventoryItem = async (inventoryId, token) => {
    const response = await axios.get(`${API_URI}/api/inventory/inventory/${inventoryId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  };
  
  export const updateInventoryItemConfig = async (inventoryId, dataToSend, token) => {
    await axios.put(
      `${API_URI}/api/inventory/inventory/config`,
      {
        inventoryId,
        config:dataToSend.config,
        deductionMethod:dataToSend.deductionMethod,
        category:dataToSend.category,
        name:dataToSend.name
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

// Fetching Fabrics
  export const getFabricItems = (val, token, productType) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_FABRICS_REQUEST });

        const config = {
          headers: {
              Authorization: `Bearer ${token}`,
          },
          params: {
              category: val,
              ...(productType && { productType })
          }
      };

      const { data } = await axios.get(`${API_URI}/api/inventory/get-items-by-cat`, config);

        dispatch({ type: FETCH_FABRICS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({
            type: FETCH_FABRICS_FAILURE,
            payload: error.response && error.response.data.message
                ? error.response.data.message
                : error.message,
        });
    }
}

// Fetching Liftstyle
export const getLiftStyleItems = (val, token, productType) => async (dispatch) => {
  try {
      dispatch({ type: FETCH_LIFT_STYLE_REQUEST });

      const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: {
            category: val,
            ...(productType && { productType })
        }
    };

    const { data } = await axios.get(`${API_URI}/api/inventory/get-items-by-cat`, config);

      dispatch({ type: FETCH_LIFT_STYLE_SUCCESS, payload: data });
  } catch (error) {
      dispatch({
          type: FETCH_LIFT_STYLE_FAILURE,
          payload: error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
      });
  }
}