import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  CircularProgress,
  Alert,
} from "@mui/material";
import { VisibilityOutlined } from "@mui/icons-material";

const AllCalculationsTable = () => {
  const [calculations, setCalculations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCalculations = async () => {
      try {
        const response = await axios.get(
          `${API_URI}/api/inventory/calculations/all`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setCalculations(response.data.data);
      } catch (err) {
        setError("Failed to fetch calculations");
      } finally {
        setLoading(false);
      }
    };

    fetchCalculations();
  }, [userInfo.token]);

  const handleViewClick = (id) => {
    navigate(`/erp/settings/calculation/${id}`);
  };

  return (
    <Box p={4}>
      <Typography variant="h4" gutterBottom>
        All Calculations
      </Typography>
      {loading ? (
        <Box display="flex" justifyContent="center" my={4}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Alert severity="error" className="mb-3">
          {error}
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Inventory Name</TableCell>
                <TableCell>Formulas</TableCell>
                <TableCell>Dependencies</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {calculations.map((calc) => (
                <TableRow key={calc._id}>
                  <TableCell>{calc.inventoryName}</TableCell>
                  <TableCell>
                    {calc.formulas.map((formula, index) => (
                      <Box key={index} mb={1}>
                        <strong>{formula.key}</strong>: {formula.expression} ({formula.operand})
                      </Box>
                    ))}
                  </TableCell>
                  <TableCell>{calc.dependencies.join(", ")}</TableCell>
                  <TableCell align="right">
                    <Tooltip title="View Calculation">
                      <IconButton
                        color="primary"
                        onClick={() => handleViewClick(calc._id)}
                      >
                        <VisibilityOutlined />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default AllCalculationsTable;