import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import store from './store';
import SplashScreen from './components/layout/SplashScreen';
import './App.css'; // Ensure CSS contains necessary transition styles

const root = ReactDOM.createRoot(document.getElementById('root'));

const RootWithSplash = () => {
  const [isAppReady, setIsAppReady] = useState(false);
  const [transitionClass, setTransitionClass] = useState('opacity-0');

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAppReady(true); 
      setTimeout(() => {
        setTransitionClass('opacity-100'); 
      }, 50);
    }, 3000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <Provider store={store}>
      {!isAppReady ? (
        <SplashScreen />
      ) : (
        <div className={`app-container ${transitionClass}`}>
          <App />
        </div>
      )}
    </Provider>
  );
};

root.render(<RootWithSplash />);
