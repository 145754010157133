import React from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { API_URI } from "../../constants/GlobalConstants";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  TextField,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Alert,
  ListItemIcon,
  SwapHorizIcon,
} from "@mui/material";
import {
  Download as DownloadIcon,
  Print as PrintIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import Loader from "../../components/layout/Loader";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import { fetchInvoiceData } from "../../redux/actions/InvoiceActions";
import { useSnackbarNotification } from "../../components/order/reusables/UseSnackBarNotification";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import SaveAsPaidModal from "../invoiceListing/SaveAsPaidModal";
import InvoiceConvConfirmModal from "./InvoiceConvConfirmModal";

const InvoiceDetailsComp = () => {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.userSignIn);
  const { documentType, invoice, pdf, loading, error } = useSelector(
    (state) => state.customInvoiceById
  );
  const { showSnackbar, SnackbarComponent } = useSnackbarNotification();
  const [editableInvoice, setEditableInvoice] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [isConvertModalOpen, setIsConvertModalOpen] = useState(false);
  const [currentEstimateId, setCurrentEstimateId] = useState(null);
  const isDisabled =
    documentType === "Estimate" && invoice?.status === "Converted";
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (invoice) {
      setEditableInvoice(invoice);
    }
  }, [invoice]);

  const handleInputChange = (field, value) => {
    setEditableInvoice((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleDownloadPDF = () => {
    if (pdf) {
      const link = document.createElement("a");
      link.href = `data:application/pdf;base64,${pdf}`;
      link.download = `Invoice_${invoice.invoiceNumber}.pdf`;
      link.click();
    }
  };

  const handlePrintPDF = () => {
    if (pdf) {
      const pdfWindow = window.open("");
      pdfWindow.document.write(
        `<iframe width='100%' height='100%' src='data:application/pdf;base64,${pdf}'></iframe>`
      );
      pdfWindow.document.close();
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handlePostDelete = () => {
    handleCloseDeleteModal();
  };

  const handleSendInvoice = async () => {
    try {
      const response = await axios.post(
        `${API_URI}/api/app/invoices/${id}/send`,
        {
          clientEmail: invoice.buyer.contact.email,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );
      if (response.data.success) {
        showSnackbar(
          `Invoice successfully sent to ${invoice.buyer.contact.email}`,
          "success"
        );
      } else {
        showSnackbar(
          "Failed to send the invoice: " + response.data.message,
          "error"
        );
      }
    } catch (error) {
      showSnackbar("Error sending invoice: " + error.message, "error");
    }
  };

  const handleSaveInvoice = async () => {
    try {
      const updateData = editableInvoice;
      const response = await axios.put(
        `${API_URI}/api/app/invoice-update/${id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      if (response.data.success) {
        showSnackbar("Invoice saved successfully!", "success");
        dispatch(fetchInvoiceData(id, userInfo.token));
      } else {
        showSnackbar("Failed to save the invoice.", "error");
      }
    } catch (error) {
      showSnackbar("Error saving invoice: " + error.message, "error");
    }
  };

  const handleSaveAsPaid = async (modeOfPayment) => {
    setLoadingModal(true);

    try {
      const response = await axios.post(
        `${API_URI}/api/invoices/update-invoice-status`,
        {
          id: [id],
          status: "Paid",
          modeOfPayment,
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      setTimeout(() => {
        setLoadingModal(false);
        setOpenModal(false);
      }, 2000);
    } catch (error) {
      console.error("Error saving invoice as Paid:", error);
      setLoadingModal(false);
    }
  };

  const handleOpenConvertModal = (estimateId) => {
    setCurrentEstimateId(estimateId);
    setIsConvertModalOpen(true);
    setAnchorEl(null);
  };

  const handleCloseConvertModal = () => {
    setIsConvertModalOpen(false);
    setCurrentEstimateId(null);
  };

  const handleConfirmConvert = async () => {
    try {
      const response = await axios.post(
        `${API_URI}/api/invoices/${invoice._id}/convert`,
        {},
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      const invoiceId = response.data.invoice._id;
      showSnackbar("Estimate successfully converted to an invoice!", "success");

      setTimeout(() => {
        navigate(`/invoice/${invoiceId}`);
      }, 200);

      handleCloseConvertModal();
    } catch (error) {
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while converting the estimate.",
        "error"
      );
    }
  };

  if (loading || deleteLoading) {
    return <Loader />;
  }
  if (error) {
    return (
      <>
        <Alert severity="error">
          The Invoice is Removed and could not be found
          <Link
            to="/app/list-invoices"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Go to Invoice List
          </Link>
        </Alert>
      </>
    );
  }

  return (
    <div className="container-fluid p-4">
      <Card elevation={3} className="mb-4">
        <CardContent>
          <div className="d-flex justify-content-end align-items-center mb-2">
            {/* Save as Paid Button */}
            {documentType !== "Estimate" && (
              <button
                className="btn btn-success me-2"
                onClick={() => setOpenModal(true)}
              >
                <i className="fas fa-money-check-alt me-1"></i> Record Payment
              </button>
            )}

            {/* Dropdown Menu */}
            <IconButton
              aria-label="more"
              aria-controls="action-menu"
              aria-haspopup="true"
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="action-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem
                onClick={() => handleOpenDeleteModal()}
                style={{ color: "red" }}
              >
                <DeleteIcon fontSize="small" className="me-2" /> Delete Invoice
              </MenuItem>
              {documentType === "Estimate" && (
                <MenuItem
                  onClick={() => handleOpenConvertModal(invoice._id)}
                  style={{ color: "blue" }}
                  disabled={isDisabled}
                >
                  Convert to Invoice
                </MenuItem>
              )}
              {documentType !== "Order-Based" && (
                <MenuItem
                  onClick={() => navigate(`/invoice-edit/${invoice._id}`)}
                  style={{ color: "blue" }}
                  disabled={isDisabled}
                >
                  Edit Products
                </MenuItem>
              )}
            </Menu>
          </div>

          <Grid container spacing={3}>
            {/* Left Side: Invoice Details */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {documentType === "Estimate"
                  ? "Estimate Details"
                  : "Invoice Details"}
              </Typography>
              <Divider className="mb-3" />
              {/* Buyer Information */}
              <Typography variant="subtitle1" className="mb-2 fw-bold">
                Buyer Information
              </Typography>
              {editableInvoice && (
                <>
                  <Grid container spacing={2} className="mb-3">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Company Name"
                        value={editableInvoice.buyer.companyName || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            companyName: e.target.value,
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Country"
                        value={editableInvoice.buyer.address.country || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              country: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Email"
                        value={editableInvoice.buyer.contact.email || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              email: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Name"
                        value={editableInvoice.buyer.contact.name || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              name: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Phone"
                        value={editableInvoice.buyer.contact.phone || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              phone: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="mb-3">
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Street"
                        value={editableInvoice.buyer.address.street || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              street: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="City"
                        value={editableInvoice.buyer.address.city || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              city: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Province"
                        value={editableInvoice.buyer.address.province || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              province: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Postal Code"
                        value={editableInvoice.buyer.address.postalCode || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              postalCode: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Country"
                        value={editableInvoice.buyer.address.country || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              country: e.target.value,
                            },
                          })
                        }
                        fullWidth
                        disabled={isDisabled}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {/* Invoice Specifics */}
              <Typography variant="subtitle1" className="mb-2 fw-bold">
                {documentType === "Estimate"
                  ? "Estimate Details"
                  : "Invoice Details"}
              </Typography>
              {editableInvoice && (
                <Grid container spacing={2} className="mb-3">
                  {/* Invoice Number */}
                  <Grid item xs={12} sm={6}>
                    {documentType === "Invoice" ? (
                      <TextField
                        label="Invoice Number"
                        value={editableInvoice.invoiceNumber || ""}
                        fullWidth
                        variant="filled"
                        InputProps={{
                          readOnly: isDisabled,
                        }}
                        disabled={isDisabled}
                        onChange={(e) =>
                          setEditableInvoice({
                            ...editableInvoice,
                            invoiceNumber: e.target.value,
                          })
                        }
                      />
                    ) : (
                      <TextField
                        label="Estimate Number"
                        value={editableInvoice.estimateNumber || ""}
                        fullWidth
                        variant="filled"
                        InputProps={{
                          readOnly: isDisabled,
                        }}
                        disabled={isDisabled}
                        onChange={(e) =>
                          setEditableInvoice({
                            ...editableInvoice,
                            estimateNumber: e.target.value,
                          })
                        }
                      />
                    )}
                  </Grid>

                  {/* Invoice Date */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Invoice Date"
                      type="date"
                      value={
                        editableInvoice.invoiceDate
                          ? new Date(editableInvoice.invoiceDate)
                              .toISOString()
                              .slice(0, 10)
                          : new Date(editableInvoice.estimateDate)
                              .toISOString()
                              .slice(0, 10)
                      }
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setEditableInvoice({
                          ...editableInvoice,
                          invoiceDate: new Date(e.target.value).toISOString(),
                        })
                      }
                    />
                  </Grid>

                  {/* Due Date */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Due Date"
                      type="date"
                      value={
                        editableInvoice.dueDate
                          ? new Date(editableInvoice.dueDate)
                              .toISOString()
                              .slice(0, 10)
                          : ""
                      }
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      disabled={isDisabled}
                      onChange={(e) =>
                        setEditableInvoice({
                          ...editableInvoice,
                          dueDate: new Date(e.target.value).toISOString(),
                        })
                      }
                    />
                  </Grid>

                  {/* Total Amount (Remains Read-Only) */}
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Total Amount"
                      value={`$${editableInvoice.total.toFixed(2)}`}
                      fullWidth
                      variant="filled"
                      InputProps={{
                        readOnly: true,
                        style: {
                          fontWeight: "bold",
                          color: "green",
                        },
                      }}
                      disabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              )}

              {editableInvoice && (
                <Grid item xs={12} sm={12}>
                  <TextField
                    label="Email Message"
                    value={editableInvoice.emailMessage || ""}
                    onChange={(e) =>
                      handleInputChange("emailMessage", e.target.value)
                    }
                    fullWidth
                    multiline
                    rows={6}
                    variant="outlined"
                    disabled={isDisabled}
                  />
                </Grid>
              )}

              {/* Action Buttons */}
              <div className="d-flex gap-2 mt-3">
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  onClick={handleDownloadPDF}
                >
                  Download PDF
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<PrintIcon />}
                  onClick={handlePrintPDF}
                >
                  Print {documentType}
                </Button>
                {editableInvoice && editableInvoice.status === "Issued" ? (
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SendIcon />}
                    onClick={handleSendInvoice}
                  >
                    Resend{" "}
                    {documentType === "Estimate" ? "Estimate" : "Invoice"}
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<SendIcon />}
                    onClick={handleSendInvoice}
                    disabled={
                      (editableInvoice &&
                        editableInvoice.status === "Issued") ||
                      isDisabled
                    }
                  >
                    Send {documentType === "Estimate" ? "Estimate" : "Invoice"}
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveInvoice}
                  disabled={isDisabled}
                >
                  Save {documentType}
                </Button>
              </div>
            </Grid>

            {/* Right Side: PDF Viewer */}
            <Grid item xs={12} md={6}>
              <Typography variant="h5" gutterBottom>
                {documentType === "Estimate"
                  ? "Estimate Preview"
                  : "Invoice Preview"}
              </Typography>
              <Divider className="mb-3" />
              {pdf ? (
                <div className="position-relative" style={{ height: "700px" }}>
                  <iframe
                    src={`data:application/pdf;base64,${pdf}`}
                    title="Invoice PDF"
                    className="w-100 h-100 border rounded shadow-sm"
                    style={{ border: "none" }}
                  />
                </div>
              ) : (
                <div className="d-flex justify-content-center align-items-center h-100">
                  <Loader />
                </div>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DeleteConfirmationModal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        onConfirm={handlePostDelete}
        invoiceId={id}
        token={userInfo.token}
        showSnackbar={showSnackbar}
        setDeleteLoading={setDeleteLoading}
      />
      <InvoiceConvConfirmModal
        open={isConvertModalOpen}
        onClose={handleCloseConvertModal}
        onConfirm={handleConfirmConvert}
        title="Convert to Invoice"
        description="Are you sure you want to convert this estimate to an invoice? This action cannot be undone."
      />
      <SaveAsPaidModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={handleSaveAsPaid}
        loading={loadingModal}
      />
      <SnackbarComponent />
    </div>
  );
};

export default InvoiceDetailsComp;
