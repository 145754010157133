export const calculateTaxAndTotal = (materialOrder) => {
    const customCost = parseFloat(materialOrder.customCost || 0);
    const selectedTaxRates = materialOrder.taxes || [];
    
    // Calculate total tax
    const tax = selectedTaxRates.reduce((acc, rate) => {
      return acc + (customCost * rate.rate) / 100;
    }, 0);
  
    // Calculate total
    const total = customCost + tax;
  
    return { tax, total };
  };

  export const calculateTaxAndTotalEdit = (material) => {
    const tax = material.taxes?.reduce(
      (sum, rate) => sum + (parseFloat(material.customCost) * rate.rate) / 100,
      0
    );
  
    const total = parseFloat(material.customCost) + (tax || 0);
  
    return { tax: tax.toFixed(2), total: total.toFixed(2) };
  };

  export const calculateProductTotalTaxAndPrice = (product) => {
    // Calculate the total taxes for the product
    const materialTaxes = product.materials.reduce((totalTax, material) => {
      // Sum the amount of each tax object in the taxes array for the material
      const materialTaxAmount = Array.isArray(material.taxes)
        ? material.taxes.reduce((acc, tax) => acc + (tax.amount || 0), 0) // Sum up the tax amounts
        : 0;
  
      return totalTax + materialTaxAmount; // Accumulate the total tax for the product
    }, 0);
  
    // Calculate the total price for the product (sum of the materials' customCost)
    const totalPrice = product.materials.reduce((acc, material) => {
      return acc + parseFloat(material.customCost || 0); // Sum the customCost of all materials
    }, 0);
  
    return { materialTaxes, totalPrice }; 
  };