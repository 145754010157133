import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Header from '../../components/layout/Header'
import Sidebar from '../../components/layout/Sidebar'
import { Link } from 'react-router-dom';
import AddAttribute from '../../components/productManagement/AddProduct/AddAttribute';
import { createOrderAction, listAttributeAction } from '../../redux/actions/ProductActions';
import LoaderLin from '../../components/layout/LoaderLin';
import ProductTypeComponent from '../../components/productManagement/AddProduct/AddProductType';


const AddProductAttribute = ({ nav }) => {
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userSignIn);
    const { userInfo } = userLogin;



    return (
        <>
            <div className={nav.isSidebar}>
                <Header nav={nav} />
                <Sidebar nav={nav} />
                <main className="page-content">
                    {/*breadcrumb*/}
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="breadcrumb-title pe-3 text-white">Product Management</div>
                        <div className="ps-3">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to='/'>
                                            <i className="bx bx-home-alt text-white" />
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active text-white" aria-current="page">
                                        Add Product/Attributes
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    {/*end breadcrumb*/}
                    <div className="product-cover bg-dark" />
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className="card shadow-sm border-0">
                                <div className="card-body">
                                    <h5 className="mb-0">Add Product/Product Attributes</h5>
                                    <hr />
                                  <ProductTypeComponent />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*end row*/}
                </main>
            </div>
        </>
    )
}

export default AddProductAttribute