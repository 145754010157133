import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  CircularProgress,
  Checkbox,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import {
  fetchDraftInvoices,
  fetchIssuedInvoices,
} from "../../redux/actions/InvoiceActions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SaveAsPaidModal from "./SaveAsPaidModal";
import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { ListDealerAction } from "../../redux/actions/DealerAction";

function InvoiceListingComp() {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const { error, invoices, loading } = useSelector((state) => state.invoices);
  const { draft, issued } = invoices;
  const { draft: draftLoading, issued: issuedLoading } = loading;
  const listDealer = useSelector((state) => state.listDealer);
  const { loading: loadingClient, dealer, success } = listDealer;
  const [draftPage, setDraftPage] = useState(0);
  const [issuedPage, setIssuedPage] = useState(0);
  const [draftRowsPerPage, setDraftRowsPerPage] = useState(10);
  const [issuedRowsPerPage, setIssuedRowsPerPage] = useState(10);
  const [selectedRows, setSelectedRows] = useState([]);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [selectedIssuedDealer, setSelectedIssuedDealer] = useState("");
  const [selectedDraftDealer, setSelectedDraftDealer] = useState("");

  const dealerId = null;
  useEffect(() => {
    dispatch(
      fetchIssuedInvoices({
        dealerId: selectedIssuedDealer,
        page: issuedPage + 1,
        limit: issuedRowsPerPage,
      })
    );
  }, [dispatch, selectedIssuedDealer, issuedPage, issuedRowsPerPage]);

  useEffect(() => {
    dispatch(
      fetchDraftInvoices({
        dealerId: selectedDraftDealer,
        page: draftPage + 1,
        limit: draftRowsPerPage,
      })
    );
  }, [dispatch, selectedDraftDealer, draftPage, draftRowsPerPage]);

  useEffect(() => {
    dispatch(ListDealerAction(userInfo.token));
  }, []);

  const handleDraftPageChange = (event, newPage) => {
    setDraftPage(newPage);
  };

  const handleIssuedPageChange = (event, newPage) => {
    setIssuedPage(newPage);
  };

  const handleDraftRowsPerPageChange = (event) => {
    setDraftRowsPerPage(parseInt(event.target.value, 10));
    setDraftPage(0);
  };

  const handleIssuedRowsPerPageChange = (event) => {
    setIssuedRowsPerPage(parseInt(event.target.value, 10));
    setIssuedPage(0);
  };

  // Selection Logic
  const handleSelectRow = (id) => {
    setSelectedRows((prev) =>
      prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]
    );
  };
  const handleSelectAll = (data) => {
    if (selectedRows.length === data.length) setSelectedRows([]);
    else setSelectedRows(data.map((row) => row._id));
  };

  const handleSaveAsPaid = async (modeOfPayment) => {
    setLoadingModal(true);
    console.log("Saving invoices as Paid:", selectedRows, modeOfPayment);
    const response = await axios.post(
      `${API_URI}/api/invoices/update-invoice-status`,
      {
        id: selectedRows,
        status: "Paid",
        modeOfPayment,
      },
      {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    setTimeout(() => {
      setLoadingModal(false);
      setSelectedRows([]);
      setOpenModal(false);
    }, 2000);
    dispatch(
      fetchIssuedInvoices({
        dealerId: selectedIssuedDealer,
        page: issuedPage + 1,
        limit: issuedRowsPerPage,
      })
    );
  };
  const handleIssuedDealerChange = async (event) => {
    const dealerId = event.target.value;
    setSelectedIssuedDealer(dealerId);
  };

  const handleDraftDealerChange = async (event) => {
    const dealerId = event.target.value;
    setSelectedDraftDealer(dealerId);
  };

  return (
    <>
      {/* Issued Invoices Table */}

      <div className="card mt-4">
        <div
          className="card-header bg-primary text-white d-flex justify-content-between align-items-center"
          style={{ height: "56px" }}
        >
          <h4 className="mb-0">Invoice List Issued</h4>
          {selectedRows.length > 0 && (
            <div
              className="d-flex align-items-center"
              style={{ position: "relative" }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => setActionAnchorEl(e.currentTarget)}
                style={{ minWidth: "120px" }}
              >
                Actions
              </Button>
            </div>
          )}
          <Menu
            anchorEl={actionAnchorEl}
            open={Boolean(actionAnchorEl)}
            onClose={() => setActionAnchorEl(null)}
          >
            <MenuItem
              onClick={() => {
                setOpenModal(true);
                setActionAnchorEl(null);
              }}
            >
              Save as Paid
            </MenuItem>
          </Menu>
        </div>
        <div className="card-body">
          {/* Dealer Dropdown */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <FormControl style={{ minWidth: 200 }}>
              <InputLabel id="dealer-select-label" sx={{ color: "white" }}>
                Select Dealer
              </InputLabel>
              <Select
                labelId="dealer-select-label"
                value={selectedIssuedDealer}
                onChange={handleIssuedDealerChange}
                sx={{
                  color: "white",
                  "& .MuiSvgIcon-root": { color: "white" },
                }}
                MenuProps={{
                  sx: {
                    "& .MuiPaper-root": {
                      backgroundColor: "#333",
                      color: "white",
                    },
                  },
                }}
              >
                <MenuItem value="">All Dealers</MenuItem>
                {dealer &&
                  dealer.map((dealer) => (
                    <MenuItem key={dealer._id} value={dealer._id}>
                      {dealer.companyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>

          {/* Table and Content */}
          {issuedLoading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < issued.data.length
                          }
                          checked={
                            issued.data.length > 0 &&
                            issued.data.every((row) =>
                              selectedRows.includes(row._id)
                            )
                          }
                          onChange={() => handleSelectAll(issued.data)}
                        />
                      </TableCell>
                      <TableCell>Invoice Number</TableCell>
                      <TableCell>Invoice Date</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>SubTotal</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Buyer</TableCell>
                      <TableCell>Invoice Type</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {issued.data.map((invoice) => (
                      <TableRow
                        key={invoice._id}
                        hover
                        selected={selectedRows.includes(invoice._id)}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedRows.includes(invoice._id)}
                            onChange={() => handleSelectRow(invoice._id)}
                          />
                        </TableCell>
                        <TableCell>{invoice.invoiceNumber}</TableCell>
                        <TableCell>
                          {new Date(invoice.invoiceDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {new Date(invoice.dueDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{invoice.subTotal}</TableCell>
                        <TableCell>{invoice.total}</TableCell>
                        <TableCell>
                          {invoice.buyer.companyName}
                          <br />
                          {invoice.buyer.contact.name}
                          <br />
                          {invoice.buyer.contact.email}
                          <br />
                          {invoice.buyer.contact.phone}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              invoice.type === "Custom" ? "orange" : "blue",
                          }}
                        >
                          {invoice.type}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              invoice.status === "Issued" ? "black" : "green",
                          }}
                        >
                          {invoice.status}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/invoice/${invoice._id}`}
                            style={{
                              textDecoration: "none",
                              color: "blue",
                            }}
                          >
                            View Invoice
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={issued.totalInvoices}
                page={issuedPage}
                onPageChange={handleIssuedPageChange}
                rowsPerPage={issuedRowsPerPage}
                onRowsPerPageChange={handleIssuedRowsPerPageChange}
              />
            </Paper>
          )}
        </div>
      </div>

      {/* Draft Invoices Table */}
      <div className="card mt-4">
        <div className="card-header bg-warning text-white">
          <h4 className="mb-0">Draft Invoices List</h4>
        </div>
        <div className="card-body">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "16px",
            }}
          >
            <FormControl style={{ minWidth: 200 }}>
              <InputLabel id="dealer-select-label" sx={{ color: "white" }}>
                Select Dealer
              </InputLabel>
              <Select
                labelId="dealer-select-label"
                value={selectedDraftDealer}
                onChange={handleDraftDealerChange}
                sx={{
                  color: "white",
                  "& .MuiSvgIcon-root": { color: "white" },
                }}
                MenuProps={{
                  sx: {
                    "& .MuiPaper-root": {
                      backgroundColor: "#333",
                      color: "white",
                    },
                  },
                }}
              >
                <MenuItem value="">All Dealers</MenuItem>
                {dealer &&
                  dealer.map((dealer) => (
                    <MenuItem key={dealer._id} value={dealer._id}>
                      {dealer.companyName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
          {draftLoading ? (
            <div style={{ textAlign: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice Number</TableCell>
                      <TableCell>Invoice Date</TableCell>
                      <TableCell>Due Date</TableCell>
                      <TableCell>SubTotal</TableCell>
                      <TableCell>Total</TableCell>
                      <TableCell>Buyer</TableCell>
                      <TableCell>Invoice Type</TableCell>
                      <TableCell>Status</TableCell>{" "}
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {draft.data.map((invoice) => (
                      <TableRow key={invoice._id}>
                        <TableCell>{invoice.invoiceNumber}</TableCell>
                        <TableCell>
                          {new Date(invoice.invoiceDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>
                          {new Date(invoice.dueDate).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{invoice.subTotal}</TableCell>
                        <TableCell>{invoice.total}</TableCell>
                        <TableCell>
                          {invoice.buyer.companyName}
                          <br />
                          {invoice.buyer.contact.name}
                          <br />
                          {invoice.buyer.contact.email}
                          <br />
                          {invoice.buyer.contact.phone}
                        </TableCell>
                        <TableCell
                          style={{
                            color:
                              invoice.type === "Custom" ? "orange" : "blue",
                          }}
                        >
                          {invoice.type}
                        </TableCell>

                        <TableCell
                          style={{
                            color:
                              invoice.status === "Issued" ? "green" : "red",
                          }}
                        >
                          {invoice.status}
                        </TableCell>
                        <TableCell>
                          <Link
                            to={`/invoice/${invoice._id}`}
                            style={{
                              textDecoration: "none",
                              color: "blue",
                            }}
                          >
                            View Invoice
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component="div"
                count={draft.totalInvoices}
                page={draftPage}
                onPageChange={handleDraftPageChange}
                rowsPerPage={draftRowsPerPage}
                onRowsPerPageChange={handleDraftRowsPerPageChange}
              />
            </Paper>
          )}
        </div>
      </div>
      <SaveAsPaidModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={handleSaveAsPaid}
        loading={loadingModal}
      />
    </>
  );
}

export default InvoiceListingComp;
