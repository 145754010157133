import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import axios from "axios";
import {
  Button,
  TextField,
  Typography,
  CircularProgress,
  Alert,
  Box,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Chip,
} from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
import { API_URI } from "../../constants/GlobalConstants";

const CalculationViewAndEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const [calculation, setCalculation] = useState(null);
  const [formulas, setFormulas] = useState([]);
  const [dependencies, setDependencies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const availableDependencies = [
    "Zebra_blinds",
    "Roller_shades",
    "Fauxwood",
    "Vertical_blinds",
  ];

  const handleAddDependency = (dependency) => {
    if (!dependencies.includes(dependency)) {
      setDependencies([...dependencies, dependency]);
    }
  };

  const handleRemoveDependency = (dependency) => {
    setDependencies((prev) => prev.filter((item) => item !== dependency));
  };

  useEffect(() => {
    const fetchCalculation = async () => {
      try {
        const response = await axios.get(
          `${API_URI}/api/inventory/calculations/${id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        const calcData = response.data.data;
        setCalculation(calcData);
        setFormulas(
          calcData.formulas.map((formula) => ({
            customName: formula.key,
            dimension: formula.operand,
            expression: formula.expression,
          }))
        );
        setDependencies(calcData.dependencies);
      } catch (error) {
        setError("Failed to fetch calculation.");
      } finally {
        setLoading(false);
      }
    };

    fetchCalculation();
  }, [id, userInfo.token]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `${API_URI}/api/inventory/calculations/edit`,
        {
          calculationId: id,
          formulas: formulas.map((formula) => ({
            key: formula.customName,
            operand: formula.dimension,
            expression: formula.expression,
          })),
          dependencies: dependencies,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setSuccess(response.data.message);
      navigate("/erp/settings/all-production-calculations");
    } catch (error) {
      setError("Failed to update calculation.");
    }
  };

  const updateExpressionDimension = (
    expression,
    oldDimension,
    newDimension
  ) => {
    // Create a regex that matches the dimension word boundary
    const dimensionRegex = new RegExp(`\\b${oldDimension}\\b`, "g");
    return expression.replace(dimensionRegex, newDimension);
  };

  const handleUpdateFormula = (index, field, value) => {
    const updatedFormulas = formulas.map((item, i) => {
      if (i === index) {
        const updatedItem = { ...item, [field]: value };

        // If the dimension field is being updated, also update the expression
        if (field === "dimension") {
          const oldDimension = item.dimension;
          const newDimension = value;
          updatedItem.expression = updateExpressionDimension(
            item.expression,
            oldDimension,
            newDimension
          );
        }

        return updatedItem;
      }
      return item;
    });
    setFormulas(updatedFormulas);
  };

  const handleAddFormula = () => {
    setFormulas([
      ...formulas,
      { customName: "", dimension: "", expression: "" },
    ]);
  };

  const handleRemoveFormula = (index) => {
    const newFormulas = [...formulas];
    newFormulas.splice(index, 1);
    setFormulas(newFormulas);
  };

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#f5f5f5",
        borderRadius: 2,
        minHeight: "60vh",
        color: "#000",
      }}
    >
      <Typography variant="h4" gutterBottom>
        View & Edit Calculation
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : (
        <form onSubmit={handleUpdate}>
          {success && (
            <Alert severity="success" className="mb-3">
              {success}
            </Alert>
          )}
          <Typography variant="h6" gutterBottom>
            Inventory: {calculation.inventoryId.name}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {availableDependencies.map((dependency) => {
                  const isAdded = dependencies.includes(dependency);
                  return (
                    <Chip
                      key={dependency}
                      label={dependency}
                      onClick={() =>
                        !isAdded && handleAddDependency(dependency)
                      }
                      onDelete={
                        isAdded
                          ? () => handleRemoveDependency(dependency)
                          : null
                      }
                      deleteIcon={isAdded ? <Delete /> : null}
                      sx={{
                        bgcolor: isAdded ? "red" : "lightblue",
                        color: isAdded ? "white" : "black",
                        cursor: isAdded ? "pointer" : "default",
                      }}
                    />
                  );
                })}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Selected Dependencies"
                  value=""
                  InputProps={{
                    readOnly: true,
                    startAdornment: (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 1,
                          paddingY: 2,
                        }}
                      >
                        {dependencies.map((dependency, index) => (
                          <Chip
                            key={index}
                            label={dependency}
                            onDelete={() => handleRemoveDependency(dependency)}
                            deleteIcon={<Delete />}
                            sx={{
                              bgcolor: "red",
                              color: "white",
                            }}
                          />
                        ))}
                      </Box>
                    ),
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            {formulas.map((formula, index) => (
              <Grid
                container
                spacing={2}
                key={index}
                sx={{ mb: 2, mt: 2, alignItems: "center" }}
              >
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Component Name"
                    value={formula.customName}
                    onChange={(e) =>
                      handleUpdateFormula(index, "customName", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <InputLabel>Dimension</InputLabel>
                    <Select
                      value={formula.dimension}
                      onChange={(e) =>
                        handleUpdateFormula(index, "dimension", e.target.value)
                      }
                      fullWidth
                    >
                      <MenuItem value="width">Width</MenuItem>
                      <MenuItem value="height">Height</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Expression"
                    value={formula.expression}
                    onChange={(e) =>
                      handleUpdateFormula(index, "expression", e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <IconButton
                    onClick={() => handleRemoveFormula(index)}
                    aria-label="delete"
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            {/* <Grid item xs={12} sx={{ mt: 2 }}> */}
            <Button
              variant="outlined"
              onClick={handleAddFormula}
              startIcon={<Add />}
            >
              Add Formula
            </Button>
            {/* </Grid> */}
          </Grid>

          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}
          >
            Update Calculation
          </Button>
        </form>
      )}
    </Box>
  );
};

export default CalculationViewAndEdit;
