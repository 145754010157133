import { FETCH_DRAFT_INVOICES_FAILURE, FETCH_DRAFT_INVOICES_REQUEST, FETCH_DRAFT_INVOICES_SUCCESS, FETCH_ESTIMATES_FAILURE, FETCH_ESTIMATES_REQUEST, FETCH_ESTIMATES_SUCCESS, FETCH_ISSUED_INVOICES_FAILURE, FETCH_ISSUED_INVOICES_REQUEST, FETCH_ISSUED_INVOICES_SUCCESS, INVOICE_FETCH_FAIL, INVOICE_FETCH_REQUEST, INVOICE_FETCH_SUCCESS } from "../../constants/InvoiceConstants";

  
const invoiceState = {
  loading: {
    issued: false,
    draft: false,
  },
  error: {
    issued: null,
    draft: null,
  },
  invoices: {
    issued: { data: [], currentPage: 1, totalPages: 0, totalInvoices: 0 },
    draft: { data: [], currentPage: 1, totalPages: 0, totalInvoices: 0 },
  },
};
  
export const invoiceReducer = (state = invoiceState, action) => {
  switch (action.type) {
    case FETCH_ISSUED_INVOICES_REQUEST:
      return { ...state, loading: { ...state.loading, issued: true }, error: { ...state.error, issued: null } };
    case FETCH_ISSUED_INVOICES_SUCCESS:
      return { ...state, loading: { ...state.loading, issued: false }, invoices: { ...state.invoices, issued: action.payload } };
    case FETCH_ISSUED_INVOICES_FAILURE:
      return { ...state, loading: { ...state.loading, issued: false }, error: { ...state.error, issued: action.payload } };

    case FETCH_DRAFT_INVOICES_REQUEST:
      return { ...state, loading: { ...state.loading, draft: true }, error: { ...state.error, draft: null } };
    case FETCH_DRAFT_INVOICES_SUCCESS:
      return { ...state, loading: { ...state.loading, draft: false }, invoices: { ...state.invoices, draft: action.payload } };
    case FETCH_DRAFT_INVOICES_FAILURE:
      return { ...state, loading: { ...state.loading, draft: false }, error: { ...state.error, draft: action.payload } };

    default:
      return state;
  }
};

  const InvoiceById = {
    invoice: null,
    pdf: "",
    loading: false,
    error: null,
  };
  
  export const CustomInvoiceReducer = (state = InvoiceById, action) => {
    switch (action.type) {
      case INVOICE_FETCH_REQUEST:
        return { ...state, loading: true };
      case INVOICE_FETCH_SUCCESS:
        return {
          ...state,
          loading: false,
          invoice: action.payload.invoice,
          pdf: action.payload.pdf,
          documentType:action.payload.documentType,
          error:null
        };
      case INVOICE_FETCH_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };

  const EstimatedState = {
    loading: false,
    estimates: [],
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalEstimates: 0,
  };
  
  export const estimatesReducer = (state = EstimatedState, action) => {
    switch (action.type) {
      case FETCH_ESTIMATES_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_ESTIMATES_SUCCESS:
        return {
          ...state,
          loading: false,
          estimates: action.payload.data,
          currentPage: action.payload.currentPage,
          totalPages: action.payload.totalPages,
          totalEstimates: action.payload.totalEstimates,
        };
      case FETCH_ESTIMATES_FAILURE:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };