import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
} from "@mui/material";

export default function CreditHistory({ creditData, page, limit, setPage }) {
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  if (!creditData?.history?.length) {
    return (
      <Typography
        variant="h6"
        component="h3"
        className="fw-semibold mb-3"
        color={"white"}
      >
        No Credit History Available
      </Typography>
    );
  }

  return (
    <div>
      <Typography
        variant="h6"
        component="h3"
        className="fw-semibold mb-3"
        color={"white"}
      >
        Credit History
      </Typography>
      <TableContainer component={Paper} className="shadow-sm">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="fw-bold">Date</TableCell>
              <TableCell className="fw-bold">Type</TableCell>
              <TableCell className="text-end fw-bold">Amount</TableCell>
              <TableCell className="text-end fw-bold">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {creditData.history.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>
                  {new Date(transaction.timestamp).toLocaleDateString()}
                </TableCell>
                <TableCell>
                  {transaction.type.replace("_", " ").toUpperCase()}
                </TableCell>
                <TableCell
                  className={`text-end fw-semibold ${
                    transaction.type === "debit_deduction"
                      ? "text-danger"
                      : "text-success"
                  }`}
                >
                  {transaction.type === "debit_deduction"
                    ? `-$${Math.abs(transaction.amount).toFixed(2)}`
                    : `+$${Math.abs(transaction.amount).toFixed(2)}`}
                </TableCell>
                <TableCell className="text-end">
                  {transaction.description || "No description"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Pagination controls */}
      <Pagination
        count={Math.ceil(creditData.totalHistoryCount / limit)}
        page={page}
        onChange={handlePageChange}
        color="primary"
        className="mt-3"
        sx={{
          "& .MuiPaginationItem-root": {
            color: "white",
          },
          "& .MuiPaginationItem-root.Mui-selected": {
            backgroundColor: "white",
            color: "black",
          },
          "& .MuiPaginationItem-root:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
          },
          "& .MuiPaginationItem-ellipsis": {
            color: "white",
          },
          "& .MuiPaginationItem-icon": {
            color: "white",
            fontSize: "1.5rem",
          },
          "& .MuiPaginationItem-icon:hover": {
            color: "rgba(255, 255, 255, 0.8)",
          },
        }}
      />
    </div>
  );
}
