import { useState } from "react";
import AddCustomLiftHardwareModal from "../ui/AddCustomLiftStyle";

const TableInputs = ({
  order,
  handleInputChange,
  addRow,
  deleteRow,
  onFocus,
  inputRefs,
  isReadOnly,
  settingsData,
  headrailConfig,
  handleRecalculate,
  LiftStyle,
  Motor,
}) => {
  const additionalColumns = headrailConfig?.formulas || [];
  const [showCustomLiftStyleModal, setShowCustomLiftStyleModal] =
    useState(false);

  return (
    <tbody className="table-bg-custom text-dark">
      {order.map((row, index) => (
        <tr key={index} className="table-row-custom">
          <td className="p-1 border table-border-custom">
            <p
              className="form-control border-light text-dark table-input-custom"
              style={{ width: "50px" }}
            >
              {index + 1}
            </p>
          </td>
          <td className="p-1 border table-border-custom">
            <input
              name="width"
              type="text"
              className="form-control border-dark text-dark table-input-custom"
              style={{ width: "100px" }}
              value={row.width || ""}
              onChange={(e) =>
                handleInputChange(index, "width", e.target.value)
              }
              onBlur={() => handleRecalculate && handleRecalculate(index)}
              onFocus={() => onFocus(index, "width")}
              ref={(el) =>
                (inputRefs.current[index] = {
                  ...inputRefs.current[index],
                  width: el,
                })
              }
              readOnly={isReadOnly}
            />
          </td>
          <td className="p-1 border table-border-custom">
            <input
              name="height"
              type="text"
              className="form-control border-dark text-dark table-input-custom"
              style={{ width: "100px" }}
              value={row.height || ""}
              onChange={(e) =>
                handleInputChange(index, "height", e.target.value)
              }
              onBlur={() => handleRecalculate && handleRecalculate(index)}
              onFocus={() => onFocus(index, "height")}
              ref={(el) =>
                (inputRefs.current[index] = {
                  ...inputRefs.current[index],
                  height: el,
                })
              }
              readOnly={isReadOnly}
            />
          </td>
          <td className="p-1 border table-border-custom">
            {row.width && row.height && (
              <select
                name="liftStyle"
                className="form-control border-dark text-dark table-input-custom"
                value={row.liftStyle || ""}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  handleInputChange(index, "liftStyle", selectedValue);
                  if (selectedValue === "custom") {
                    setShowCustomLiftStyleModal(true);
                  }
                }}
                style={{ width: "100%" }}
              >
                <option value="">---Select Lift Style---</option>
                {LiftStyle.map((style) => (
                  <option key={style.id} value={style.id}>
                    {style.name}
                  </option>
                ))}
                <option value="">---Select Lift Motor---</option>
                {Motor.map((style) => (
                  <option key={style.id} value={style.id}>
                    {style.name}
                  </option>
                ))}
                <option value="custom">Custom Lift Style</option>
              </select>
            )}
          </td>
          {settingsData?.showLocation && (
            <td className="p-1 border table-border-custom">
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row?.location || ""}
                style={{ width: "100%" }}
                onChange={(e) =>
                  handleInputChange(index, "location", e.target.value)
                }
              />
            </td>
          )}
          {settingsData?.showRemark && (
            <td className="p-1 border table-border-custom">
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row.remark || ""}
                onChange={(e) =>
                  handleInputChange(index, "remark", e.target.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          )}
          {settingsData?.showControl && (
            <td className="p-1 border table-border-custom">
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row.control || ""}
                onChange={(e) =>
                  handleInputChange(index, "control", e.target.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          )}
          {settingsData?.showMountLift && (
            <td className="p-1 border table-border-custom">
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row.lift || ""}
                onChange={(e) =>
                  handleInputChange(index, "lift", e.target.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          )}
          {settingsData?.showTile && (
            <td className="p-1 border table-border-custom">
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row.tile || ""}
                onChange={(e) =>
                  handleInputChange(index, "tile", e.target.value)
                }
                style={{ width: "100%" }}
              />
            </td>
          )}
          {additionalColumns.map((column) => (
            <td className="p-1 border table-border-custom" key={column.key}>
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                value={row[column.key] || ""}
                style={{ width: "100%" }}
                disabled
              />
            </td>
          ))}
          <td className="p-1 border table-border-custom">
            {row.factor && (
              <input
                type="text"
                className="form-control border-dark text-dark table-input-custom"
                style={{ width: "100%" }}
                value={row.factor || ""}
                disabled
              />
            )}
          </td>

          <td className="p-1 border table-border-custom">
            <button
              type="button"
              className="btn btn-success w-100"
              onClick={() => addRow(index)}
            >
              <i className="bi bi-plus-circle fs-5 text-white"></i>
            </button>
          </td>
          <td className="p-1 border table-border-custom">
            {index !== 0 && (
              <button
                type="button"
                className="btn btn-danger w-100"
                onClick={() => deleteRow(index)}
              >
                <i className="bi bi-trash fs-5 text-white"></i>
              </button>
            )}
          </td>
        </tr>
      ))}
      <AddCustomLiftHardwareModal
        show={showCustomLiftStyleModal}
        handleClose={() => setShowCustomLiftStyleModal(false)}
      />
    </tbody>
  );
};

export default TableInputs;
