import React, { useState, useEffect } from "react";
import { Button, Card, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  addCredit,
  getCreditData,
  withdrawCredit,
} from "../../../redux/actions/CreditSystemActions";
import CreditHistory from "./CreditHistory";
import TransactionModal from "./CreditTransactionModal";
import Loader from "../../layout/Loader";

export default function CreditSystemPage() {
  const dispatch = useDispatch();
  const { creditData, loading, error } = useSelector((state) => state.credit);
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [page, setPage] = useState(1); 
  const [limit] = useState(10); 

  useEffect(() => {
    dispatch(getCreditData(id, page, limit)); 
  }, [dispatch, id, page, limit]);

  const handleDeposit = () => {
    setTransactionType("deposit");
    setOpenModal(true);
  };

  const handleWithdraw = () => {
    setTransactionType("withdraw");
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSubmit = () => {
    const action =
      transactionType === "deposit"
        ? addCredit(id, amount, description)
        : withdrawCredit(id, amount, description);
  
    dispatch(action).then(() => {
      dispatch(getCreditData(id, page, limit));
    });
  
    setAmount("");
    setDescription("");
    setOpenModal(false);
  };

  return (
    <div className="container mx-auto py-10">
      <Typography variant="h4" component="h1" className="font-bold mb-6">
        Credit System
      </Typography>
      <Card className="bg-light rounded-lg shadow-lg p-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <Typography
            variant="h5"
            component="h2"
            className="fw-semibold"
            color={"white"}
          >
            Credit Balance
          </Typography>
          <Typography
            variant="h4"
            component="div"
            className="fw-bold text-success"
          >
            {loading ? <Loader /> : `$${creditData.totalCredits}`}
          </Typography>
        </div>
        <div className="d-flex gap-3 mb-4">
          <Button
            variant="contained"
            color="primary"
            className="btn btn-primary fw-bold px-4"
            onClick={handleWithdraw}
          >
            Deduct Credit
          </Button>
          <Button
            variant="contained"
            color="secondary"
            className="btn btn-secondary fw-bold px-4"
            onClick={handleDeposit}
          >
            Add Credit
          </Button>
        </div>
        <CreditHistory
          creditData={creditData}
          page={page}
          limit={limit}
          setPage={setPage}
        />
        <TransactionModal
          open={openModal}
          transactionType={transactionType}
          amount={amount}
          description={description}
          onAmountChange={(e) => setAmount(e.target.value)}
          onDescriptionChange={(e) => setDescription(e.target.value)}
          onSubmit={handleSubmit}
          onClose={handleCloseModal}
        />
      </Card>
    </div>
  );
}
