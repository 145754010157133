import React, { useState } from 'react';
import { Modal, Box, Typography, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SaveAsPaidModal = ({ open, onClose, onSave, loading }) => {
  const [modeOfPayment, setModeOfPayment] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(modeOfPayment); 
  };

  const handleModeOfPaymentChange = (event) => {
    setModeOfPayment(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="h6" id="modal-title">
          Save as Paid
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Are you sure you want to mark the selected invoices as paid?
        </Typography>
        
        {/* Mode of Payment Dropdown */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Mode of Payment</InputLabel>
          <Select
            value={modeOfPayment}
            onChange={handleModeOfPaymentChange}
            label="Mode of Payment"
          >
            <MenuItem value="Check">Check</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            <MenuItem value="Credit Card">Credit Card</MenuItem>
            <MenuItem value="Bank Remittance">Bank Remittance</MenuItem>
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
          <Button variant="contained" onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSave}
            color="primary"
            disabled={loading || !modeOfPayment} 
          >
            {loading ? <CircularProgress size={24} /> : 'Save as Paid'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveAsPaidModal;
