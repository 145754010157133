//MUI Dropdown Action

import OrderOverviewUI from "./OrderComp/tables/OrderOverviewUI";
import { useEffect, useRef, useState } from "react";
import {
  getProductionData,
  updateOrderArr,
  updateOrderFactor,
} from "./functions/OrderFunctions";
import { useDispatch, useSelector } from "react-redux";
import { UpdateOrderAction } from "../../redux/actions/OrderAction";
import Loader from "../layout/Loader";
import { useNavigate } from "react-router";
import Badge from "../layout/Badge";
import fileDownload from "js-file-download";
import InputTableHeader from "./reusables/InputTableHeader";
import TableInputs from "./reusables/TableInputs";
import { fetchSettingsAction } from "../../redux/actions/UserSettingsActions";
import CustomKeyboard from "./functions/CustomKeyboard";
import SettingsModal from "./reusables/UseSettingsModal";
import {
  fetchCalculations,
  getFabricItems,
  getInvItemsByCat,
  getLiftStyleItems,
} from "../../redux/actions/InventoryAction";
import { recalculateOrders } from "./reusables/RecalculateOrder";
import MaterialOrdersTable from "./reusables/MaterialTable";
import { productTypeOptions } from "./functions/ComponentOptions";
import { ListDealerAction } from "../../redux/actions/DealerAction";
import AddCustomFabricModal from "./ui/AddCustomFabricModal";
import InputSettingsDropdown from "./orderForm/InputSettings";
import { fetchMatrixByProductType } from "../../redux/actions/MatrixAction";
import { getProductTypesAction } from "../../redux/actions/ProductTypeactions";
import { formatLabel } from "./orderForm/ProductTypeMapping";
import { useSnackbarNotification } from "./reusables/UseSnackBarNotification";
import {
  checkHeadrailPricing,
  checkProductRate,
  getSelectedDealer,
} from "./functions/HelperFunctions";
import PricingModal from "./ui/PriceNotAvailableModal";

const OrderDetail = ({ o }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const orderUpdate = useSelector((state) => state.UpdateOrder);
  const { loading: updateLoading, data, success: successOrder } = orderUpdate;
  const { userInfo } = useSelector((state) => state.userSignIn);
  const settings = useSelector((state) => state.userSettings);
  const { loading, settings: settingsData } = settings;
  const listDealer = useSelector((state) => state.listDealer);
  const { loading: loadingClient, dealer, success } = listDealer;
  const [sleeveOption, setSleeveOption] = useState(
    o?.productHeadrail?.sleeve === "on" ? "sleeveOn" : "sleeveOff"
  );
  const [orderFactor, setOrderFactor] = useState(o.orderFactor);
  const [orderArr, setOrderArr] = useState(
    o.productType !== "Materials" ? o.orderArray : []
  );
  const [materialOrders, setMaterialOrders] = useState(
    o.productType === "Materials" ? o.orderArray : []
  );
  const [updateLiftStyle, setUpdateLiftStyle] = useState(o.liftStyle);
  const [updateProMaterial, setUpdateProMaterial] = useState(o.productMaterial);
  const [headrail, setHeadrail] = useState(o.productHeadrail.itemId || "");
  const [productType, setProductType] = useState(o.productType);
  const [updateRemark, setUpdateRemark] = useState(o.remark);
  const [updateTag, setUpdateTag] = useState(o.tag);

  //keyboard states and refs
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [focusedIndex, setFocusedIndex] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [showAddMaterialModal, setShowAddMaterialModal] = useState(false);
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const { data: inventoryData } = inventoryItems;
  const items = inventoryData?.[0]?.items || [];
  const { calculations } = useSelector((state) => state.inventoryCalculation);
  const fabricItemsState = useSelector((state) => state.fabricItems);
  const {
    loading: fabricLoading,
    error: fabricError,
    fabrics,
  } = fabricItemsState;
  const Fabrics = fabrics?.[0]?.items || [];
  const { liftStyleItems } = useSelector((state) => state.liftSyleItems);
  const liftStyleCategory = liftStyleItems?.find(
    (item) => item.category === "Lift_hardware"
  );
  const motorCategory = liftStyleItems?.find(
    (item) => item.category === "Motor"
  );

  const LiftStyle = liftStyleCategory?.items || [];
  const Motor = motorCategory?.items || [];
  const [dealerInfo, setDealerInfo] = useState({
    dealerId: o.dealerId,
    dealerName: o.dealerName,
  });
  const matrixState = useSelector((state) => state.matrix);
  const { loading: matrixLoading, matrix, error } = matrixState;
  const [showCustomFabricModal, setShowCustomFabricModal] = useState(false);
  const [showAdditionInput, setShowAdditionInput] = useState(false);
  const [showDeductionInput, setShowDeductionInput] = useState(false);
  const [deduction, setDeduction] = useState({ val: null, trigger: false });
  const [addition, setAddition] = useState({ val: null, trigger: false });
  const [activeRowIndex, setActiveRowIndex] = useState(null);
  const productTypesList = useSelector((state) => state.productTypes);
  const { productTypes } = productTypesList;
  const { showSnackbar, SnackbarComponent } = useSnackbarNotification();
  const inputRefs = useRef([]);
  const tableContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //-Cancel the Order Edits and returns to View
  const handleSettingsClick = () => {
    setShowSettingsModal(true);
  };

  const handleCloseModal = () => {
    setShowSettingsModal(false);
  };
  const headrailConfig = items?.find(
    (item) => item.id === headrail || item.name === headrail
  );
  const fetchSettings = () => dispatch(fetchSettingsAction(userInfo.token));
  const fetchDealers = () => dispatch(ListDealerAction(userInfo.token));

  useEffect(() => {
    if (userInfo?.token) {
      fetchSettings();
      fetchDealers();
      dispatch(getProductTypesAction(userInfo.token));
      dispatch(fetchMatrixByProductType(o?.productType, userInfo.token));
    }
  }, []);

  useEffect(() => {
    if (productType && headrailConfig) {
      dispatch(
        fetchCalculations(productType, headrailConfig?.id, userInfo.token)
      );
    }
  }, [userInfo.token, productType, headrailConfig]);

  const renderHeadrailOptions = (productType) => {
    if (!items) return null;

    return items.map((item, index) => {
      if (productType && !item.proDependency.includes(productType)) {
        return null;
      }

      const isSelected = headrailConfig
        ? headrailConfig?.id === item.id || headrailConfig?.name === item.name
        : false;

      return (
        <option
          key={index}
          value={item.id}
          selected={isSelected}
          defaultValue={headrailConfig?.name}
        >
          {item.name}
        </option>
      );
    });
  };

  const handleHeadrailChange = (e) => {
    const newHeadrail = e.target.value;
    setHeadrail(newHeadrail);

    const selectedHeadrailConfig = items?.find(
      (item) => item.id === newHeadrail || item.name === newHeadrail
    );

    if (!selectedHeadrailConfig?.config?.headrail?.sleeveOn) {
      setSleeveOption("");
    }
  };

  const handleUpdateOrder = async () => {
    let filteredOrder = orderArr;

    const selectedDealer = getSelectedDealer(dealer, dealerInfo.dealerId);

    if (!selectedDealer) {
      showSnackbar(
        "Dealer not found. Please select a valid dealer or refresh the page.",
        "error"
      );
      return;
    }
    if (productType !== "Materials") {
      const validRate = checkProductRate(selectedDealer, productType);

      if (!validRate) {
        showSnackbar(
          `Pricing is missing or invalid for the selected product type (${productType}). Please update the product rates to proceed.`,
          "error"
        );
        setIsModalOpen(true);
        return;
      }

      const pricingExists = checkHeadrailPricing(
        selectedDealer,
        headrail,
        productType
      );

      if (!pricingExists) {
        showSnackbar(
          `Pricing is missing for the selected product type (${productType}) and headrail. Please add pricing before submitting.`,
          "error"
        );
        return;
      }

      filteredOrder = orderArr.filter((row) => {
        const hasWidth = row.width && row.width.trim() !== "";
        const hasHeight = row.height && row.height.trim() !== "";
        const hasLiftStyle = row.liftStyle && row.liftStyle.trim() !== "";

        if (hasWidth && hasHeight && !hasLiftStyle) {
          showSnackbar(
            "Please make sure all rows with width and height have a selected Lift Style!",
            "error"
          );
          return false;
        }

        return row.id !== -1 && hasWidth && hasHeight;
      });

      if (filteredOrder.length === 0) {
        showSnackbar(
          "No valid rows found for submission. Please check the input data.",
          "error"
        );
        return;
      }
    }

    const val = {
      orderId: o._id,
      dealerId: dealerInfo.dealerId,
      dealerName: dealerInfo.dealerName,
      productTag: updateTag,
      productMaterial: updateProMaterial,
      liftStyle: updateLiftStyle,
      orderRemark: updateRemark,
      order: filteredOrder,
      orderFactor: orderFactor,
      productType: productType,
      headrail: headrailConfig?.id || headrail,
      sleeveOption,
      materialOrders,
    };

    try {
      await dispatch(UpdateOrderAction(val, userInfo.token));
      // showSnackbar("Order updated successfully!", "success");
    } catch (error) {
      showSnackbar(
        "There was an error updating the order. Please try again.",
        "error"
      );
    }
  };
  // Any change in orderArr will update the factor
  useEffect(() => {
    dispatch({ type: "ORDER_UPDATE_RESET" });
    setOrderFactor(updateOrderFactor(orderArr));
  }, [orderArr]);

  //download the data
  useEffect(() => {
    if (data) {
      fileDownload(
        data,
        `${dealerInfo.dealerName} ${productType} ${updateTag}.pdf`
      );
    }
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(max-width: 1000px)").matches) {
        setIsReadOnly(true);
        setIsKeyboardVisible(true);
      } else {
        setIsReadOnly(false);
        setIsKeyboardVisible(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleKeyPress = (key) => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      const currentValue = orderArr[row][field];

      const keyString = String(key);

      if (keyString.includes("/")) {
        const parts = currentValue.split(" ");
        if (parts.length > 1) {
          parts[parts.length - 1] = keyString;
        } else {
          parts.push(keyString);
        }
        const newValue = parts.join(" ");
        handleInputChange(row, field, newValue);
      } else {
        const newValue = currentValue + keyString;
        handleInputChange(row, field, newValue);
      }
    }
  };

  const handleNext = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      if (field === "width") {
        setFocusedIndex({ row, field: "height" });
      } else if (field === "height" && row < orderArr.length - 1) {
        setFocusedIndex({ row: row + 1, field: "width" });
      }
    }
  };

  const handlePrevious = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      if (field === "height" && row > 0) {
        setFocusedIndex({ row: row - 1, field: "width" });
      } else if (field === "width") {
        setFocusedIndex({ row, field: "height" });
      }
    }
  };

  const handleEnter = () => {
    if (focusedIndex !== null) {
      const { row } = focusedIndex;
      if (row < orderArr.length - 1) {
        setFocusedIndex({ row: row + 1, field: "width" });
      } else {
        setOrderArr([
          ...orderArr,
          {
            width: "",
            height: "",
            sqft: 0,
            remark: "",
            location: "",
            liftStyle: "",
          },
        ]);
        setFocusedIndex({ row: row + 1, field: "width" });
      }
    }
  };

  const handleClear = () => {
    if (focusedIndex !== null) {
      const { row, field } = focusedIndex;
      handleInputChange(row, field, "");
    }
  };

  const handleFocus = (row, field) => {
    if (field === "width" || field === "height") {
      setFocusedIndex({ row, field });
      if (window.matchMedia("(max-width: 1000px)").matches) {
        setIsKeyboardVisible(true);
        setActiveRowIndex(row);
      }
    } else {
      setIsKeyboardVisible(false);
    }
  };
  const handleCloseKeyboard = () => {
    setIsKeyboardVisible(false);
  };

  const addRow = (index) => {
    const newOrder = [...orderArr];
    newOrder.splice(index + 1, 0, {
      width: "",
      height: "",
      factor: 0,
      location: "",
      remark: "",
      liftStyle: "",
    });
    setOrderArr(newOrder);
  };

  const deleteRow = (index) => {
    // Create a new array excluding the row at the specified index
    const newOrder = orderArr.filter((_, i) => i !== index);
    setOrderArr(newOrder);
  };

  const handleInputChange = (index, field, value) => {
    // Create a shallow copy of the order array
    const updatedOrder = [...orderArr];

    // Get the current row
    const currentRow = updatedOrder[index];

    updatedOrder[index] = { ...currentRow, [field]: value };

    setOrderArr(updatedOrder);

    const lastRow = updatedOrder[updatedOrder.length - 1];
    const isLastRowFilled = lastRow.width && lastRow.height;

    // Add an empty row if the last row is filled
    if (isLastRowFilled) {
      updatedOrder.push({
        width: "",
        height: "",
        control: "",
        lift: "",
        mount: "",
        remark: "",
        location: "",
        tile: "",
        deduction: "",
        addition: "",
        liftStyle: "",
      });

      setOrderArr(updatedOrder);
    }
  };

  const handleRecalculate = (index) => {
    const updatedOrder = [...orderArr];
    const currentRow = updatedOrder[index];

    // Check if deduction or addition has changed
    const deductionValue = deduction.trigger ? deduction.val : null;
    const additionValue = addition.trigger ? addition.val : null;

    const prevDeduction = currentRow.deduction;
    const prevAddition = currentRow.addition;

    // Recalculate only if deduction or addition has changed
    if (deductionValue !== prevDeduction || additionValue !== prevAddition) {
      // Perform recalculation for width/height with the current values
      const recalculatedRow = getProductionData(
        productType,
        currentRow.width,
        currentRow.height,
        currentRow.control,
        currentRow.lift,
        currentRow.mount,
        currentRow.remark,
        currentRow.location,
        calculations[0],
        deductionValue,
        additionValue,
        currentRow.tile,
        matrix,
        currentRow.liftStyle
      );

      // Update the row with recalculated values
      updatedOrder[index] = { ...currentRow, ...recalculatedRow };

      // Update the order state with recalculated values
      setOrderArr(updatedOrder);
    }
  };

  useEffect(() => {
    if (
      calculations &&
      calculations[0] &&
      matrix &&
      Object.keys(matrix).length > 0 &&
      orderArr
    ) {
      recalculateOrders(
        orderArr,
        productType,
        calculations[0],
        setOrderArr,
        deduction.val,
        addition.val,
        matrix
      );
    }
  }, [calculations]);

  useEffect(() => {
    if (userInfo?.token && productType) {
      dispatch(getInvItemsByCat("Headrail", userInfo.token, productType));
      dispatch(getFabricItems("Fabric", userInfo.token, productType));
      dispatch(
        getLiftStyleItems("Lift_hardware,Motor", userInfo.token, productType)
      );
    }
  }, [userInfo.token, dispatch, productType]);

  const handleSleeveChange = (e) => {
    setSleeveOption(e.target.value);
  };

  const handleDealerChange = (e) => {
    const [name, id] = e.target.value.split("_");
    setDealerInfo({
      dealerId: id,
      dealerName: name,
    });
  };
  useEffect(() => {
    setUpdateProMaterial(o.productMaterial);
  }, [o.productMaterial]);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue === "custom") {
      setShowCustomFabricModal(true);
    } else {
      setUpdateProMaterial(selectedValue);
    }
  };
  const handleAddMaterialOrder = (index, key, value) => {
    const updatedOrders = [...materialOrders];
    if (index !== null) {
      updatedOrders[index] = { ...updatedOrders[index], [key]: value };
    } else {
      // Add new material order if index is null
      updatedOrders.push({
        category: "",
        material: "",
        customCost: "",
        quantity: 1,
        liftStyle: "",
      });
    }
    setMaterialOrders(updatedOrders);
  };

  // Handler to remove material orders
  const handleRemoveMaterialOrder = (index) => {
    const updatedOrders = materialOrders.filter((_, i) => i !== index);
    setMaterialOrders(updatedOrders);
  };

  const handleProductTypeChange = (e) => {
    const { value } = e.target;

    setProductType(value);

    if (value === "Materials") return;

    const selectedDealer = getSelectedDealer(dealer, dealerInfo.dealerId);

    if (value !== "") {
      if (!selectedDealer) {
        showSnackbar(
          "Dealer not found. Please select a valid dealer or refresh the page.",
          "error"
        );
        return;
      }

      const validRate = checkProductRate(selectedDealer, value);
      if (!validRate) {
        showSnackbar(
          `Pricing is missing or invalid for the selected product type (${value}). Please update the product rates to proceed.`,
          "error"
        );
        setIsModalOpen(true);
        return;
      }
    }
  };

  const handleAdditionClick = () => {
    setShowAdditionInput(true);
    setShowDeductionInput(false);
  };

  const handleDeductionClick = () => {
    setShowDeductionInput(true);
    setShowAdditionInput(false);
  };
  const handleFractionChange = (e) => {
    const value = e.target.value;
    if (showDeductionInput) {
      setDeduction({ val: value, trigger: true });
    } else if (showAdditionInput) {
      setAddition({ val: value, trigger: true });
    }
  };

  const handleCancel = () => {
    setShowAdditionInput(false);
    setShowDeductionInput(false);
    // Reset the deduction and addition values
    setAddition({ val: null, trigger: false });
    setDeduction({ val: null, trigger: false });
  };
  const handleCustomFabricModalClose = () => {
    setShowCustomFabricModal(false);
    dispatch(getFabricItems("Fabric", userInfo.token, productType));
  };

  const handleNavigateToPricing = () => {
    navigate(`/dealer/edit-product-rate/${dealerInfo.dealerId}`);
  };

  return (
    <>
      {successOrder ? (
        <Badge
          p={{
            type: "success",
            message: "Action completed successfully👍",
            redirect: `/order/order-detail/` + o._id,
            redirectName: "View updated order",
          }}
        />
      ) : updateLoading ? (
        <Loader />
      ) : (
        <>
          <>
            <div className="row mt-5 mb-4">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h6 className="mb-2 text-uppercase">Order Edit</h6>
                </div>
                <div className="col-lg-3 d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-success px-5 radius-30"
                    onClick={handleUpdateOrder}
                  >
                    Update the Order
                  </button>
                </div>
                <div className="col-lg-3 d-flex justify-content-end">
                  <InputSettingsDropdown
                    handleSettingsClick={handleSettingsClick}
                    handleAdditionClick={handleAdditionClick}
                    handleDeductionClick={handleDeductionClick}
                    showSettingsModal={showSettingsModal}
                    handleCloseModal={handleCloseModal}
                  />
                </div>
              </div>
              <div className="col-lg-6 row g-3">
                <div className="col-lg-4">
                  <label className="form-label">Company Name</label>
                  <select
                    name="dealerName"
                    className="form-select"
                    value={`${dealerInfo.dealerName}_${dealerInfo.dealerId}`}
                    onChange={handleDealerChange}
                  >
                    {/* Map through dealer options */}
                    {dealer &&
                      dealer.map((c) => (
                        <option key={c._id} value={`${c.name}_${c._id}`}>
                          {`${c.companyName}-${c.name}`}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-lg-4">
                  <label className="form-label"> Order Tag </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Not Specified"
                    value={updateTag}
                    onChange={(e) => setUpdateTag(e.target.value)}
                  />
                </div>
                <div className="col-lg-4">
                  <label className="form-label">Product Type</label>
                  <select
                    name="productType"
                    className="form-select"
                    value={productType}
                    onChange={handleProductTypeChange}
                    disabled={productType === "Materials"}
                  >
                    {/* Show a placeholder option */}
                    <option value="">Select Product Type</option>

                    {/* Filter options: exclude "Materials" if the product is not "Materials" */}
                    {productTypes
                      .filter(
                        (option) =>
                          productType === "Materials" ||
                          option.name !== "Materials"
                      )
                      .map((option) => (
                        <option key={option._id} value={option.name}>
                          {formatLabel(option.name)}
                        </option>
                      ))}
                    {productType === "Materials" && (
                      <option value="Materials">Materials/Components</option>
                    )}
                  </select>
                </div>
                {productType !== "Materials" && (
                  <div className="col-lg-4">
                    <label className="form-label"> Headrail/Cassette</label>
                    <select
                      className="form-control"
                      value={headrail}
                      onChange={handleHeadrailChange}
                    >
                      {renderHeadrailOptions(productType)}
                    </select>
                  </div>
                )}
                {headrailConfig?.config?.headrail && (
                  <div className="col-lg-4">
                    <label className="form-label">Sleeve Option</label>
                    <select
                      className="form-select"
                      value={sleeveOption}
                      onChange={handleSleeveChange}
                    >
                      <option value="">Select sleeve</option>

                      {headrailConfig.config.headrail.sleeveOn?.isTrue ? (
                        <>
                          <option value="sleeveOn">Sleeve On</option>
                          <option value="sleeveOff">Sleeve Off</option>
                        </>
                      ) : (
                        <option value="sleeveOff">Sleeve Off</option>
                      )}
                    </select>
                  </div>
                )}
                {productType !== "Materials" && (
                  <>
                    <div className="col-lg-4">
                      <label className="form-label">Product Material</label>
                      <select
                        name="productMaterial"
                        className="form-control"
                        value={updateProMaterial}
                        onChange={handleChange}
                      >
                        <option value="">Select Fabric</option>
                        {Fabrics.map((fabric) => (
                          <option key={fabric.id} value={fabric.id}>
                            {fabric.name}
                          </option>
                        ))}
                        <option value="custom">Custom Fabric</option>
                      </select>
                    </div>
                    <AddCustomFabricModal
                      show={showCustomFabricModal}
                      handleClose={handleCustomFabricModalClose}
                    />
                  </>
                )}
              </div>
              <div className="col-lg-6 row g-3">
                <div className="col-12">
                  <label className="form-label">Order Remark</label>
                  <textarea
                    className="form-control"
                    placeholder=" Remarks, order tag..."
                    rows={5}
                    cols={4}
                    defaultValue={""}
                    value={updateRemark}
                    onChange={(e) => setUpdateRemark(e.target.value)}
                  />
                </div>
              </div>
              <h6 className="mt-4 mb-2 text-uppercase">Order Overview</h6>
              <OrderOverviewUI
                productType={productType}
                orderFactor={orderFactor}
              />
              <h6 className="mt-4 mb-2 text-uppercase">Order Data Table </h6>
              <hr />
              <hr />
              {productType === "Materials" && (
                <MaterialOrdersTable
                  materialOrders={materialOrders}
                  handleAddMaterialOrder={handleAddMaterialOrder}
                  handleRemoveMaterialOrder={handleRemoveMaterialOrder}
                  showAddMaterialModal={showAddMaterialModal}
                  setShowAddMaterialModal={setShowAddMaterialModal}
                  dealer={dealerInfo.dealerId}
                  setMaterialOrders={setMaterialOrders}
                />
              )}
              {productType !== "Materials" && (
                <>
                  {(showAdditionInput || showDeductionInput) && (
                    <div className="mt-3 pb-2">
                      <input
                        type="text"
                        value={
                          showDeductionInput ? deduction.val : addition.val
                        }
                        onChange={handleFractionChange}
                        placeholder="Enter fraction (e.g., 1/8)"
                        className="form-control"
                        style={{ width: "150px", display: "inline-block" }}
                      />
                      <button
                        className="btn btn-danger"
                        onClick={handleCancel}
                        style={{ marginLeft: "10px" }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                  <div className="container-fluid">
                    <div
                      className="table-responsive"
                      style={{ overflowX: "auto" }}
                      ref={tableContainerRef}
                    >
                      <table className="table table-bordered table-sm table-hover">
                        <InputTableHeader
                          settingsData={settingsData}
                          headrailConfig={calculations[0]}
                          order={orderArr}
                        />
                        <TableInputs
                          order={orderArr}
                          productType={productType}
                          handleInputChange={handleInputChange}
                          addRow={addRow}
                          deleteRow={deleteRow}
                          onFocus={handleFocus}
                          inputRefs={inputRefs}
                          isReadOnly={isReadOnly}
                          settingsData={settingsData}
                          headrailConfig={calculations[0]}
                          handleRecalculate={handleRecalculate}
                          LiftStyle={LiftStyle}
                          Motor={Motor}
                        />
                      </table>
                    </div>
                  </div>

                </>
              )}
              <SnackbarComponent />
              {isKeyboardVisible && (
                <div
                  style={{
                    width: "100%",
                    position: "fixed",
                    bottom: 0,
                    left: 0,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  <CustomKeyboard
                    onKeyPress={handleKeyPress}
                    onNext={handleNext}
                    onPrevious={handlePrevious}
                    onEnter={handleEnter}
                    onClear={handleClear}
                    onClose={handleCloseKeyboard}
                    handleRecalculate={handleRecalculate}
                    activeRowIndex={activeRowIndex}
                  />
                </div>
              )}

              <PricingModal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onNavigate={handleNavigateToPricing}
              />

              <hr />
            </div>
          </>
        </>
      )}
    </>
  );
};

export default OrderDetail;
