import React, { useState, useEffect } from "react";
import { CircularProgress, Typography } from "@mui/material";
import { AppVersion } from "../../constants/GlobalConstants";

export default function SplashScreen() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center transition-opacity ${
        loading ? "opacity-100" : "opacity-0"
      }`}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        transition: "opacity 1s ease-in-out",
        zIndex: 9999,
        background: "linear-gradient(135deg, #001f3d, #000a1a)",
        color: "#FFFFFF",
        textAlign: "center",
      }}
    >
      {/* Brand Logo */}
      <div
        className="mb-4"
        style={{
          animation: "fade-in-scale 1.5s ease-in-out",
        }}
      >
        <img
          src="/assets/images/brand-logo-2.png"
          alt="Brand Logo"
          style={{
            width: 250, 
            filter: "drop-shadow(0 4px 10px rgba(0, 0, 0, 0.1))",
          }}
        />
      </div>

      {/* App Name */}
      <Typography
        variant="h3"
        style={{
          fontWeight: "bold",
          backgroundClip: "text",
          color: "transparent",
          background: "linear-gradient(to right, #A78BFA, #7C3AED)",
          WebkitBackgroundClip: "text",
          animation: "slide-in 1.5s ease-out",
        }}
        className="mb-2"
      >
        ERP System
      </Typography>

      {/* App Version */}
      <Typography
        variant="subtitle1"
        style={{
          color: "#B0C4DE", 
          fontSize: "16px",
          letterSpacing: "0.1em",
        }}
        className="mb-4"
      >
        Version {AppVersion}
      </Typography>

      {/* Loading Spinner */}
      <div
        style={{
          animation: "fade-in-scale 1.5s ease-in-out",
        }}
      >
        <CircularProgress
          size={60}
          style={{
            color: "#A78BFA", 
            marginBottom: "20px",
          }}
        />
      </div>

     
      <Typography
        variant="body2"
        style={{
          fontSize: "14px",
          color: "#B0C4DE", 
        }}
      >
        Powering your business with innovation.
      </Typography>
    </div>
  );
}
