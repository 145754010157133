import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import axios from "axios";
import { API_URI } from "../../../constants/GlobalConstants";
import { useSelector } from "react-redux";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Button, Snackbar, Alert } from "@mui/material";
import MaterialPriceSkeletonLoader from "./MaterialPricingSkeleton";

const MaterialPricingComponent = () => {
  const { id } = useParams();
  const [pricingData, setPricingData] = useState({});
  const [dealerName, setDealerName] = useState('');
  const [loading, setLoading] = useState(true);
  const [updatedPrices, setUpdatedPrices] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success"); 
  
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get(
          `${API_URI}/api/dealer/dealers/${id}/material-pricing`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
          }
        );
        setPricingData(response.data.pricingData);
        setDealerName(response.data.dealerName);
      } catch (error) {
        console.error("Failed to load material pricing:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPricingData();
  }, [id, userInfo.token]);

  // Handle price change
  const handlePriceChange = (itemId, newPrice) => {
    setUpdatedPrices((prevPrices) => ({
      ...prevPrices,
      [itemId]: newPrice,
    }));
  };

  // Save updated prices
  const saveUpdatedPrices = async () => {
    try {
      const updateData = Object.keys(updatedPrices).map(itemId => ({
        itemId,
        price: updatedPrices[itemId],
      }));

      await axios.put(
        `${API_URI}/api/dealer/dealers/${id}/update-pricing`,
        { pricingUpdates: updateData },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      setSnackbarMessage("Pricing updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update pricing:", error);
      setSnackbarMessage("Failed to update pricing. Please try again.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true); 
    }
  };

  if (loading) return <MaterialPriceSkeletonLoader />;

  return (
    <div style={{ padding: '20px', maxWidth: '1200px', margin: 'auto' }}>
      <Typography variant="h4" gutterBottom align="center">
        Material Pricing for Dealer: {dealerName}
      </Typography>

      {Object.entries(pricingData).map(([category, items]) => (
        <div key={category} className="mb-4">
          <Typography variant="h5">{category}</Typography>

          <TableContainer component={Paper} sx={{ maxWidth: '100%', marginBottom: '20px' }}>
            <Table size="small" aria-label="material pricing table">
              <TableHead>
                <TableRow>
                  <TableCell>Material</TableCell>
                  <TableCell align="right">Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((item) => (
                  <TableRow key={item.inventoryItem}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell align="right">
                      <TextField
                        size="small"
                        variant="outlined"
                        value={updatedPrices[item.inventoryItem] || item.price || 0}
                        type="number"
                        inputProps={{
                          style: {
                            textAlign: "right",
                            padding: '6px 12px',
                          },
                        }}
                        sx={{
                          maxWidth: '120px',
                          width: '100%',
                        }}
                        onChange={(e) => handlePriceChange(item.inventoryItem, e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ))}

      <Button variant="contained" color="primary" onClick={saveUpdatedPrices}>
        Save Changes
      </Button>

      {/* Snackbar Notification */}
      <Snackbar 
        open={snackbarOpen} 
        autoHideDuration={6000} 
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert 
          onClose={() => setSnackbarOpen(false)} 
          severity={snackbarSeverity} 
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default MaterialPricingComponent;
