import React from "react";

const MaterialInvoiceEditTable = ({
  materialOrders = [],
  invParents = [],
  inventoryData = [],
  handleCategoryChange,
  handleMaterialChange,
  handleAddMaterial,
  handleRemoveMaterial,
  taxRates = [],
  handleMaterialTaxChange,
}) => {
    
  return (
    <div className="col-lg-12 mt-3">
      <h5>Material Orders</h5>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Category</th>
            <th>Material</th>
            <th>Tax Rates</th>
            <th>Quantity</th>
            <th>Price ($)</th>
            <th>Tax ($)</th>
            <th>Total ($)</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          {materialOrders?.map((material, materialIndex) => {
            // Calculate tax dynamically using rate or existing amount
            const tax = material.tax 
            ? material.tax 
            : (material.taxes || []).reduce((sum, tax) => {
                // Use existing amount if available, otherwise calculate from rate
                const taxAmount = tax.amount || (parseFloat(material.customCost || 0) * (tax.rate / 100));
                return sum + taxAmount;
              }, 0) || 0;

            // Calculate total as customCost + tax
            const total = parseFloat(material.customCost || 0) + tax;

            return (
              <tr key={materialIndex}>
                <td>
                  <select
                    className="form-select"
                    value={material.category || ""}
                    onChange={(e) =>
                      handleCategoryChange(materialIndex, e.target.value)
                    }
                  >
                    <option value="">Select Category</option>
                    {invParents.map((cat, i) => (
                      <option key={i} value={cat}>
                        {cat}
                      </option>
                    ))}
                  </select>
                </td>
                <td>
                  <select
                    className="form-select"
                    value={material.material || ""}
                    onChange={(e) =>
                      handleMaterialChange(
                        materialIndex,
                        "material",
                        e.target.value
                      )
                    }
                    disabled={!material.category}
                  >
                    <option value="">Select Material</option>
                    {material.category &&
                      inventoryData
                        .filter((item) => item.category === material.category)
                        .map((item) => (
                          <option key={item._id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                  </select>
                </td>
                <td>
                  {taxRates.map((taxRate) => (
                    <div
                      key={taxRate._id}
                      className="d-flex align-items-center gap-2"
                    >
                      <input
                        type="checkbox"
                        checked={material.taxes?.some(
                          (rate) => rate.type === taxRate.type
                        )}
                        onChange={(e) =>
                          handleMaterialTaxChange(
                            materialIndex,
                            taxRate,
                            e.target.checked
                          )
                        }
                      />
                      <label className="form-check-label mb-0">
                        {taxRate.type} ({taxRate.value}%)
                      </label>
                    </div>
                  ))}
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={material.quantity || ""}
                    onChange={(e) =>
                      handleMaterialChange(
                        materialIndex,
                        "quantity",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="number"
                    className="form-control"
                    value={material.customCost || ""}
                    onChange={(e) =>
                      handleMaterialChange(
                        materialIndex,
                        "customCost",
                        e.target.value
                      )
                    }
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={tax.toFixed(2)}
                    disabled
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={total.toFixed(2)}
                    disabled
                  />
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => handleRemoveMaterial(materialIndex)}
                  >
                    <i className="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-primary"
        onClick={handleAddMaterial}
      >
        Add Material
      </button>
    </div>
  );
};

export default MaterialInvoiceEditTable;
