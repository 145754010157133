import axios from "axios";
import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";

import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { CircularProgress, TextField } from "@mui/material";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { API_URI, invParents } from "../../constants/GlobalConstants";
import { useDispatch, useSelector } from "react-redux";
import { ListDealerAction } from "../../redux/actions/DealerAction";
import { getProductTypesAction } from "../../redux/actions/ProductTypeactions";
import { fetchAppSettings } from "../../redux/actions/UserSettingsActions";
import { getAllInventoryItems } from "../../redux/actions/InventoryAction";
import MaterialInvoiceTable from "./MaterialInvoiceTable";
import ProductSection from "./ProductSection";
import { useSnackbarNotification } from "../../components/order/reusables/UseSnackBarNotification";
import { useNavigate } from "react-router";
import { calculateTaxAndTotal } from "../../functions/invoicetaxcalc/taxcalculation";
import { provinces } from "../../constants/InvoiceConstants";

const InvoiceApp = ({ nav }) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const listDealer = useSelector((state) => state.listDealer);
  const { loading: loadingClient, dealer, success } = listDealer;
  const productTypesList = useSelector((state) => state.productTypes);
  const { productTypes } = productTypesList;
  const appSettingsState = useSelector((state) => state.appSettings);
  const { settings } = appSettingsState;
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const { data: inventoryData = [] } = inventoryItems;
  const [name, setName] = useState("");
  const [salesPerson, setSalesPerson] = useState("");
  const [date, setDate] = useState(dayjs());
  const [des, setDes] = useState("");
  const [action, setAction] = useState("");
  const [products, setProducts] = useState([
    {
      product: "",
      price: 0.0,
      tax: 0.0,
      selectedTaxRates: [],
      quantity: 1,
      materialOrders: [
        {
          category: "",
          material: "",
          customCost: "",
          quantity: 1,
          tax: 0,
          total: 0,
          selectedTaxRates: [],
        },
      ],
    },
  ]);
  const [loading, setLoading] = useState(false);

  const [clientDetails, setClientDetails] = useState({
    clientName: "",
    clientEmail: "",
    clientPhone: "",
    address: "",
    province: "",
    city: "",
    postalCode: "",
  });


  const [selectedTaxRates, setSelectedTaxRates] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);

  const [materialOrders, setMaterialOrders] = useState([
    { category: "", material: "", customCost: "", quantity: 1 },
  ]);
  const { showSnackbar, SnackbarComponent } = useSnackbarNotification();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(ListDealerAction(userInfo.token));
    dispatch(getProductTypesAction(userInfo.token));
    dispatch(fetchAppSettings());
    dispatch(getAllInventoryItems(userInfo.token));
  }, [dispatch]);

  // Handle adding a new product
  const handleAddProduct = () => {
    setProducts([
      ...products,
      {
        product: "",
        price: 0.0,
        tax: 0.0,
        selectedTaxRates: [],
        quantity: 1,
        materialOrders: [
          {
            category: "",
            material: "",
            customCost: "",
            quantity: 1,
            tax: 0,
            total: 0,
            selectedTaxRates: [],
          },
        ],
      },
    ]);
  };

  // Handle removing a product
  const handleRemoveProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  // Handle updating product or price values
  const handleProductChange = (index, field, value) => {
    const updatedProducts = products.map((item, i) => {
      if (i === index) {
        const updatedProduct = { ...item, [field]: value };

        // Recalculate subtotal
        const subtotal = updatedProduct.price;
        // Recalculate tax
        const taxAmount = updatedProduct.selectedTaxRates.reduce(
          (acc, taxRate) => acc + (subtotal * taxRate.value) / 100,
          0
        );

        // Recalculate total
        const total = Number(subtotal) + taxAmount;

        return { ...updatedProduct, subtotal, tax: taxAmount, total };
      }
      return item;
    });
    setProducts(updatedProducts);
  };

  const handleTaxRateChange = (productIndex, taxRate, isChecked) => {
    const updatedProducts = products.map((item, i) => {
      if (i === productIndex) {
        // Update selected tax rates
        const updatedTaxRates = isChecked
          ? [...item.selectedTaxRates, taxRate]
          : item.selectedTaxRates.filter((rate) => rate._id !== taxRate._id);

        // Recalculate subtotal
        const subtotal = item.price;

        // Recalculate tax
        const taxAmount = updatedTaxRates.reduce(
          (acc, rate) => acc + (subtotal * rate.value) / 100,
          0
        );

        // Recalculate total
        const total = Number(subtotal) + taxAmount;

        return {
          ...item,
          selectedTaxRates: updatedTaxRates,
          subtotal,
          tax: taxAmount,
          total,
        };
      }
      return item;
    });
    setProducts(updatedProducts);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const updatedProducts = products.map((product) => {
      const filteredMaterialOrders = product.materialOrders.filter(
        (material) =>
          material.category &&
          material.material &&
          material.customCost &&
          material.quantity > 0
      );

      return {
        ...product,
        materialOrders: filteredMaterialOrders,
      };

      // return product;
    });

    const payload = {
      name,
      salesPerson,
      action,
      date: date.$d.toString().slice(4, 15),
      products: updatedProducts,
      des,
      clientDetails,
      taxRates: selectedTaxRates,
    };

    try {
      // Make the API call to generate invoice
      const response = await axios.post(
        `${API_URI}/api/app/generate-custom-invoice`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      if (response.data.success) {
        // Trigger success snackbar
        showSnackbar(
          "Invoice generated and downloaded successfully!",
          "success"
        );

        // Handle file download (PDF)
        // const pdfData = response.data.pdf;
        // const pdfBlob = new Blob(
        //   [
        //     new Uint8Array(
        //       atob(pdfData)
        //         .split("")
        //         .map((c) => c.charCodeAt(0))
        //     ),
        //   ],
        //   { type: "application/pdf" }
        // );
        // await fileDownload(pdfBlob, `DSSBlinds-${action}-${name}.pdf`);
        navigate(`/invoice/${response.data.invoiceData._id}`);
      } else {
        showSnackbar("Failed to generate the invoice.", "error");
      }
    } catch (error) {
      // Handle error (backend failure or other issues)
      showSnackbar(
        `Error generating or downloading the invoice: ${error.message}`,
        "error"
      );
      console.error(
        "Error generating or downloading the invoice:",
        error.message
      );
    } finally {
      setLoading(false);
    }
  };

  const handleClientDetailsChange = (e) => {
    const { name, value } = e.target;
    setClientDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleMaterialChange = (productIndex, materialIndex, field, value) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          const updatedMaterialOrders = [...product.materialOrders];
          updatedMaterialOrders[materialIndex][field] = value;

          // Recalculate tax and total
          const { tax, total } = calculateTaxAndTotal(
            updatedMaterialOrders[materialIndex]
          );
          updatedMaterialOrders[materialIndex].tax = tax;
          updatedMaterialOrders[materialIndex].total = total;

          return { ...product, materialOrders: updatedMaterialOrders };
        }
        return product;
      })
    );
  };

  const handleAddMaterial = (productIndex) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          return {
            ...product,
            materialOrders: [
              ...product.materialOrders,
              {
                category: "",
                material: "",
                customCost: "",
                quantity: 1,
                tax: 0,
                total: 0,
              },
            ],
          };
        }
        return product;
      })
    );
  };
  const handleRemoveMaterial = (productIndex, materialIndex) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          const updatedMaterialOrders = product.materialOrders.filter(
            (_, i) => i !== materialIndex
          );
          return { ...product, materialOrders: updatedMaterialOrders };
        }
        return product;
      })
    );
  };

  const handleCategoryChange = (productIndex, materialIndex, category) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) => {
        if (index === productIndex) {
          const updatedMaterialOrders = [...product.materialOrders];
          updatedMaterialOrders[materialIndex].category = category;

          // Clear material selection if the category changes
          updatedMaterialOrders[materialIndex].material = "";
          updatedMaterialOrders[materialIndex].tax = 0;
          updatedMaterialOrders[materialIndex].total = 0;

          return { ...product, materialOrders: updatedMaterialOrders };
        }
        return product;
      });
      console.log("Updated Products:", updatedProducts);
      return updatedProducts;
    });
  };

  const handleMaterialTaxChange = (
    productIndex,
    materialIndex,
    taxRate,
    isChecked
  ) => {
    // Update the material orders for the specific product
    const updatedProducts = [...products]; // Copy the current state of products

    const updatedMaterialOrders = updatedProducts[
      productIndex
    ].materialOrders.map((material, index) => {
      if (index === materialIndex) {
        // Update the selected tax rates for the material
        const updatedTaxRates = isChecked
          ? [...(material.selectedTaxRates || []), taxRate]
          : material.selectedTaxRates.filter(
              (rate) => rate._id !== taxRate._id
            );

        const updatedMaterial = {
          ...material,
          selectedTaxRates: updatedTaxRates,
        };

        // Recalculate tax and total for the updated material
        const { tax, total } = calculateTaxAndTotal(updatedMaterial);
        return { ...updatedMaterial, tax, total };
      }
      return material;
    });

    // Update the material orders in the product
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      materialOrders: updatedMaterialOrders,
    };

    // Finally, update the products state with the new material orders
    setProducts(updatedProducts);
  };
  return (
    <>
      <div className={nav.isSidebar}>
        <Header />
        <Sidebar nav={nav} />
        {/*start content*/}
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Dashboard</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <a href="/">
                      <i className="bx bx-home-alt" />
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Create Invoice
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="row">
              <form onSubmit={handleSubmit}>
                <div className="col-lg-12 mx-auto">
                  <div className="card">
                    <div className="card-body">
                      <div className="row g-3">
                        <h4>Invoice Details</h4>
                        <div className="col-lg-4 col-md-4">
                          <label className="form-label">Company Name</label>
                          <div className="dropdown">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="To"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                                const selectedDealer = dealer.find(
                                  (item) => item.companyName === e.target.value
                                );
                                if (selectedDealer) {
                                  setClientDetails((prev) => ({
                                    clientEmail: selectedDealer.email,
                                    clientName: selectedDealer.name,
                                    clientPhone: selectedDealer.phone,
                                  }));
                                }
                              }}
                              list="dealer-list"
                            />
                            <datalist id="dealer-list">
                              {dealer &&
                                dealer.map((item) => (
                                  <option
                                    key={item._id}
                                    value={item.companyName}
                                  >
                                    {item.email}
                                  </option>
                                ))}
                            </datalist>
                          </div>
                        </div>

                        <div className="col-lg-2 col-md-2">
                          <label className="form-label">Salesperson</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Salesperson"
                            value={salesPerson}
                            onChange={(e) => setSalesPerson(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-2 col-md-2">
                          <label className="form-label">Action</label>
                          <select
                            className="form-select"
                            value={action}
                            onChange={(e) => setAction(e.target.value)}
                          >
                            <option value=""></option>
                            <option value="Invoice">Invoice</option>
                            <option value="estimate">Estimate</option>
                          </select>
                        </div>
                        <div className="col-lg-4 col-md-4">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                              label="Date"
                              inputFormat="MM/DD/YYYY"
                              value={date}
                              onChange={(newValue) => setDate(newValue)}
                              renderInput={(params) => (
                                <TextField {...params} />
                              )}
                            />
                          </LocalizationProvider>
                        </div>
                        <hr />
                        <h5>Client Details</h5>
                        <div className="row g-3">
                          <div className="col-lg-4">
                            <label className="form-label">Client Name</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Client Name"
                              name="clientName"
                              value={clientDetails.clientName}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                          <div className="col-lg-4">
                            <label className="form-label">Client Email</label>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Client Email"
                              name="clientEmail"
                              value={clientDetails.clientEmail}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                          <div className="col-lg-4">
                            <label className="form-label">Client Phone</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Client Phone"
                              name="clientPhone"
                              value={clientDetails.clientPhone}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Address</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Address"
                              name="address"
                              value={clientDetails.address}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label">Province</label>
                            <select
                              className="form-select"
                              name="province"
                              value={clientDetails.province}
                              onChange={handleClientDetailsChange}
                            >
                              <option value="">Select Province</option>
                              {provinces.map((province) => (
                                <option key={province} value={province}>
                                  {province}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label">City</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="City"
                              name="city"
                              value={clientDetails.city}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                          <div className="col-lg-3">
                            <label className="form-label">Postal Code</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Postal Code"
                              name="postalCode"
                              value={clientDetails.postalCode}
                              onChange={handleClientDetailsChange}
                            />
                          </div>
                        </div>
                        <hr />
                        <h5>Product Pricing</h5>
                        <ProductSection
                          products={products}
                          productTypes={productTypes}
                          settings={settings}
                          materialOrders={materialOrders}
                          invParents={invParents}
                          inventoryData={inventoryData}
                          handleCategoryChange={handleCategoryChange}
                          handleMaterialChange={handleMaterialChange}
                          handleAddMaterial={handleAddMaterial}
                          handleRemoveMaterial={handleRemoveMaterial}
                          handleProductChange={handleProductChange}
                          handleTaxRateChange={handleTaxRateChange}
                          handleAddProduct={handleAddProduct}
                          handleRemoveProduct={handleRemoveProduct}
                          handleMaterialTaxChange={handleMaterialTaxChange}
                        />

                        <hr />
                        <div className="col-12">
                          <label className="form-label">
                            Description / Note
                          </label>
                          <textarea
                            className="form-control"
                            placeholder="Full description"
                            rows={2}
                            value={des}
                            onChange={(e) => setDes(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-header py-3 bg-transparent">
                      <div className="d-sm-flex align-items-center">
                        <h5 className="mb-2 mb-sm-0">Invoice generator</h5>
                        <div className="ms-auto">
                          <button type="submit" className="btn btn-primary">
                            Create Invoice
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </main>
        <SnackbarComponent />
        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
};

export default InvoiceApp;
