import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
} from "@mui/material";
import { fetchEstimates } from "../../redux/actions/InvoiceActions";
import { Link } from "react-router-dom";

const EstimatedListing = () => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { userInfo } = useSelector((state) => state.userSignIn);

  const { loading, estimates, error, totalEstimates } = useSelector(
    (state) => state.estimates
  );

  useEffect(() => {
    if (userInfo) {
      dispatch(
        fetchEstimates({
          dealerId: null,
          page: page + 1,
          limit: rowsPerPage,
        })
      );
    }
  }, [dispatch, userInfo, page, rowsPerPage]);

  const handlePageChange = (_, newPage) => setPage(newPage);
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (!userInfo) return <p>Please log in to view estimates.</p>;

  return (
    <div className="card mt-4">
      <div
        className="card-header bg-primary text-white d-flex justify-content-between align-items-center"
        style={{ height: "56px" }}
      >
        <h4 className="mb-0">Estimated List</h4>
      </div>
      {loading ? (
        <div style={{ textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Estimate Number</TableCell>
                  <TableCell>Estimate Date</TableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Dealer</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {estimates.map((estimate) => (
                  <TableRow key={estimate._id} hover>
                    <TableCell>{estimate.estimateNumber}</TableCell>
                    <TableCell>
                      {new Date(estimate.estimateDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{estimate.total}</TableCell>
                    <TableCell>{estimate.buyer.companyName}</TableCell>
                    <TableCell
                      style={{
                        color:
                          estimate.status === "Issued" ? "orange" : "green",
                      }}
                    >
                      {estimate.status}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={`/invoice/${estimate._id}`}
                        style={{ textDecoration: "none", color: "blue" }}
                      >
                        View Estimate
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalEstimates}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </Paper>
      )}
    </div>
  );
};

export default EstimatedListing;
