import { nanoid } from "nanoid";
import { fauxwoodProduction } from "../../../functions/productionSheet/fauxwood/FauxwoodProduction";
import {
  cvrtDeci2Frac,
  getDecimal,
  getFinalVal,
  totalDecimal,
} from "../../../functions/productionSheet/GolbalFunctions";
import { rollerEquation } from "../../../functions/productionSheet/rollerShades/RollerProduction";
import { verticalProduction } from "../../../functions/productionSheet/vertical/verticalProduction";
import { calculateProduction } from "./CalculateProduction";

//| ---------------------UPDATE------------------------

// This update single order inside Arr by finding the index
export const updateOrderArr = ({
  OrderArr,
  editProductId,
  productType,
  widthEdit,
  heightEdit,
  remarkEdit,
  editLocation,
  headrail,
  tile,
}) => {
  const newOrder = [...OrderArr];
  const index = OrderArr.findIndex((o) => o.id === editProductId);
  switch (productType) {
    case "Zebra_blinds":
    case "Roller_shades": {
      const { sqft, ...calculatedValues } = rollerEquation(
        widthEdit,
        heightEdit,
        headrail
      );
      newOrder[index] = {
        id: editProductId,
        width: widthEdit,
        height: heightEdit,
        remark: remarkEdit,
        location: editLocation,
        tile,
        ...calculatedValues,
        factor: sqft,
      };
      return newOrder;
    }

    case "Fauxwood": {
      const { fullPrice, ...fauxwoodValues } = fauxwoodProduction(
        widthEdit,
        heightEdit,
        headrail
      );
      newOrder[index] = {
        id: editProductId,
        width: widthEdit,
        height: heightEdit,
        remark: remarkEdit,
        tile,
        location: editLocation,
        ...fauxwoodValues,
        factor: fullPrice,
      };
      return newOrder;
    }

    case "Vertical_blinds": {
      const { vfullPrice, ...verticalValues } = verticalProduction(
        widthEdit,
        heightEdit,
        headrail
      );
      newOrder[index] = {
        id: editProductId,
        width: widthEdit,
        height: heightEdit,
        remark: remarkEdit,
        location: editLocation,
        tile,
        ...verticalValues,
        factor: vfullPrice,
      };
      return newOrder;
    }

    default:
      return OrderArr;
  }
};

// Update the overall order factor
export const updateOrderFactor = (orderArr) => {
  return orderArr
    .filter(({ factor }, index) => {
      // Filter rows that have a valid factor and an index not equal to -1
      return factor !== undefined && index !== -1;
    })
    .reduce((sum, { factor }) => sum + factor, 0);
};
// Delete the order by id from order Arr
export const deleteOrderbyId = (orderId, orderArr) => {
  const newOrder = [...orderArr];
  const index = orderArr.findIndex((o) => o.id === orderId);
  newOrder.splice(index, 1);
  return newOrder;
};

//* -------------------Order Calculation 🧮-------------------
export function getProductionData(
  productType,
  width,
  height,
  control,
  lift,
  mount,
  remark,
  location,
  headrail,
  deduction,
  addition,
  tile,
  matrix,
  liftStyle
) {
  
  const productionData = calculateProduction(
    width,
    height,
    headrail,
    matrix,
    deduction,
    addition
  );

  return {
    ...productionData,
    control,
    lift,
    remark,
    location,
    tile,
    liftStyle,
  };
}


//+ -----------------Attributes:Order Attributes Functions---------------
//This function will split the left,right and middle splits and returns the l,r,m values
export function splitValidation(width, name, value, setSplitWidth, splitWidth) {
  var decWidth = totalDecimal(width);
  if (name === "l") {
    setSplitWidth({
      ...splitWidth,
      l: value,
      r: cvrtDeci2Frac(decWidth - totalDecimal(value)),
      m: 0,
    });
  } else if (name === "r") {
    var right = totalDecimal(value);
    setSplitWidth({
      ...splitWidth,
      r: value,
      m: cvrtDeci2Frac(decWidth - (right + totalDecimal(splitWidth.l))),
    });
  }
}
