import { USER_LOGIN_FAIL, USER_LOGIN_REQUEST, USER_LOGIN_SUCCESS } from "../../constants/userConstants"
import { API_URI } from "../../constants/GlobalConstants"
import axios from "axios"
import { jwtDecode } from "jwt-decode";

export const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;  
    
      if (!decodedToken.exp) {
        return true; 
      }
  
      return decodedToken.exp < currentTime; 
    } catch (error) {
      console.error("Token decoding error:", error);
      return true;  
    }
  };
  
export const SignImAction = (email, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        const { data } = await axios.post(
            `${API_URI}/api/auth/sign-in`,
            { email, password },
            config
        )
        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })
        localStorage.setItem('userInfo', JSON.stringify(data))
    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.message ?
                error.response.data.message : error.message
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({ type: 'USER_LOGOUT' })
    document.location.href = '/sign-in'
}