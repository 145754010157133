import React, { useEffect } from "react";
import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchAppSettings } from "../../../redux/actions/UserSettingsActions";

const TaxSelectionForm = ({
  selectedTaxes,
  setSelectedTaxes,
  invoiceData,
  pdfLoading,
}) => {
  const appSettingsState = useSelector((state) => state.appSettings);
  const { loading, settings, error } = appSettingsState;

  const dispatch = useDispatch();

  const handleTaxChange = (event) => {
    const { name, checked } = event.target;
    setSelectedTaxes((prev) =>
      checked ? [...prev, name] : prev.filter((tax) => tax !== name)
    );
  };

  useEffect(() => {
    dispatch(fetchAppSettings());
  }, [dispatch]);

  

  return (
    <Box
    sx={{
      bgcolor: "white",
      borderRadius: 2,
      p: 4,
      width: "100%",
      maxWidth: 400,
      mx: "auto",
      border: "1px solid #e0e0e0",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    }}
    >
      <Typography
        variant="h6"
        component="h2"
        sx={{ mb: 2, color: "text.primary" }}
      >
        Select Applicable Taxes
      </Typography>
      <FormGroup>
        {settings.taxRates &&
          settings.taxRates.map((tax) => (
            <FormControlLabel
              key={tax._id}
              control={
                <Checkbox
                  checked={selectedTaxes.includes(tax.type)}
                  onChange={handleTaxChange}
                  name={tax.type}
                  sx={{
                    color: "primary.main",
                    "&.Mui-checked": {
                      color: "primary.dark",
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: "text.secondary", fontWeight: 500 }}>
                  {tax.type} - {tax.value}%
                </Typography>
              }
            />
          ))}
      </FormGroup>

      {/* Divider for separation */}
      <Divider sx={{ my: 3 }} />

      {/* Total Amount and Tax Section with border */}
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          borderRadius: 2,
          p: 2,
          mt: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ color: "text.primary", mb: 1 }}>
          Subtotal:
        </Typography>

        {pdfLoading ? (
          <CircularProgress size={50} sx={{ color: "primary.main" }} />
        ) : (
          <Typography
            variant="h6"
            sx={{ color: "primary.main", fontWeight: 600, mb: 2 }}
          >
            ${invoiceData.subTotal}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ color: "text.primary", mb: 1 }}>
          Tax:
        </Typography>

        {pdfLoading ? (
          <CircularProgress size={50} sx={{ color: "primary.main" }} />
        ) : (
          <Typography
            variant="h6"
            sx={{ color: "error.main", fontWeight: 600, mb: 2 }}
          >
            ${invoiceData.totalTax}
          </Typography>
        )}

        <Typography variant="subtitle1" sx={{ color: "text.primary", mb: 1 }}>
          Estimated Total:
        </Typography>

        {pdfLoading ? (
          <CircularProgress size={50} sx={{ color: "primary.main" }} />
        ) : (
          <Typography
            variant="h6"
            sx={{ color: "success.main", fontWeight: 600 }}
          >
            ${invoiceData.totalAmount}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default TaxSelectionForm;
