import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

const PricingModal = ({ open, onClose, onNavigate }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pricing Not Found</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          Pricing is missing for the selected product type and headrail. Please
          update the pricing to proceed.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="secondary">
          Close
        </Button>
        <Button onClick={onNavigate} variant="contained" color="primary">
          Go to Pricing Page
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default PricingModal;
