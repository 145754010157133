import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/UserActions";
import axios from "axios";
import { API_URI, AppVersion } from "../../constants/GlobalConstants";

const Header = ({ nav }) => {
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userSignIn);
  const { error, userInfo } = userLogin;
  const token = userInfo.token;
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const [notificationsCount, setNotificationsCount] = useState(0);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${API_URI}/api/notifications`,
          config
        );
        setNotifications(response.data);
        setNotificationsCount(
          response.data.filter((notification) => !notification.read).length
        );
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [token]);

  const toggleHandle = () => {
    nav.isSidebar === "wrapper"
      ? nav.setIsSidebar("wrapper toggled")
      : nav.setIsSidebar("wrapper");
  };

  const logoutHandler = () => {
    dispatch(logout());
  };

  const markAsRead = async (id, dealerId) => {
    try {
      // Navigate to the page when notification is clicked
      navigate(`/dealer/account-view/${dealerId}`);

      await axios.patch(
        `${API_URI}/api/notifications/${id}/read`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNotifications(
        notifications.map((notification) =>
          notification._id === id
            ? { ...notification, read: true }
            : notification
        )
      );
      setNotificationsCount(notificationsCount - 1);
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };
  const rolesString = userInfo.roles.join(", ");

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      timeZone: "America/Toronto",
    };

    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-CA", options).format(date);
  };

  const hasNotificationAccess =
    userInfo?.isAdmin === true ||
    userInfo?.roles.some((role) =>
      ["admin", "production_manager"].includes(role)
    );
  return (
    <header className="top-header">
      <nav className="navbar navbar-expand">
        <button onClick={toggleHandle} type="button" className="btn btn-dark">
          <div className="mobile-toggle-icon d-xl-none">
            <i className="bi bi-list" />
          </div>
        </button>
        <div className="top-navbar d-none d-xl-block">
          <ul className="navbar-nav align-items-center">
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Dashboard
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/action/add-action" className="nav-link">
                Add Action
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/add-appointment" className="nav-link">
                Add Appointment
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/order/add-order" className="nav-link">
                Add Orders
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/order/add-order" className="nav-link">
                Dev6
              </Link>
            </li>
          </ul>
        </div>
        <div className="search-toggle-icon d-xl-none ms-auto">
          <i className="bi bi-search" />
        </div>
        <form className="searchbar d-none d-xl-flex ms-auto">
          <div className="position-absolute top-50 translate-middle-y search-icon ms-3">
            <i className="bi bi-search" />
          </div>
          <input
            className="form-control"
            type="text"
            placeholder="Type here to search"
          />
          <div className="position-absolute top-50 translate-middle-y d-block d-xl-none search-close-icon">
            <i className="bi bi-x-lg" />
          </div>
        </form>
        <div className="top-navbar-right ms-3 d-flex align-items-center">
          {/* Notification Dropdown */}
          {hasNotificationAccess && (
            <div className="notification-dropdown me-3">
              <button
                className="btn btn-light position-relative"
                data-bs-toggle="dropdown"
              >
                <div className="position-relative">
                  <i className="bi bi-bell" style={{ fontSize: "1rem" }} />
                  {notificationsCount > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {notificationsCount}
                    </span>
                  )}
                </div>
              </button>
              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <li>
                  <h6 className="dropdown-header">Notifications</h6>
                </li>
                {notifications.length > 0 ? (
                  notifications.map((notification) => (
                    <li
                      key={notification._id}
                      className="dropdown-item d-flex align-items-start"
                      onClick={() =>
                        markAsRead(notification._id, notification.dealerId)
                      }
                    >
                      <div className="notification-icon me-2">
                        <i className="bi bi-info-circle" />
                      </div>
                      <div className="notification-content">
                        <h6
                          className="mb-0"
                          style={{
                            maxWidth: "250px",
                            whiteSpace: "normal",
                            overflowWrap: "break-word",
                          }}
                        >
                          {notification.message}
                        </h6>
                        <small className="text-muted">
                          {formatDate(notification.timestamp)}
                        </small>
                        {!notification.read && (
                          <button
                            className="btn btn-link"
                            onClick={(e) => {
                              e.stopPropagation();
                              markAsRead(
                                notification._id,
                                notification.dealerId
                              );
                            }}
                          >
                            Mark as Read
                          </button>
                        )}
                      </div>
                    </li>
                  ))
                ) : (
                  <li>
                    <a className="dropdown-item" href="#">
                      <div className="d-flex align-items-center">
                        <div className="notification-icon me-2">
                          <i className="bi bi-info-circle" />
                        </div>
                        <div className="ms-3">
                          <h6 className="mb-0">No notifications</h6>
                        </div>
                      </div>
                    </a>
                  </li>
                )}
              </ul>
            </div>
          )}

          {/* User Dropdown */}
          <div className="dropdown">
            <a
              className="nav-link dropdown-toggle dropdown-toggle-nocaret"
              href="#"
              data-bs-toggle="dropdown"
            >
              <div className="user-setting d-flex align-items-center gap-1">
                <img
                  src="/assets/images/Logo-O.png"
                  className="user-img"
                  alt=""
                />
                <div
                  className="user-name"
                  style={{
                    textAlign: "center",
                    maxWidth: "150px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {process.env.REACT_APP_NAME}

                  <div
                    className="tooltip-item"
                    style={{
                      backgroundColor: "#7C3AED",
                      color: "#FFFFFF",
                      fontSize: "0.5rem",
                      padding: "3px 6px",
                      borderRadius: "15px",
                      boxShadow: "0 3px 4px rgba(0, 0, 0, 0.1)",
                      fontWeight: "600",
                      display: "inline-block",
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title={`App Version: ${AppVersion}`}
                  >
                    Version {AppVersion}
                  </div>
                </div>
              </div>
            </a>
            <ul className="dropdown-menu dropdown-menu-end">
              <li>
                <a className="dropdown-item" href="#">
                  <div className="d-flex align-items-center">
                    <img
                      src="/assets/images/Logo-O.png"
                      alt=""
                      className="rounded-circle"
                      style={{ objectFit: "contain" }}
                      width={120}
                      height={60}
                    />
                    <div className="ms-3">
                      <h6 className="mb-0 dropdown-user-name">
                        {process.env.REACT_APP_NAME}
                      </h6>
                      <small className="mb-0 dropdown-user-designation text-secondary">
                        {userInfo.username}
                        <br />
                        {rolesString}
                      </small>
                    </div>
                  </div>
                </a>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link to="/" className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="setting-icon">
                      <i className="bi bi-speedometer" />
                    </div>
                    <div className="setting-text ms-3">
                      <span>Dashboard</span>
                    </div>
                  </div>
                </Link>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link onClick={logoutHandler} className="dropdown-item">
                  <div className="d-flex align-items-center">
                    <div className="setting-icon">
                      <i className="bi bi-lock-fill" />
                    </div>
                    <div className="setting-text ms-3">
                      <span>Logout</span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
