import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { useSnackbarNotification } from "../../components/order/reusables/UseSnackBarNotification";
import { useEffect, useState } from "react";
import { API_URI, invParents } from "../../constants/GlobalConstants";
import Loader from "../../components/layout/Loader";
import { Alert } from "@mui/material";
import { Link } from "react-router-dom";

import {
  calculateProductTotalTaxAndPrice,
  calculateTaxAndTotal,
} from "../../functions/invoicetaxcalc/taxcalculation";
import MaterialInvoiceEditTable from "./MaterialInvoiceEditTable";
import axios from "axios";
import { fetchInvoiceData } from "../../redux/actions/InvoiceActions";

const InvoiceEditComp = () => {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.userSignIn);
  const { documentType, invoice, pdf, loading, error } = useSelector(
    (state) => state.customInvoiceById
  );
  const productTypesList = useSelector((state) => state.productTypes);
  const { productTypes } = productTypesList;
  const appSettingsState = useSelector((state) => state.appSettings);
  const { settings } = appSettingsState;
  const inventoryItems = useSelector((state) => state.getInvItemsByCat);
  const { data: inventoryData = [] } = inventoryItems;
  const { showSnackbar, SnackbarComponent } = useSnackbarNotification();
  const [editableInvoice, setEditableInvoice] = useState(null);
  const [products, setProducts] = useState([
    {
      type: "",
      price: 0.0,
      tax: 0.0,
      taxes: [],
      quantity: 1,
      materials: [
        {
          category: "",
          material: "",
          customCost: "",
          quantity: 1,
          tax: 0,
          total: 0,
          taxes: [],
        },
      ],
    },
  ]);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  console.log(invoice);

  useEffect(() => {
    if (invoice) {
      setEditableInvoice(invoice);
      setProducts(invoice.items);
    }
  }, [invoice]);

  const calculateProductTotal = (price, taxes) => {
    const subtotal = parseFloat(price || 0);

    // Adjust to handle taxes with "rate" instead of "value"
    const taxAmount = taxes.reduce((acc, taxRate) => {
      const taxValue = parseFloat(taxRate.rate || 0);
      return acc + (subtotal * taxValue) / 100;
    }, 0);

    const total = subtotal + taxAmount;
    console.log(total, subtotal);

    return { subtotal, taxAmount, total };
  };

  const handleInputChange = (field, value) => {
    setEditableInvoice((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleProductChange = (index, field, value) => {
    const updatedProducts = products.map((item, i) => {
      if (i === index) {
        const updatedProduct = { ...item, [field]: value };

        // Recalculate taxes based on the new price
        const updatedTaxes = updatedProduct.taxes.map((tax) => ({
          ...tax,
          amount: (updatedProduct.price * tax.rate) / 100,
        }));

        // Recalculate subtotal, tax amount, and total
        const { subtotal, taxAmount, total } = calculateProductTotal(
          updatedProduct.price,
          updatedTaxes
        );

        return {
          ...updatedProduct,
          taxes: updatedTaxes,
          subtotal,
          tax: taxAmount,
          total,
        };
      }
      return item;
    });
    setProducts(updatedProducts);
  };

  const handleTaxRateChange = (productIndex, taxRate, isChecked) => {
    const updatedProducts = products.map((item, i) => {
      if (i === productIndex) {
        // Calculate the new tax object to be added or removed
        const newTax = {
          type: taxRate.type,
          rate: taxRate.value,
          amount: (item.price * taxRate.value) / 100,
        };

        // Update taxes array: Add or remove the tax rate
        const updatedTaxes = isChecked
          ? [...item.taxes, newTax] // Add the new tax if checked
          : item.taxes.filter((rate) => rate.type !== taxRate.type); // Remove tax if unchecked
        // Recalculate subtotal, tax amount, and total
        const { subtotal, taxAmount, total } = calculateProductTotal(
          item.price,
          updatedTaxes
        );

        return {
          ...item,
          taxes: updatedTaxes, // Updated taxes array
          subtotal, // Recalculated subtotal (should be the price itself)
          tax: taxAmount, // Recalculated tax amount
          total, // Recalculated total (price + tax)
        };
      }
      return item;
    });

    setProducts(updatedProducts);
  };

  const handleRemoveProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const handleAddProduct = () => {
    setProducts((prevProducts) => [
      ...prevProducts,
      {
        type: "",
        price: 0.0,
        tax: 0.0,
        taxes: [],
        quantity: 1,
        materials: [
          {
            category: "",
            material: "",
            customCost: "",
            quantity: 1,
            tax: 0,
            total: 0,
            taxes: [],
          },
        ],
      },
    ]);
  };
  console.log(products);

  const handleMaterialChange = (productIndex, materialIndex, field, value) => {
    setProducts((prevProducts) => {
      // Step 1: Create a copy of the products to avoid direct mutation
      const updatedProducts = [...prevProducts];

      // Step 2: Update the specific material field
      const updatedMaterialOrders = [
        ...updatedProducts[productIndex].materials,
      ];
      updatedMaterialOrders[materialIndex][field] = value;

      // Step 3: Recalculate taxes for the specific material after the field change
      const updatedTaxes = updatedMaterialOrders[materialIndex].taxes.map(
        (tax) => ({
          ...tax,
          amount:
            parseFloat(updatedMaterialOrders[materialIndex].customCost || 0) *
            (tax.rate / 100), // Recalculate the tax amount for the material
        })
      );

      // Step 4: Recalculate the total tax and total for the updated material
      const { tax, total } = calculateTaxAndTotal(
        updatedMaterialOrders[materialIndex]
      );

      // Step 5: Update the material with the new tax and total cost
      updatedMaterialOrders[materialIndex] = {
        ...updatedMaterialOrders[materialIndex],
        taxes: updatedTaxes, // Updated tax array with recalculated amounts
        tax, // Updated total tax for the material
        totalCost: total, // Updated total cost for the material
      };

      // Step 6: Recalculate overall tax and price for the product
      const updatedProduct = {
        ...updatedProducts[productIndex],
        materials: updatedMaterialOrders, // Ensure materials are updated with new taxes and totals
      };

      // Pass the updated product to the calculation function
      const { materialTaxes, totalPrice } =
        calculateProductTotalTaxAndPrice(updatedProduct);

      console.log(
        "Updated material taxes and total price:",
        materialTaxes,
        totalPrice
      );

      // Step 7: Update the product with recalculated values
      updatedProducts[productIndex] = {
        ...updatedProducts[productIndex],
        materials: updatedMaterialOrders, // Ensure materials are updated
        materialTaxes, // Updated overall tax for the materials in the product
        price: totalPrice, // Updated subtotal of the product
        total: totalPrice + materialTaxes,
        outstanding: totalPrice + materialTaxes,
      };

      return updatedProducts;
    });
  };

  const handleAddMaterial = (productIndex) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          return {
            ...product,
            materials: [
              ...product.materials,
              {
                category: "",
                material: "",
                customCost: "",
                quantity: 1,
                tax: 0,
                total: 0,
                taxes: [],
              },
            ],
          };
        }
        return product;
      })
    );
  };
  const handleRemoveMaterial = (productIndex, materialIndex) => {
    setProducts((prevProducts) =>
      prevProducts.map((product, index) => {
        if (index === productIndex) {
          const updatedMaterialOrders = product.materials.filter(
            (_, i) => i !== materialIndex
          );
          return { ...product, materials: updatedMaterialOrders };
        }
        return product;
      })
    );
  };

  const handleCategoryChange = (productIndex, materialIndex, category) => {
    setProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) => {
        if (index === productIndex) {
          const updatedMaterialOrders = [...product.materials];
          updatedMaterialOrders[materialIndex].category = category;

          // Clear material selection if the category changes
          updatedMaterialOrders[materialIndex].material = "";
          updatedMaterialOrders[materialIndex].tax = 0;
          updatedMaterialOrders[materialIndex].total = 0;

          return { ...product, materials: updatedMaterialOrders };
        }
        return product;
      });
      // console.log("Updated Products:", updatedProducts);
      return updatedProducts;
    });
  };

  const handleMaterialTaxChange = (
    productIndex,
    materialIndex,
    taxRate,
    isChecked
  ) => {
    // Step 1: Create a copy of the products to avoid direct mutation
    const updatedProducts = [...products];

    // Step 2: Update the material's taxes
    const updatedMaterialOrders = updatedProducts[productIndex].materials.map(
      (material, index) => {
        if (index === materialIndex) {
          // Update the selected tax rates for the material
          const updatedTaxRates = isChecked
            ? [
                ...(material.taxes || []),
                {
                  type: taxRate.type,
                  rate: taxRate.value,
                  amount:
                    parseFloat(material.customCost || 0) *
                    (taxRate.value / 100),
                },
              ]
            : material.taxes.filter((rate) => rate.type !== taxRate.type);

          // Recalculate tax and total for the updated material
          const updatedMaterial = {
            ...material,
            taxes: updatedTaxRates,
          };

          // Recalculate total tax and total cost for the material
          const { tax, total } = calculateTaxAndTotal(updatedMaterial);

          return { ...updatedMaterial, tax, total };
        }
        return material;
      }
    );

    // Step 3: Recalculate the overall product's total tax (materialTaxes) and subtotal (price and total)
    const updatedProduct = {
      ...updatedProducts[productIndex],
      materials: updatedMaterialOrders,
    };

    // Pass the updated product to recalculate total taxes and price
    const { materialTaxes, totalPrice } =
      calculateProductTotalTaxAndPrice(updatedProduct);

    console.log(
      "Updated material taxes and total price:",
      materialTaxes,
      totalPrice
    );

    // Step 4: Update the product with the recalculated overall tax and total
    updatedProducts[productIndex] = {
      ...updatedProducts[productIndex],
      materials: updatedMaterialOrders,
      materialTaxes,
      price: totalPrice,
      total: totalPrice + materialTaxes,
    };

    // Step 5: Update the products state with the new values
    setProducts(updatedProducts);
  };

  const handleSaveInvoice = async () => {
    try {
      // Step 1: Clean up the products by removing incomplete materials
      const cleanedProducts = products.map((product) => ({
        ...product,
        materials: product.materials.filter(
          (material) => material.category && material.material
        ), // Filter out materials with empty 'category' or 'material'
      }));

      // Step 2: Prepare the updated invoice data
      const updateData = {
        ...editableInvoice, // Spread the existing invoice fields
        items: cleanedProducts, // Replace items with the cleaned products
      };

      // Step 3: Send the updated invoice data to the server
      const response = await axios.put(
        `${API_URI}/api/app/invoice-update/${id}`,
        updateData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      // Step 4: Handle the response
      if (response.data.success) {
        showSnackbar("Invoice saved successfully!", "success");
        navigate(`/invoice/${id}`);
        // Update the editable invoice and products
        setEditableInvoice(response.data.updatedInvoice);
        setProducts(response.data.updatedInvoice.items);
      } else {
        showSnackbar("Failed to save the invoice.", "error");
      }
    } catch (error) {
      // Step 5: Handle errors
      showSnackbar("Error saving invoice: " + error.message, "error");
    }
  };

  const renderMaterialSection = (index) => (
    <MaterialInvoiceEditTable
      materialOrders={products[index].materials || []}
      invParents={invParents}
      inventoryData={inventoryData}
      handleCategoryChange={(materialindex, category) =>
        handleCategoryChange(index, materialindex, category)
      }
      handleMaterialChange={(materialindex, field, material) =>
        handleMaterialChange(index, materialindex, field, material)
      }
      handleAddMaterial={() => handleAddMaterial(index)}
      handleRemoveMaterial={(materialId) =>
        handleRemoveMaterial(index, materialId)
      }
      taxRates={settings.taxRates}
      handleMaterialTaxChange={(materialId, taxId, isSelected) =>
        handleMaterialTaxChange(index, materialId, taxId, isSelected)
      }
    />
  );
  if (loading) {
    return <Loader />;
  }
  if (error) {
    return (
      <>
        <Alert severity="error">
          The Invoice is Removed and could not be found
          <Link
            to="/app/list-invoices"
            style={{ textDecoration: "underline", color: "blue" }}
          >
            Go to Invoice List
          </Link>
        </Alert>
      </>
    );
  }

  return (
    <div className="container-fluid p-4">
      <div className="row">
        <div className="col-12 d-flex justify-content-end mb-3">
          <button
            type="button"
            className="btn btn-success"
            onClick={handleSaveInvoice}
          >
            Update Invoice
          </button>
        </div>
        <div className="container-fluid">
          {/* Full-width form on the left side */}
          <div className="row">
            <div className="col-12">
            

              {/* Buyer Information */}
              <h6 className="mb-2 fw-bold">Buyer Information</h6>
              {editableInvoice && (
                <>
                  <div className="mb-3 row">
                    <div className="col-12 col-md-6 mb-3">
                      <label>Company Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.companyName || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            companyName: e.target.value,
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.country || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              country: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        value={editableInvoice.buyer.contact.email || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              email: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.contact.name || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              name: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.contact.phone || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            contact: {
                              ...editableInvoice.buyer.contact,
                              phone: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                  </div>

                  <div className="mb-3 row">
                    <div className="col-12 col-md-6 mb-3">
                      <label>Street</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.street || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              street: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.city || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              city: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Province</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.province || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              province: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Postal Code</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.postalCode || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              postalCode: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                    <div className="col-12 col-md-6 mb-3">
                      <label>Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value={editableInvoice.buyer.address.country || ""}
                        onChange={(e) =>
                          handleInputChange("buyer", {
                            ...editableInvoice.buyer,
                            address: {
                              ...editableInvoice.buyer.address,
                              country: e.target.value,
                            },
                          })
                        }
                        //disabled={isDisabled}
                      />
                    </div>
                  </div>
                </>
              )}

              {/* Invoice Specifics */}
              <h6 className="mb-2 fw-bold">
                {documentType === "Estimate"
                  ? "Estimate Details"
                  : "Invoice Details"}
              </h6>
              {editableInvoice && (
                <div className="mb-3 row">
                  <div className="col-12 col-md-6 mb-3">
                    {documentType === "Invoice" ? (
                      <div>
                        <label>Invoice Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={editableInvoice.invoiceNumber || ""}
                          //disabled={isDisabled}
                          onChange={(e) =>
                            setEditableInvoice({
                              ...editableInvoice,
                              invoiceNumber: e.target.value,
                            })
                          }
                        />
                      </div>
                    ) : (
                      <div>
                        <label>Estimate Number</label>
                        <input
                          type="text"
                          className="form-control"
                          value={editableInvoice.estimateNumber || ""}
                          //disabled={isDisabled}
                          onChange={(e) =>
                            setEditableInvoice({
                              ...editableInvoice,
                              estimateNumber: e.target.value,
                            })
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="col-12 col-md-6 mb-3">
                    <label>Invoice Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={
                        editableInvoice.invoiceDate
                          ? new Date(editableInvoice.invoiceDate)
                              .toISOString()
                              .slice(0, 10)
                          : new Date(editableInvoice.estimateDate)
                              .toISOString()
                              .slice(0, 10)
                      }
                      //disabled={isDisabled}
                      onChange={(e) =>
                        setEditableInvoice({
                          ...editableInvoice,
                          invoiceDate: new Date(e.target.value).toISOString(),
                        })
                      }
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-3">
                    <label>Due Date</label>
                    <input
                      type="date"
                      className="form-control"
                      value={
                        editableInvoice.dueDate
                          ? new Date(editableInvoice.dueDate)
                              .toISOString()
                              .slice(0, 10)
                          : ""
                      }
                      //disabled={isDisabled}
                      onChange={(e) =>
                        setEditableInvoice({
                          ...editableInvoice,
                          dueDate: new Date(e.target.value).toISOString(),
                        })
                      }
                    />
                  </div>

                  <div className="col-12 col-md-6 mb-3">
                    <label>Total Amount</label>
                    <input
                      type="text"
                      className="form-control"
                      value={`$${editableInvoice.total.toFixed(2)}`}
                      disabled
                      readOnly
                      style={{ fontWeight: "bold", color: "green" }}
                    />
                  </div>
                </div>
              )}

              {editableInvoice && (
                <div className="mb-3">
                  <label>Email Message</label>
                  <textarea
                    className="form-control"
                    value={editableInvoice.emailMessage || ""}
                    onChange={(e) =>
                      handleInputChange("emailMessage", e.target.value)
                    }
                    rows={6}
                    //disabled={isDisabled}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {products.map((item, index) => (
          <div className="col-lg-12" key={index}>
            <div className="row g-3 align-items-center">
              {/* Product Selection */}
              <div className="col-lg-3">
                <label className="form-label">Product</label>
                <select
                  className="form-select"
                  value={item.type || ""}
                  onChange={(e) =>
                    handleProductChange(index, "type", e.target.value)
                  }
                >
                  <option value="">Select Product</option>
                  {productTypes.map((product) => (
                    <option key={product._id} value={product.name}>
                      {product.name}
                    </option>
                  ))}
                  <option value="Materials">Materials/Components</option>
                </select>
              </div>

              {/* Tax Rates for Regular Products */}
              {item.type !== "Materials" && (
                <div className="col-lg-3">
                  <label className="form-label">Tax Rates</label>
                  <div className="d-flex flex-wrap">
                    {settings.taxRates &&
                      settings.taxRates.map((taxRate) => (
                        <div
                          className="form-check form-check-inline"
                          key={taxRate._id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={item?.taxes?.some(
                              (rate) => rate.type === taxRate.type
                            )}
                            onChange={(e) =>
                              handleTaxRateChange(
                                index,
                                taxRate,
                                e.target.checked
                              )
                            }
                          />
                          <label className="form-check-label">
                            {taxRate.type} ({taxRate.value}%)
                          </label>
                        </div>
                      ))}
                  </div>
                </div>
              )}

              {/* Render Material Table if Product is "Materials" */}
              {item.type === "Materials" && renderMaterialSection(index)}

              {/* If Product is not "Materials", render the other fields */}
              {item.type !== "Materials" && (
                <>
                  {/* Quantity */}
                  <div className="col-lg-2">
                    <label className="form-label">Quantity</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Quantity"
                      value={item.quantity || 1}
                      onChange={(e) =>
                        handleProductChange(index, "quantity", e.target.value)
                      }
                    />
                  </div>

                  {/* Price */}
                  <div className="col-lg-1">
                    <label className="form-label">Price $</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Price"
                      value={item.price}
                      onChange={(e) =>
                        handleProductChange(index, "price", e.target.value)
                      }
                    />
                  </div>

                  {/* Tax */}
                  <div className="col-lg-1">
                    <label className="form-label">Tax $</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={
                        item.taxes
                          ? item.taxes
                              .reduce(
                                (sum, tax) => sum + parseFloat(tax.amount || 0),
                                0
                              )
                              .toFixed(2)
                          : 0
                      }
                    />
                  </div>

                  {/* Total */}
                  <div className="col-lg-2">
                    <label className="form-label">Total $</label>
                    <input
                      type="text"
                      className="form-control"
                      readOnly
                      value={item.total}
                    />
                  </div>
                </>
              )}

              {/* Remove Button */}
              <div className="col-12 text-end">
                <button
                  type="button"
                  className="btn btn-danger mt-2"
                  onClick={() => handleRemoveProduct(index)}
                  disabled={products.length === 1}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        ))}

        {/* Add Product Button */}
        <div className="col-lg-12 mt-3">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleAddProduct}
          >
            Add Product
          </button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceEditComp;
