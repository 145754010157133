// Utility function to find selected dealer
export const getSelectedDealer = (dealerList, dealerId) => {
  return dealerList.find((d) => d._id === dealerId);
};

// Utility function to check pricing for headrail
export const checkHeadrailPricing = (dealer, headrailId, productType) => {
  const headrailConfig = dealer?.headrail?.find(
    (headrail) => headrail.itemId === headrailId
  );
  return headrailConfig?.products?.[productType];
};

export const checkProductRate = (dealer, productType) => {
    const rate = dealer?.productRate?.[productType];
    return rate > 0; 
  };
  