import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../components/layout/Loader";
import {
  ViewDealerAction,
  updateHeadrailConfigAndRates,
  updateLiftStyleConfigAndRates,
  updateMotorConfigAndRates,
} from "../../redux/actions/DealerAction";
import {
  getInvItemsByCat,
  getLiftStyleItems,
} from "../../redux/actions/InventoryAction";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Box, Tab, Tabs, Typography, styled } from "@mui/material";
import LiftStyleConfigTable from "./dealerproductPricing/ListStylePricingTable";
import HeadrailConfigTable from "./dealerproductPricing/HeadrailPricing";

const Alert = styled(MuiAlert)(({ theme }) => ({
  width: "600px",
  height: "60px",
  fontSize: "1rem",
  padding: theme.spacing(2),
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: theme.spacing(5),
}));
const EditRatesOfProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { userInfo } = useSelector((state) => state.userSignIn);
  const {
    loading,
    dealer,
    success: detailSuccess,
  } = useSelector((state) => state.viewDealer);
  const { data: inventoryData } = useSelector(
    (state) => state.getInvItemsByCat
  );
  const items = inventoryData?.[0]?.items || [];
  const { liftStyleItems } = useSelector((state) => state.liftSyleItems);
  const liftStyleCategory = liftStyleItems?.find(item => item.category === "Lift_hardware");
  const motorCategory = liftStyleItems?.find(item => item.category === "Motor");
  
  const LiftStyle = liftStyleCategory?.items || [];
  const Motor = motorCategory?.items || [];
  
  const [productRates, setProductRates] = useState({});
  const [headrailConfigList, setHeadrailConfigList] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [liftUpdateSnackbar, setLiftUpdateSnackbar] = useState(false);
  const [liftUpdateMessage, setLiftUpdateMessage] = useState("");

  const [liftStyleConfigList, setLiftStyleConfigList] = useState({});
  const [motorConfigList, setMotorConfigList] = useState([]);

  // console.log(liftStyleConfigList);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (id && userInfo.token) {
      dispatch(ViewDealerAction(id, userInfo.token));
    }
  }, [dispatch, id, userInfo.token]);

  useEffect(() => {
    if (detailSuccess && dealer) {
      setProductRates(dealer.productRate || {});

      const headrailList = items.map((item) => {
        const dealerHeadrail = dealer.headrail.find(
          (h) => h.itemId.toString() === item.id
        ) || {
          itemId: item.id,
          products: {},
        };

        return {
          name: item.name,
          id: item.id,
          products: dealerHeadrail.products,
        };
      });
      setHeadrailConfigList(headrailList);

      // Initialize lift style prices
      const liftStyleList = LiftStyle.map((item) => {
        const dealerLiftStyle = dealer.liftStyle?.find(
          (l) => l.itemId.toString() === item.id
        ) || {
          itemId: item.id,
          productPricing: {},
        };

        return {
          name: item.name,
          id: item.id,
          productPricing: dealerLiftStyle.productPricing,
        };
      });
      setLiftStyleConfigList(liftStyleList);
      const motorList = Motor.map((item) => {
        const dealerMotor = dealer.Motor?.find(
          (m) => m.itemId.toString() === item.id
        ) || {
          itemId: item.id,
          productPricing: {},
        };
  
        return {
          name: item.name,
          id: item.id,
          productPricing: dealerMotor.productPricing,
        };
      });
      setMotorConfigList(motorList);
    }
  }, [detailSuccess, dealer, items]);

  const handleRateChange = (product, value) => {
    setProductRates((prevRates) => ({ ...prevRates, [product]: value }));
  };

  const handleHeadrailConfigChange = useCallback(
    (index, productName, field, value) => {
      setHeadrailConfigList((prevConfig) => {
        const updatedConfig = [...prevConfig];

        // Split the field to support nested structure
        const keys = field.split(".");
        const product = updatedConfig[index].products[productName];

        if (keys[0] === "sleeveOn" || keys[0] === "sleeveOff") {
          product[keys[0]] = product[keys[0]] || {};

          // Handle unitRate specifically
          if (keys[1] === "unitRate") {
            product[keys[0]].unitRate = value;
          } else {
            product[keys[0]][keys[1]] = value;
          }
        }

        return updatedConfig;
      });
    },
    [setHeadrailConfigList]
  );

  const handleLiftStyleConfigChange = useCallback(
    (index, productName, field, value) => {
      setLiftStyleConfigList((prevConfig) => {
        // Create a new copy of the config list to ensure immutability
        const updatedConfig = prevConfig.map((liftStyle, idx) => {
          if (idx === index) {
            // When we find the matching liftStyle, we need to update it
            const updatedLiftStyle = { ...liftStyle };

            // Make sure productPricing is an object and not undefined
            const updatedProductPricing = {
              ...updatedLiftStyle.productPricing,
            };

            if (updatedProductPricing[productName]) {
              // Update either addonPrice or unitRate based on the field
              updatedProductPricing[productName] = {
                ...updatedProductPricing[productName],
                [field]: value,
              };
            }

            // Update the liftStyle's productPricing with the new value
            updatedLiftStyle.productPricing = updatedProductPricing;

            return updatedLiftStyle;
          }
          return liftStyle;
        });

        return updatedConfig;
      });
    },
    [setLiftStyleConfigList]
  );

  const handleMotorConfigChange = useCallback(
    (index, productName, field, value) => {
      setMotorConfigList((prevConfig) => {
        // Create a new copy of the config list to ensure immutability
        const updatedConfig = prevConfig.map((motor, idx) => {
          if (idx === index) {
            // When we find the matching motor, we need to update it
            const updatedMotor = { ...motor };
  
            // Make sure productPricing is an object and not undefined
            const updatedProductPricing = {
              ...updatedMotor.productPricing,
            };
  
            if (updatedProductPricing[productName]) {
              // Update either addonPrice or unitRate based on the field
              updatedProductPricing[productName] = {
                ...updatedProductPricing[productName],
                [field]: value,
              };
            } else {
              // Initialize the productPricing if it doesn't exist
              updatedProductPricing[productName] = { [field]: value };
            }
  
            // Update the motor's productPricing with the new value
            updatedMotor.productPricing = updatedProductPricing;
  
            return updatedMotor;
          }
          return motor;
        });
  
        return updatedConfig;
      });
    },
    [setMotorConfigList]
  );
  console.log(motorConfigList);
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Prepare the updated data
    const updatedData = {
      dealerId: id,
      productRate: productRates,
      headrails: headrailConfigList.map((headrail) => ({
        itemId: headrail.id,
        products: Object.entries(headrail.products).reduce(
          (acc, [productName, productConfig]) => {
            acc[productName] = {
              sleeveOn: {
                ...(productConfig.sleeveOn || {}),
                addonPrice: productConfig.sleeveOn?.addonPrice || 0, // Keep the actual value or set to 0
                unitRate: productConfig.sleeveOn?.unitRate || "sqft",
              },
              sleeveOff: {
                ...(productConfig.sleeveOff || {}),
                addonPrice: productConfig.sleeveOff?.addonPrice || 0, // Keep the actual value or set to 0
                unitRate: productConfig.sleeveOff?.unitRate || "sqft",
              },
            };
            return acc;
          },
          {}
        ),
      })),
    };
  
    // Dispatch the update without setting addonPrice to 1
    dispatch(updateHeadrailConfigAndRates(updatedData, userInfo.token))
      .then(() => {
        setSnackbarMessage("Update successful!");
        setOpenSnackbar(true);
      })
      .catch((error) => {
        setSnackbarMessage("Update failed. Please try again.");
        setOpenSnackbar(true);
      });
  };
  
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    setLiftUpdateSnackbar(false);
  };

  useEffect(() => {
    if (userInfo?.token) {
      dispatch(getInvItemsByCat("Headrail", userInfo.token));
      dispatch(getLiftStyleItems("Lift_hardware,Motor", userInfo.token));
    }
  }, [dispatch, userInfo.token]);

  const handleLiftStyleUpdate = () => {
    const updatedData = {
      dealerId: id,
      productRate: productRates,
      liftStyles: liftStyleConfigList.map((liftstyle) => ({
        itemId: liftstyle.id,
        productPricing: liftstyle.productPricing,
        unitRate: liftstyle.unitRate,
      })),
    };
    dispatch(updateLiftStyleConfigAndRates(updatedData, userInfo.token))
      .then(() => {
        setLiftUpdateMessage("Lift style update successful!");
        setLiftUpdateSnackbar(true);
      })
      .catch(() => {
        setLiftUpdateMessage("Failed to update lift styles.");
        setLiftUpdateSnackbar(true);
      });
  };

  const handleMotorUpdate = () => {
    const updatedData = {
      dealerId: id,
      productRate: productRates,
      motors: motorConfigList.map((motor) => ({
        itemId: motor.id,
        productPricing: motor.productPricing,
        unitRate: motor.unitRate,
      })),
    };
  
    dispatch(updateMotorConfigAndRates(updatedData, userInfo.token))
      .then(() => {
        setLiftUpdateMessage("Motor update successful!");
        setLiftUpdateSnackbar(true);
      })
      .catch(() => {
        setLiftUpdateMessage("Failed to update motor styles.");
        setLiftUpdateSnackbar(true);
      });
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2>
          Edit Product Rates & Headrail of {" "}
          <span class="text-primary font-weight-bold">{dealer?.name}</span> -
          <span class="text-primary font-weight-bold">
            {dealer?.companyName}
          </span>
        </h2>{" "}
        <button type="submit" form="editRatesForm" className="btn btn-primary">
          Update
        </button>
      </div>
      <hr />

      {loading ? (
        <Loader />
      ) : (
        <>
          {detailSuccess ? (
            <form id="editRatesForm" onSubmit={handleSubmit}>
              {/* Product Rates Table */}
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Product</th>
                      <th>Rate (per Unit)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.entries(productRates).map(([product]) => (
                      <tr key={product}>
                        <td>{product}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={productRates[product] || 0}
                            onChange={(e) =>
                              handleRateChange(product, e.target.value)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <Box sx={{ borderBottom: 1, borderColor: "divider", mt: 4 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  sx={{
                    "& .MuiTab-root": {
                      color: "white",
                      "&.Mui-selected": {
                        color: "white",
                        fontWeight: "bold",
                      },
                    },
                  }}
                >
                  <Tab label="Headrail Pricing" />
                  <Tab label="LiftStyle Pricing" />
                </Tabs>
              </Box>

              <Box sx={{ mt: 2 }}>
                {tabValue === 0 && (
                  <Box>
                    <HeadrailConfigTable
                      headrailConfigList={headrailConfigList}
                      handleHeadrailConfigChange={handleHeadrailConfigChange}
                    />
                  </Box>
                )}
                {tabValue === 1 && (
                  <Box>
                    <h4>Lift addon Pricing</h4>

                    <LiftStyleConfigTable
                      liftStyleConfigList={liftStyleConfigList}
                      handleLiftStyleConfigChange={handleLiftStyleConfigChange}
                      handleLiftStyleUpdate={handleLiftStyleUpdate}
                      motorConfigList={motorConfigList}
                      handleMotorConfigChange={handleMotorConfigChange}
                      handleMotorUpdate={handleMotorUpdate}
                    />
                  </Box>
                )}
              </Box>
            </form>
          ) : (
            <p>No dealer details available.</p>
          )}

          {/* Snackbar for success message */}
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              {snackbarMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={liftUpdateSnackbar}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleSnackbarClose} severity="success">
              {liftUpdateMessage}
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
};

export default EditRatesOfProducts;
