//Table Head
import React from 'react';
import PropTypes from 'prop-types';
import {
    TableHead,
    TableRow,
    TableCell,
    Checkbox,
    TableSortLabel,
    Box
} from '@mui/material';
import { visuallyHidden } from '@mui/utils';

// Define head cells for the table
const headCells = [
    {
        id: 'orderId',
        numeric: false,
        disablePadding: true,
        label: 'Order Id',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'productType',
        numeric: false,
        disablePadding: false,
        label: 'Product Type',
    },
    {
        id: 'tag',
        numeric: false,
        disablePadding: false,
        label: 'Order Tag',
    },
    {
        id: 'quantity',
        numeric: true,
        disablePadding: false,
        label: 'Quantity',
    },
    {
        id: 'liftStyle',
        numeric: false,
        disablePadding: false,
        label: 'Lift Style',
    },
    {
        id: 'factor',
        numeric: true,
        disablePadding: false,
        label: 'Full/SQFT',
    },
    {
        id: 'payment',
        numeric: true,
        disablePadding: false,
        label: 'Payment',
    },
    {
        id:'outstandingamount',
        numeric:true,
        disablePadding:false,
        label:'Oustanding'
    },
    {
        id: 'paymentStatus',
        numeric: false,
        disablePadding: false,
        label: 'Payment Status',
    },
    {
        id: 'invoiceStatus',
        numeric: false,
        disablePadding: false,
        label: 'Invoice Status',
    },
    {
        id: 'View',
        numeric: false,
        disablePadding: false,
        label: 'View Order',
    },
];

// EnhancedTableHead component
function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all orders',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'left' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

// Prop types validation
EnhancedTableHead.propTypes = {
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
};

export default EnhancedTableHead;
