import React from "react";
import AddCustomFabricModal from "../ui/AddCustomFabricModal";
import AddCustomLiftHardwareModal from "../ui/AddCustomLiftStyle";
import { formatLabel } from "./ProductTypeMapping";

const OrderDetailsForm = ({
  orderDetails,
  dealer,
  handleChange,
  productTypeOptions,
  renderHeadrailOptions,
  showCustomFabricModal,
  setShowCustomFabricModal,
  headrailConfig,
  sleeveOption,
  handleSleeveChange,
  Fabrics,
}) => {
  return (
    <>
      <div className="col-lg-8 row g-3">
        <div className="col-lg-4">
          <label className="form-label">Company Name</label>
          <select
            name="dealerName"
            className="form-select"
            value={orderDetails.dealerName}
            onChange={handleChange}
          >
            <option value=""></option>
            {dealer &&
              dealer.map((c) => (
                <option key={c._id} value={c.name + "_" + c._id}>
                  {`${c.companyName}-${c.name}`}
                </option>
              ))}
          </select>
        </div>
        <div className="col-lg-4">
          <label className="form-label">Product Tag</label>
          <input
            name="productTag"
            type="text"
            className="form-control"
            placeholder="Order PO/Tag"
            value={orderDetails.productTag}
            onChange={handleChange}
          />
        </div>
        <div className="col-lg-4">
          <label className="form-label">Product Type</label>
          <select
            name="productType"
            className="form-select"
            value={orderDetails.productType}
            onChange={handleChange}
          >
            <option value=""></option>
            {productTypeOptions.map((option) => (
              <option key={option._id} value={option.name}>
                {formatLabel(option.name)}
              </option>
            ))}
            <option value="Materials">Materials/Components</option>
          </select>
        </div>
        {orderDetails.productType !== "Materials" && (
          <div className="col-lg-4">
            <label className="form-label">Headrail/Cassette</label>
            <select
              name="headrail"
              className="form-select"
              required
              value={orderDetails.headrail || orderDetails.headrail?.name}
              onChange={handleChange}
            >
              {!orderDetails.headrail && <option value=""></option>}
              {renderHeadrailOptions(orderDetails.productType)}
              {orderDetails.headrail && (
                <option>{orderDetails.headrail.name}</option>
              )}
            </select>
          </div>
        )}
        {headrailConfig?.config?.headrail && (
          <div className="col-lg-4">
            <label className="form-label">Sleeve Option</label>
            <select
              className="form-select"
              value={sleeveOption}
              onChange={handleSleeveChange}
            >
              <option value="">Select sleeve</option>
              {headrailConfig.config.headrail.sleeveOn?.isTrue ? (
                <>
                  <option value="sleeveOn">Sleeve On</option>
                  <option value="sleeveOff">Sleeve Off</option>
                </>
              ) : (
                <option value="sleeveOff">Sleeve Off</option>
              )}
            </select>
          </div>
        )}
        <>
          {orderDetails.productType !== "Materials" && (
            <div className="col-lg-4">
              <label className="form-label">Product Material</label>
              <select
                name="productMaterial"
                className="form-control"
                value={orderDetails.productMaterial}
                onChange={(e) => {
                  handleChange(e);
                  if (e.target.value === "custom") {
                    setShowCustomFabricModal(true);
                  }
                }}
              >
                <option value="">Select Fabric</option>
                {Fabrics.map((fabric) => (
                  <option key={fabric.id} value={fabric.id}>
                    {fabric.name}
                  </option>
                ))}
                <option value="custom">Custom Fabric</option>
              </select>
            </div>
          )}
          <AddCustomFabricModal
            show={showCustomFabricModal}
            handleClose={() => setShowCustomFabricModal(false)}
          />
        </>
      </div>
      <div className="col-lg-4 row g-3">
        <div className="col-12">
          <label className="form-label">Order Remark</label>
          <textarea
            name="orderRemark"
            className="form-control"
            placeholder="Remarks, order tag..."
            rows={5}
            cols={4}
            value={orderDetails.orderRemark}
            onChange={handleChange}
          />
        </div>
      </div>
    </>
  );
};

export default OrderDetailsForm;
