
export const FETCH_ISSUED_INVOICES_REQUEST = "FETCH_ISSUED_INVOICES_REQUEST";
export const FETCH_ISSUED_INVOICES_SUCCESS = "FETCH_ISSUED_INVOICES_SUCCESS";
export const FETCH_ISSUED_INVOICES_FAILURE = "FETCH_ISSUED_INVOICES_FAILURE";

export const FETCH_DRAFT_INVOICES_REQUEST = "FETCH_DRAFT_INVOICES_REQUEST";
export const FETCH_DRAFT_INVOICES_SUCCESS = "FETCH_DRAFT_INVOICES_SUCCESS";
export const FETCH_DRAFT_INVOICES_FAILURE = "FETCH_DRAFT_INVOICES_FAILURE";

// -----Invoice pdf and data fetching----
export const INVOICE_FETCH_REQUEST = "INVOICE_FETCH_REQUEST";
export const INVOICE_FETCH_SUCCESS = "INVOICE_FETCH_SUCCESS";
export const INVOICE_FETCH_FAIL = "INVOICE_FETCH_FAIL";


// estimated fetching
export const FETCH_ESTIMATES_REQUEST = "FETCH_ESTIMATES_REQUEST";
export const FETCH_ESTIMATES_SUCCESS = "FETCH_ESTIMATES_SUCCESS";
export const FETCH_ESTIMATES_FAILURE = "FETCH_ESTIMATES_FAILURE";

//provinces 
export const provinces = [
    "Alberta",
    "British Columbia",
    "Manitoba",
    "New Brunswick",
    "Newfoundland and Labrador",
    "Nova Scotia",
    "Ontario",
    "Prince Edward Island",
    "Quebec",
    "Saskatchewan",
  ];