import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HeadrailConfigEdit from "./HeadrailConfigEdit";
import { Snackbar, Alert } from "@mui/material";
import {
  fetchInventoryItem,
  updateInventoryItemConfig,
} from "../../redux/actions/InventoryAction";
import { invParents } from "../../constants/GlobalConstants";

const EditInventoryItem = ({ inventoryId }) => {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const [item, setItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({
    headrail: {
      sleeveOn: { isTrue: false, addonPrice: 0 },
      sleeveOff: { isTrue: false },
    },
  });

  // Snackbar state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [deductionMethod, setDeductionMethod] = useState("");

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const fetchedItem = await fetchInventoryItem(
          inventoryId,
          userInfo.token,
        );

        if (!fetchedItem.config || fetchedItem.config === 0) {
          fetchedItem.config = {
            headrail: {
              sleeveOn: { isTrue: false, addonPrice: 0 },
              sleeveOff: { isTrue: false },
            },
          };
        }
        setDeductionMethod(fetchedItem.deductionMethod);
        setItem(fetchedItem);
        setConfig(fetchedItem.config);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching inventory item:", error);
        setLoading(false);
      }
    };

    fetchItem();
  }, [inventoryId, userInfo.token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSend = {
        config,
        deductionMethod,
        category: item.category,
        name:item.name
      };
      await updateInventoryItemConfig(inventoryId, dataToSend, userInfo.token);
      setSnackbarMessage("Configuration updated successfully!");
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating inventory item:", error);
      setSnackbarMessage("Failed to update configuration.");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update checkbox or input fields
    if (type === "checkbox") {
      if (name === "sleeveOn") {
        setConfig((prevConfig) => ({
          ...prevConfig,
          headrail: {
            ...prevConfig.headrail,
            sleeveOn: { ...prevConfig.headrail.sleeveOn, isTrue: checked },
            sleeveOff: { ...prevConfig.headrail.sleeveOff, isTrue: !checked },
          },
        }));
      } else if (name === "sleeveOff") {
        setConfig((prevConfig) => ({
          ...prevConfig,
          headrail: {
            ...prevConfig.headrail,
            sleeveOff: { ...prevConfig.headrail.sleeveOff, isTrue: checked },
            sleeveOn: { ...prevConfig.headrail.sleeveOn, isTrue: !checked },
          },
        }));
      }
    } else {
      // For other input fields (like addonPrice)
      setConfig((prevConfig) => ({
        ...prevConfig,
        headrail: {
          ...prevConfig.headrail,
          [name]: { ...prevConfig.headrail[name], addonPrice: value },
        },
      }));
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (!item) {
    return <div className="text-center">Item not found.</div>;
  }

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Edit Inventory Item: {item.name}</h2>
      <form onSubmit={handleSubmit} className="mt-3">
        {/* Item Details Section */}
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title text-primary">Item Details</h5>
            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={item.name}
                  onChange={(e) =>
                    setItem((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Category</label>
                <select
                  className="form-control"
                  name="category"
                  value={item.category}
                  onChange={(e) =>
                    setItem((prev) => ({ ...prev, category: e.target.value }))
                  }
                >
                  <option value="" disabled>
                    Select Category
                  </option>
                  {invParents.map((parent) => (
                    <option key={parent} value={parent}>
                      {parent}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-12">
                <label className="form-label">Description</label>
                <textarea
                  className="form-control"
                  value={item.description}
                  rows={4}
                  disabled
                ></textarea>
              </div>
              <div className="col-md-6">
                <label className="form-label">In Stock</label>
                <input
                  type="number"
                  className="form-control"
                  value={item.inStock}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Stock Alert</label>
                <input
                  type="number"
                  className="form-control"
                  value={item.stockAlert}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Cost per Unit</label>
                <input
                  type="number"
                  className="form-control"
                  value={item.cost}
                  disabled
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Selling Price</label>
                <input
                  type="number"
                  className="form-control"
                  value={item.sellingPrice}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        {/* Config Section */}
        <div className="card mb-3">
          <div className="card-body">
            <div className="row g-3">
              <div className="card mb-3">
                <div className="card-body">
                  <h5 className="card-title text-success">Configuration</h5>
                  {item.category === "Headrail" && (
                    <HeadrailConfigEdit
                      formData={config.headrail}
                      handleChange={handleChange}
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <label className="form-label">Deduction Method</label>
                <select
                  className="form-control"
                  name="deductionMethod" // Set the name for the select input
                  value={deductionMethod}
                  onChange={(e) => setDeductionMethod(e.target.value)}
                >
                  <option value="">Select Deduction Method</option>
                  <option value="width">Width</option>
                  <option value="height">Height</option>
                  <option value="sqft">Square Feet (sqft)</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="text-end">
          <button type="submit" className="btn btn-primary">
            Save Changes
          </button>
        </div>
      </form>

      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default EditInventoryItem;
