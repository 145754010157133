import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import {
  FETCH_DRAFT_INVOICES_FAILURE,
  FETCH_DRAFT_INVOICES_REQUEST,
  FETCH_DRAFT_INVOICES_SUCCESS,
  FETCH_ESTIMATES_FAILURE,
  FETCH_ESTIMATES_REQUEST,
  FETCH_ESTIMATES_SUCCESS,
  FETCH_ISSUED_INVOICES_FAILURE,
  FETCH_ISSUED_INVOICES_REQUEST,
  FETCH_ISSUED_INVOICES_SUCCESS,
  INVOICE_FETCH_FAIL,
  INVOICE_FETCH_REQUEST,
  INVOICE_FETCH_SUCCESS,
} from "../../constants/InvoiceConstants";

export const fetchIssuedInvoices =
  ({ dealerId = null, page = 1, limit = 10, statuses = ["Issued", "Paid"] }) =>
  async (dispatch, getState) => {
    try {
      console.log(dealerId);
      dispatch({ type: FETCH_ISSUED_INVOICES_REQUEST });
      const { userInfo } = getState().userSignIn;

      const endpoint = `${API_URI}/api/invoices${
        dealerId ? `/dealer/${dealerId}` : ""
      }?page=${page}&limit=${limit}&status=${statuses.join(",")}`;

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(endpoint, config);

      dispatch({
        type: FETCH_ISSUED_INVOICES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ISSUED_INVOICES_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };

export const fetchDraftInvoices =
  ({ dealerId = null, page = 1, limit = 10, statuses = ["Draft"] }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_DRAFT_INVOICES_REQUEST });
      const { userInfo } = getState().userSignIn;

      const endpoint = `${API_URI}/api/invoices${
        dealerId ? `/dealer/${dealerId}` : ""
      }?page=${page}&limit=${limit}&status=${statuses.join(",")}`;

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(endpoint, config);

      dispatch({
        type: FETCH_DRAFT_INVOICES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_DRAFT_INVOICES_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };

export const fetchInvoiceData = (id, token) => async (dispatch) => {
  try {
    dispatch({ type: INVOICE_FETCH_REQUEST });

    const response = await axios.get(`${API_URI}/api/app/invoices/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: INVOICE_FETCH_SUCCESS,
      payload: {
        invoice: response.data.invoice,
        pdf: response.data.pdf,
        documentType: response.data.documentType,
      },
    });
  } catch (error) {
    dispatch({
      type: INVOICE_FETCH_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const fetchEstimates =
  ({
    dealerId = null,
    page = 1,
    limit = 10,
    statuses = ["Draft", "Issued", "Converted"],
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: FETCH_ESTIMATES_REQUEST });

      const { userInfo } = getState().userSignIn;

      const endpoint = `${API_URI}/api/invoices/estimates${
        dealerId ? `/dealer/${dealerId}` : ""
      }?page=${page}&limit=${limit}&status=${statuses.join(",")}`;

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(endpoint, config);

      dispatch({
        type: FETCH_ESTIMATES_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: FETCH_ESTIMATES_FAILURE,
        payload: error.response?.data?.message || error.message,
      });
    }
  };
