import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import fileDownload from "js-file-download";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/layout/Loader";
import { useNavigate, useParams } from "react-router";
import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { Link, useSearchParams } from "react-router-dom";
//Dropdown Action menu
import VisibilityIcon from "@mui/icons-material/Visibility";
import EnhancedTableToolbar from "../../components/dealer/accountView/EnhancedTableToolbar";
import EnhancedTableHead from "../../components/dealer/accountView/EnhancedTableHead";
import EditPayment from "../../components/dealer/accountView/EditPayment";
import { Button, Card, CardContent, Modal, Typography } from "@mui/material";
import AccountSection from "../../components/dealer/accountView/AccountSection";
import PaymentModal from "../../components/dealer/accountView/PaymentModal";
import { Height } from "@mui/icons-material";
import BulkPaymentModal from "../../components/dealer/accountView/BulkPaymentModal";
import ReusableAlert from "../../components/layout/alert/AlertMessage";
import { getDealerOrders } from "../../redux/actions/DealerAction";
import { useSnackbarNotification } from "../../components/order/reusables/UseSnackBarNotification";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

//= ------------------------Main Table---------------------------
export default function EnhancedTable({ nav }) {
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month") || 0;
  const navigate = useNavigate();
  const { id } = useParams();
  const { loading, orderMonth, rows, error } = useSelector(
    (state) => state.dealerOrder
  );

  // const [orderMonth, setOrderMonth] = useState("");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [isPaymentInput, setIsPaymentInput] = useState(false);
  const { showSnackbar, SnackbarComponent } = useSnackbarNotification();
  const [monthVal, setMonthVal] = useState(0);
  let renderDispatch = true;
  // const [rows, setRows] = useState([]);
  // const [loading, setLoading] = useState(true);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isBulkPaymentModalOpen, setIsBulkPaymentModalOpen] = useState(false);
  const [alert, setAlert] = useState({
    title: "",
    description: "",
    variant: "",
  });
  const [refresh, setRefresh] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.orderId);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  //++ If pagination need
  // const handleChangeRowsPerPage = (event) => {
  //     setRowsPerPage(parseInt(event.target.value, 10));
  //     setPage(0);
  // };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  //| Getting the selected item
  const isSelected = (name) => selected.indexOf(name) !== -1;
  // Calculating total due amount

  //++ If pagination
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  //_________________________MY CODE______________________________

  //+     Setting the data (row) for the MUI data array

  //getting month by month order
  // const getDealerOrder = async (variables) => {
  //   try {
  //     const { data } = await axios.post(
  //       `${API_URI}/api/order/order-by-dealer?month=${month}`,
  //       variables,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${userInfo.token}`
  //         }
  //       }
  //     )
  //     setOrderMonth(data.orderMonth);
  //     if (data) {
  //       if (!variables.loadMore) {
  //         setRows(data.rows);
  //         setLoading(false);
  //         //| Need to load the createRowArray fun here
  //       } else if (variables.loadMore) {
  //         setRows(data.rows);
  //         setLoading(false);
  //       }
  //     } else {
  //       console.log("Data is not received in the FE function");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //print account sheet
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const printAccountOrderSheet = async (fileType = "pdf") => {
    try {
      const response = await axios.get(
        `${API_URI}/api/dealer/print-account-order-sheet?id=${id}&month=${month}`,
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
          responseType: "json",
        }
      );

      const { fileName, fileContent } = response.data;

      const companyName = fileName.split(" - ")[0];

      // Determine the month and year
      const date = new Date();
      date.setMonth(date.getMonth() - month);
      const targetMonthName = monthNames[date.getMonth()];
      const targetYear = date.getFullYear();

      // Get the appropriate file content based on file type (PDF or Excel)
      const fileBase64 =
        fileType === "pdf" ? fileContent.pdf : fileContent.excel;

      // Convert Base64 string to Blob
      const mimeType =
        fileType === "pdf"
          ? "application/pdf"
          : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      const fileBlob = new Blob(
        [Uint8Array.from(atob(fileBase64), (c) => c.charCodeAt(0))],
        { type: mimeType }
      );

      // Dynamic file name with company name, month, and year
      const fileExtension = fileType === "pdf" ? "pdf" : "xlsx";
      fileDownload(
        fileBlob,
        `Account sheet of ${companyName} in ${targetMonthName} ${targetYear}.${fileExtension}`
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch({ type: "DEALER_DETAIL_RESET" });
    if (!userInfo) {
      navigate("/sign-in");
    }
    window.scrollTo(0, 0);
    if (window.innerWidth > 1200) {
      nav.setIsSidebar("wrapper toggled");
    }
  }, [userInfo, navigate]);

  useEffect(() => {
    const variables = {
      id,
      monthVal,
      loadMore: false,
      month,
    };
    dispatch(getDealerOrders(variables));
    if (window.innerWidth > 1200) {
      nav.setIsSidebar("wrapper toggled");
    }
  }, [id, month]);

  const handleOpenPaymentModal = () => {
    setIsPaymentModalOpen(true);
  };

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const handleSavePayment = (response) => {
    if (response.success) {
      setAlert({
        title: "Success",
        description: response.message,
        variant: "success",
      });
    } else {
      setAlert({
        title: "Error",
        description: response.message,
        variant: "error",
      });
    }

    const variables = {
      id,
      monthVal,
      loadMore: false,
      month,
    };
    dispatch(getDealerOrders(variables));
    setRefresh((prev) => prev + 1);
  };

  const handleOpenBulkPaymentModal = () => {
    setIsBulkPaymentModalOpen(true);
  };

  const handleCloseBulkPaymentModal = () => {
    setIsBulkPaymentModalOpen(false);
  };

  useEffect(() => {
    if (alert.title) {
      const timer = setTimeout(
        () => setAlert({ title: "", description: "", variant: "" }),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [alert]);

  const buttonStyle = {
    minHeight: "40px",
    fontSize: "14px",
    width: "100%",
  };
  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        {/*start content*/}
        <main className="page-content">
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">Dealer Account View</div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt" />
                    </Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/dealer/list-dealer">List All Dealers</Link>
                  </li>
                  <li className="breadcrumb-item " aria-current="page">
                    <Link to={`/dealer/view-dealer/${id}`}>
                      Dealer Overview
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Account Overview
                  </li>
                </ol>
              </nav>
            </div>
          </div>

          <div className="w-100 shadow-sm rounded-lg p-1">
            <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start align-items-sm-center mb-3">
              <div className="flex-grow">
                <h6 className="h5 font-weight-semibold">
                  Account: Month by month
                </h6>
              </div>
              <div className="d-flex flex-column flex-sm-row gap-2">
                {/* Button to open the modal */}
                {selected.length > 0 ? (
                  <Button
                    variant="contained"
                    style={buttonStyle}
                    className="w-100 w-sm-auto"
                    onClick={handleOpenPaymentModal}
                    sx={{ ...buttonStyle, height: "70px" }}
                  >
                    Receivables
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    style={buttonStyle}
                    className="w-100 w-sm-auto"
                    onClick={handleOpenBulkPaymentModal}
                    sx={{ ...buttonStyle, height: "70px" }}
                  >
                    Receivables
                  </Button>
                )}
                <Button
                  variant="outlined"
                  style={buttonStyle}
                  className="w-100 w-sm-auto"
                  onClick={() => printAccountOrderSheet("pdf")}
                  sx={{ ...buttonStyle, height: "70px" }}
                >
                  <i className="fadeIn animated bx bx-printer" />
                  Print Acc Sheet (PDF)
                </Button>

                <Button
                  variant="outlined"
                  color="success"
                  style={buttonStyle}
                  className="w-100 w-sm-auto"
                  onClick={() => printAccountOrderSheet("excel")}
                  sx={{ ...buttonStyle, height: "70px" }}
                >
                  <i className="fadeIn animated bx bx-spreadsheet" />
                  Print Acc Sheet (Excel)
                </Button>
              </div>
            </div>
          </div>

          <PaymentModal
            open={isPaymentModalOpen}
            onClose={handleClosePaymentModal}
            onSave={handleSavePayment}
            orderId={selected[0]}
            dealerId={id}
            token={userInfo.token}
            selected={selected}
          />

          <BulkPaymentModal
            open={isBulkPaymentModalOpen}
            onClose={handleCloseBulkPaymentModal}
            onSave={handleSavePayment}
            dealerId={id}
            token={userInfo.token}
          />

          {loading ? (
            <Loader />
          ) : (
            <Box sx={{ width: "100%" }}>
              <Paper sx={{ width: "100%", mb: 2 }}>
                <EnhancedTableToolbar
                  numSelected={selected.length}
                  orderMonth={orderMonth}
                  selected={selected}
                  // setLoading={setLoading}
                  month={month}
                  monthVal={monthVal}
                  id={id}
                  setIsPaymentInput={setIsPaymentInput}
                  rows={rows}
                />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={!dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                    rows.sort(getComparator(order, orderBy)).slice() */}
                      {stableSort(rows, getComparator(order, orderBy))
                        //++ If pagination needed
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => {
                          const isItemSelected = isSelected(row.orderId);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={(event) =>
                                handleClick(event, row.orderId)
                              }
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={row.orderId}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  color="primary"
                                  checked={isItemSelected}
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </TableCell>
                              <TableCell
                                component="th"
                                id={labelId}
                                scope="row"
                                padding="none"
                              >
                                {row.orderId.substring(18, 24)}
                              </TableCell>
                              <TableCell align="left">{row.date}</TableCell>
                              <TableCell align="left">
                                {row.productType}
                              </TableCell>
                              <TableCell align="left">{row.tag}</TableCell>
                              <TableCell align="left">{row.quantity}</TableCell>
                              <TableCell align="left">
                                {Object.keys(row.liftStyleSummary || {})
                                  .length > 0 ? (
                                  <>
                                    {Object.entries(row.liftStyleSummary).map(
                                      ([liftStyle, count]) => (
                                        <div key={liftStyle}>
                                          {liftStyle}: {count}
                                          <span className="font-weight-bold">{` qty`}</span>
                                        </div>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>{row.liftStyle}</>
                                )}
                              </TableCell>
                              <TableCell align="left">{row.factor}</TableCell>
                              <TableCell align="left">{`$ ${row.payment}`}</TableCell>
                              <TableCell align="left">
                                {`$ ${row.outstandingBalance}`}{" "}
                              </TableCell>

                              <TableCell align="left">
                                {row.paymentStatus}
                              </TableCell>
                              <TableCell align="left">
                                {row.invoiceStatus}
                              </TableCell>
                              <TableCell align="left">
                                <Link to={`/order/order-detail/${row.orderId}`}>
                                  <VisibilityIcon />
                                  View
                                </Link>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
              <FormControlLabel
                control={
                  <Switch checked={dense} onChange={handleChangeDense} />
                }
                label="Larger padding"
              />
            </Box>
          )}

          <div class="col text-center">
            {loading ? (
              <Loader />
            ) : (
              <>
                <Link
                  to={`/dealer/account-view/${id}?month=${parseInt(month) + 1}`}
                >
                  <button
                    type="button"
                    className="btn btn-primary px-5 mx-5 radius-30 mb-3"
                  >
                    ⬅ Previous Month
                  </button>
                </Link>
                {monthVal >= 0 ? (
                  <>
                    <Link
                      to={`/dealer/account-view/${id}?month=${
                        parseInt(month) - 1
                      }`}
                    >
                      <button
                        type="button"
                        className="btn btn-primary px-5 radius-30 mb-3 "
                      >
                        Next Month ➡
                      </button>
                    </Link>
                  </>
                ) : (
                  <button
                    type="button"
                    disabled
                    className="btn btn-primary px-5 radius-30 mb-3"
                  >
                    Next Month ➡
                  </button>
                )}
              </>
            )}
            {alert.title && (
              <ReusableAlert
                title={alert.title}
                description={alert.description}
                variant={alert.variant}
              />
            )}
          </div>
          <hr className="mt-3 mb-4" />
          <AccountSection dealerId={id} month={month} refresh={refresh} />
          {isPaymentInput && (
            <>
              {selected.length === 1 ? (
                <EditPayment selected={selected} />
              ) : (
                <>{setIsPaymentInput(false)}</>
              )}
            </>
          )}
          <SnackbarComponent />
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
          <i className="bx bxs-up-arrow-alt" />
        </a>
      </div>
    </>
  );
}
