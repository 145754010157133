import {
  ADD_CREDIT,
  GET_CREDIT_FAILURE,
  GET_CREDIT_REQUEST,
  GET_CREDIT_SUCCESS,
  WITHDRAW_CREDIT,
} from "../../constants/CreditSystemConstants";

const CreditState = {
  loading: false,
  creditData: {},
  error: null,
};

export const creditReducer = (state = CreditState, action) => {
  switch (action.type) {
    case GET_CREDIT_REQUEST:
      return { ...state, loading: true };
    case GET_CREDIT_SUCCESS:
      return { ...state, loading: false, creditData: action.payload };
    case GET_CREDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ADD_CREDIT:
    case WITHDRAW_CREDIT:
      return { ...state, loading: false };
    default:
      return state;
  }
};
