import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import axios from "axios";
import { API_URI } from "../../../constants/GlobalConstants";
import ReusableAlert from "../../layout/alert/AlertMessage";
import { useParams } from "react-router-dom";
import { getCreditData } from "../../../redux/actions/CreditSystemActions";
import { useDispatch, useSelector } from "react-redux";

export default function BulkPaymentModal({
  open,
  onClose,
  onSave,
  dealerId,
  token,
}) {
  const {
    creditData,
    loading: creditDataLoading,
    error,
  } = useSelector((state) => state.credit);

  const [amount, setAmount] = useState("");
  const [modeOfPayment, setModeOfPayment] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    title: "",
    description: "",
    variant: "",
  });
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreditData(id));
  }, [dispatch]);



  const handleAmountChange = (event) => setAmount(event.target.value);
  const handleModeOfPaymentChange = (event) =>
    setModeOfPayment(event.target.value);

  const handleSave = async () => {
    if (!amount || isNaN(amount) || parseFloat(amount) <= 0 || !modeOfPayment) {
      onSave({
        success: false,
        message: "Please enter a valid amount and select a mode of payment.",
      });
      return;
    }

    if (modeOfPayment === "ERP Payment" && parseFloat(amount) > creditData.totalCredits) {
      onSave({
        success: false,
        message: `Insufficient credit balance. Your current credit balance is $${creditData.totalCredits}.`,
      });
      return;
    }
    setLoading(true);

    try {
      const response = await axios.put(
        `${API_URI}/api/order/bulk-order-payment`,
        {
          amount,
          dealerId,
          modeOfPayment,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onSave({ success: true, message: response.data.message });
    } catch (error) {
      onSave({
        success: false,
        message: error.response ? error.response.data.message : error.message,
      });
    } finally {
      setLoading(false);
      setAmount("");
      setModeOfPayment("");
      onClose();
    }
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    width: "400px",
  };

  useEffect(() => {
    if (!open) {
      setAmount("");
      setModeOfPayment("");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography
          id="modal-title"
          variant="h6"
          component="h2"
          sx={{ mb: 2, color: "black" }}
        >
          Receivable Bulk Orders
        </Typography>
        <TextField
          label="Amount"
          variant="outlined"
          fullWidth
          margin="normal"
          value={amount}
          onChange={handleAmountChange}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Mode of Payment</InputLabel>
          <Select
            value={modeOfPayment}
            onChange={handleModeOfPaymentChange}
            label="Mode of Payment"
          >
            <MenuItem value="Check">Check</MenuItem>
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
            <MenuItem value="Credit Card">Credit Card</MenuItem>
            <MenuItem value="Bank Remittance">Bank Remittance</MenuItem>
            <MenuItem value="ERP Payment">
              ERP Payment (Remaining Credit: ${creditData?.totalCredits})
            </MenuItem>{" "}
          </Select>
        </FormControl>
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            sx={{ mr: 2 }}
            disabled={loading}
          >
            {loading ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
