import { nanoid } from "nanoid";
import { getDecimal, getFinalVal, nearestGreaterVal, totalDecimal } from "../../../functions/productionSheet/GolbalFunctions";
import { calculateHeadrailFormulas } from "../../../functions/productionSheet/rollerShades/RollerProduction";

export function calculateProduction(
    width,
    height,
    headrailConfig,
    matrix,
    deduction,
    addition
  ) {
    let newWidth = width;
  
    // Apply deduction or addition
    if (deduction) {
      newWidth = getFinalVal(totalDecimal(width) - getDecimal(deduction));
    } else if (addition) {
      newWidth = getFinalVal(totalDecimal(width) + getDecimal(addition));
    }
  
    // Shared calculation logic
    const { heightArray, widthArray, matrix: matrixArray } = matrix;
  
    // Convert matrix values to numbers
    const numericMatrixArray = matrixArray.map(row => row.map(value => parseFloat(value)));
  
    const wFraction = totalDecimal(newWidth);
    const hFraction = totalDecimal(height);
    const dimensions = { width: wFraction, height: hFraction };
  
    let calculatedValues = {};
    if (headrailConfig && headrailConfig.formulas) {
      calculatedValues = calculateHeadrailFormulas(headrailConfig.formulas, dimensions);
    }
  
    const wNearest = nearestGreaterVal(widthArray, wFraction);
    const hNearest = nearestGreaterVal(heightArray, hFraction);
  
    const widthIndex = widthArray.indexOf(wNearest);
    const heightIndex = heightArray.indexOf(hNearest);
  
    const factor = numericMatrixArray[widthIndex][heightIndex];
  
    return {
      id: nanoid(),
      width: newWidth,
      height,
      factor,
      ...calculatedValues
    };
  }
  