import { useState } from 'react';
import { Snackbar, Alert } from '@mui/material';

// Custom Hook to handle Snackbar notifications
export const useSnackbarNotification = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('error');

  const showSnackbar = (message, severity = 'error') => {
    setMessage(message);
    setSeverity(severity);
    setOpen(true);
  };

  const hideSnackbar = () => {
    setOpen(false);
  };

  const SnackbarComponent = () => (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={hideSnackbar}
    >
      <Alert onClose={hideSnackbar} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );

  return {
    showSnackbar,
    SnackbarComponent, 
  };
};
