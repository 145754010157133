import React from "react";
import { Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";

const MaterialPriceSkeletonLoader = () => {
  const rows = 5; 

  return (
    <div style={{ padding: "20px", maxWidth: "1200px", margin: "auto" }}>
    <Typography variant="h4" gutterBottom align="center">
      <Skeleton width="50%" />
    </Typography>

    <Typography variant="h5" align="center" style={{ marginBottom: "20px" }}>
      Loading material pricing data... Please wait.
    </Typography>

    {/* Skeleton for the pricing data */}
    {[...Array(3)].map((_, idx) => (
      <div key={idx} className="mb-4">
        <Typography variant="h5">
          <Skeleton width="50%" />
        </Typography>

        <TableContainer component={Paper} sx={{ maxWidth: "100%", marginBottom: "20px" }}>
          <Table size="small" aria-label="material pricing table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Skeleton width="60%" />
                </TableCell>
                <TableCell align="right">
                  <Skeleton width="40%" />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[...Array(rows)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  <TableCell>
                    <Skeleton width="80%" />
                  </TableCell>
                  <TableCell align="right">
                    <Skeleton width="50px" />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    ))}
  </div>
  );
};

export default MaterialPriceSkeletonLoader;
