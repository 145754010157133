import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getProductTypesAction } from "../../redux/actions/ProductTypeactions";

const ProDependency = ({ proDep, setProDep }) => {
  const productTypesList = useSelector((state) => state.productTypes);
  const { loading, error, productTypes } = productTypesList;
  const userLogin = useSelector((state) => state.userSignIn);
  const { userInfo } = userLogin;
  const dispatch = useDispatch();

  // Fetch product types on component mount
  useEffect(() => {
    dispatch(getProductTypesAction(userInfo.token));
  }, []);

  // Set the initial product dependencies when product types are fetched
  useEffect(() => {
    if (productTypes.length > 0) {
      const fetchedProductNames = productTypes.map((product) => product.name);
      setProDep(fetchedProductNames); 
    }
  }, [productTypes]);

  // Remove a product dependency (tag)
  function removeTag(index) {
    setProDep(proDep.filter((_, i) => i !== index));
  }

  // Reset the product dependencies to the full list of fetched products
  function resetTag(e) {
    e.preventDefault();
    const fetchedProductNames = productTypes.map((product) => product.name);
    setProDep(fetchedProductNames); // Reset to the full list
  }

  return (
    <>
      <label className="form-label test">
        Product Dependency
        <Tooltip
          title={
            <span style={{ fontSize: ".8rem" }}>
              Indicate which products are dependent on this item. For example,
              if your tube is used exclusively in roller shades, select "roller
              shades" as its <span className="text-info">dependency </span>{" "}
            </span>
          }
          arrow
        >
          <IconButton size="small" sx={{ color: "#fff" }}>
            <HelpOutlineIcon fontSize="inherit" />
          </IconButton>
        </Tooltip>
      </label>

      <div className="tags-input-container">
        {/* Render the selected product dependencies (tags) */}
        {proDep.map((tag, index) => (
          <div className="tag-item" key={index}>
            <span className="text text-black">{tag}</span>
            <span className="close" onClick={() => removeTag(index)}>
              &times;
            </span>
          </div>
        ))}

        {/* Reset to the complete product type list */}
        <button
          className="btn btn-sm btn-warning px-5 radius-30"
          onClick={resetTag}
          disabled={loading || error} // Disable reset if loading or error occurred
        >
          {loading ? "Loading..." : "Reset Product Dependency"}
        </button>

        {/* Show error if there's any */}
        {error && <p className="text-danger">Failed to load product types: {error}</p>}
      </div>
    </>
  );
};

export default ProDependency;
