import { getProductionData } from "../functions/OrderFunctions";

export const recalculateOrders = (updatedOrder, productType, calculationValue, setOrder, deduction, addition,matrix) => {
  
  const recalculatedOrder = updatedOrder.map((row) => {
      const {
        width,
        height,
        control,
        lift,
        mount,
        remark,
        location,
        tile,
        liftStyle
        // deduction,
        // addition,
      } = row;
  
      if (width && height && productType) {
        return getProductionData(
          productType,
          width,
          height,
          control,
          lift,
          mount,
          remark,
          location,
          calculationValue,
          deduction,
          addition,
          tile,
          matrix,
          liftStyle
        );
      }
      return row;
    });
  
    setOrder(recalculatedOrder);
  };