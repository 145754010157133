import axios from "axios";
import { API_URI } from "../../constants/GlobalConstants";
import { APP_SETTINGS_FETCH_FAIL, APP_SETTINGS_FETCH_REQUEST, APP_SETTINGS_FETCH_SUCCESS, USER_SETTINGS_UPDATE_FAIL, USER_SETTINGS_UPDATE_REQUEST, USER_SETTINGS_UPDATE_SUCCESS } from "../../constants/UserSettingsConstants";

// Action to fetch settings
export const fetchSettingsAction = (token) => async (dispatch) => {
    try {
      dispatch({ type: "SETTINGS_REQUEST" });
      
    const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${API_URI}/api/user-settings/settings`,
        config
      );      
      dispatch({ type: "SETTINGS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({ type: "SETTINGS_FAIL", payload: error.message });
    }
  };

  export const changeUserSettings = (token,newSettings) => async (dispatch, getState) => {
    try {
      dispatch({ type: USER_SETTINGS_UPDATE_REQUEST });
  
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      
      const { data } = await axios.post(`${API_URI}/api/user-settings/update-usersettings`, newSettings, config);

      dispatch({
        type: USER_SETTINGS_UPDATE_SUCCESS,
        payload: data,
      });
      dispatch(fetchSettingsAction(token));

    } catch (error) {
      dispatch({
        type: USER_SETTINGS_UPDATE_FAIL,
        payload: error.response && error.response.data.message ? error.response.data.message : error.message,
      });
    }
  };

  //app setting fetching
  export const fetchAppSettings = () => async (dispatch, getState) => {
    try {
      dispatch({ type: APP_SETTINGS_FETCH_REQUEST });
  
      const {
        userSignIn: { userInfo },
      } = getState();
  
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
  
      const { data } = await axios.get(`${API_URI}/api/app/application-settings`, config);
  
      dispatch({ type: APP_SETTINGS_FETCH_SUCCESS, payload: data.settings });
    } catch (error) {
      dispatch({
        type: APP_SETTINGS_FETCH_FAIL,
        payload: error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
      });
    }
  };