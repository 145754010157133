import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Box,
  Typography,
  Button,
  MenuItem,
  Select,
} from "@mui/material";
import { getCreditData } from "../../../redux/actions/CreditSystemActions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const EnhanceTTPaymentModal = ({
  open,
  onClose,
  onConfirm,
  modeOfPayment,
  handleModeOfPaymentChange,
}) => {
  const {
    creditData,
    loading: creditDataLoading,
    error,
  } = useSelector((state) => state.credit);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCreditData(id));
  }, [dispatch]);
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: 400,
          padding: 2,
          backgroundColor: "white",
          margin: "auto",
          marginTop: "10%",
        }}
      >
        <Typography variant="h6">Select Payment Mode</Typography>
        <Select
          value={modeOfPayment}
          onChange={handleModeOfPaymentChange}
          label="Mode of Payment"
          fullWidth
          sx={{ marginTop: 2 }}
        >
          <MenuItem value="Check">Check</MenuItem>
          <MenuItem value="Cash">Cash</MenuItem>
          <MenuItem value="Bank Transfer">Bank Transfer</MenuItem>
          <MenuItem value="Credit Card">Credit Card</MenuItem>
          <MenuItem value="Bank Remittance">Bank Remittance</MenuItem>
          <MenuItem value="ERP Payment">
            ERP Payment (Remaining Credit: ${creditData?.totalCredits})
          </MenuItem>{" "}
        </Select>
        <Box sx={{ marginTop: 2, textAlign: "right" }}>
          <Button onClick={onClose} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              onConfirm(modeOfPayment);
              onClose();
            }}
            variant="contained"
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

EnhanceTTPaymentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  modeOfPayment: PropTypes.string.isRequired,
  handleModeOfPaymentChange: PropTypes.func.isRequired,
};

export default EnhanceTTPaymentModal;
