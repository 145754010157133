import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import InvoiceListingComp from "./InvoiceListingComp";


const ListInvoices = ({ nav }) => {
  
  return (
    <>
      <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        <main className="page-content">
          {/* Breadcrumb */}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center">
            <div className="breadcrumb-title pe-3 text-white">
              Product Management
            </div>
            <div className="ps-3">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0 p-0">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="bx bx-home-alt text-white" />
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    List Invoices
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <InvoiceListingComp />
          
        </main>
      </div>
    </>
  );
};

export default ListInvoices;
