import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";

const LiftStyleConfigTable = ({
  liftStyleConfigList,
  handleLiftStyleConfigChange,
  handleLiftStyleUpdate,
  motorConfigList,
  handleMotorConfigChange,
  handleMotorUpdate,
}) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderTable = (configList, handleConfigChange, handleUpdate) => (
    <div className="table-responsive mt-4">
      <table className="table table-bordered table-hover">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Product Type</th>
            <th>Addon Price</th>
            <th>Unit Rate</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(configList) && configList.length > 0 ? (
            configList.map((config, index) => {
              const productPricing = config.productPricing;
              const productEntries = Object.entries(productPricing);

              return productEntries.length > 0 ? (
                productEntries.map(
                  ([productName, productConfig], productIndex) => (
                    <React.Fragment key={`${config.id}-${productName}`}>
                      <tr>
                        {productIndex === 0 && (
                          <td rowSpan={productEntries.length}>{config.name}</td>
                        )}
                        <td>{productName}</td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={productConfig?.addonPrice ?? ""}
                            required
                            onChange={(e) => {
                              const newValue =
                                e.target.value === ""
                                  ? ""
                                  : parseFloat(e.target.value);
                              handleConfigChange(
                                index,
                                productName,
                                "addonPrice",
                                isNaN(newValue) ? 0 : newValue
                              );
                            }}
                          />
                        </td>
                        <td>
                          <select
                            className="form-control"
                            value={productConfig?.unitRate ?? "individual"}
                            onChange={(e) => {
                              handleConfigChange(
                                index,
                                productName,
                                "unitRate",
                                e.target.value
                              );
                            }}
                            disabled
                          >
                            <option value="individual">individual</option>
                          </select>
                        </td>
                      </tr>
                    </React.Fragment>
                  )
                )
              ) : (
                <tr key={config.id}>
                  <td colSpan={3}>
                    No products available for this configuration.
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={3}>Loading or no data available.</td>
            </tr>
          )}
        </tbody>
      </table>
      <button
        type="button"
        className="btn btn-primary mt-4"
        onClick={handleUpdate}
      >
        Update Config
      </button>
    </div>
  );

  return (
    <div>
      {/* MUI Tabs */}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="configuration tabs"
        sx={{
          "& .MuiTab-root": {
            color: "white",
            "&.Mui-selected": {
              color: "white",
              fontWeight: "bold",
            },
          },
        }}
      >
        <Tab label="Lift Hardwares" />
        <Tab label="Motor" />
      </Tabs>

      {/* Render corresponding table based on the active tab */}
      {tabValue === 0
        ? renderTable(
            liftStyleConfigList,
            handleLiftStyleConfigChange,
            handleLiftStyleUpdate
          )
        : renderTable(
            motorConfigList,
            handleMotorConfigChange,
            handleMotorUpdate
          )}
    </div>
  );
};

export default LiftStyleConfigTable;
