import React, { useEffect } from "react";
import Header from "../../components/layout/Header";
import Sidebar from "../../components/layout/Sidebar";
import { Link, useParams } from "react-router-dom";
import InvoiceDetailsComp from "../../apps/invoiceGenerator/InvoiceDetailsComp";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoiceData } from "../../redux/actions/InvoiceActions";

const InvoiceDetailsPage = ({ nav }) => {
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.userSignIn);
  const { documentType } = useSelector(
    (state) => state.customInvoiceById
  );
  const dispatch = useDispatch();


  useEffect(() => {
    if (userInfo.token) {
      dispatch(fetchInvoiceData(id, userInfo.token));
    }
  }, [dispatch, id, userInfo.token]);

  return (
    <div className={nav.isSidebar}>
      <Header nav={nav} />
      <Sidebar nav={nav} />
      <main className="page-content">
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
          <div className="breadcrumb-title pe-3">Invoice Management</div>
          <nav aria-label="breadcrumb" className="ps-3">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <Link to="/">
                  <i className="bx bx-home-alt" />
                </Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Invoice
              </li>
            </ol>
          </nav>
        </div>
        <hr />
        <div className="row">
          <div className="col-xl-12 mx-auto">
            <h6 className="mb-0">
              <i className="fadeIn animated bx bx-line-chart" />
              {documentType === "Estimate" ? "Estimate Details" : "Invoice Details"}
              </h6>
          </div>
        </div>
        <InvoiceDetailsComp />
      </main>
    </div>
  );
};

export default InvoiceDetailsPage;
