import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router';
import Header from '../../components/layout/Header';
import Sidebar from '../../components/layout/Sidebar';
import { Link } from 'react-router-dom';
import AllCalculations from '../../components/appSettings/AllCalculations';
import { useSelector } from 'react-redux';

const CalculationsList = ({nav}) => {

  const navigate = useNavigate()

  const userLogin = useSelector(state => state.userSignIn)
  const { userInfo } = userLogin

  useEffect(() => {
      if (!userInfo) {
          navigate('/sign-in')
      }
      window.scrollTo(0, 0)
  }, [userInfo, navigate])

  return (
    <>
    <div className={nav.isSidebar}>
        <Header nav={nav} />
        <Sidebar nav={nav} />
        {/*start content*/}
        <main className="page-content">
            {/*breadcrumb*/}
            <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
                <div className="ps-3">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 p-0">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    <i className="bx bx-home-alt" />
                                </Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                Settings
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                All calculations
                            </li>
                        </ol>
                    </nav>
                </div>
            </div>
            {/*end breadcrumb*/}
            <AllCalculations />
            {/*end row*/}
        </main>

        <div className="overlay nav-toggle-icon" />
        <a href="" className="back-to-top">
            <i className="bx bxs-up-arrow-alt" />
        </a>
    </div>
</>
  );
};

export default CalculationsList;
