import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
  Alert,
  Stepper,
  StepLabel,
  Step,
  MenuItem,
} from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { API_URI } from "../../../constants/GlobalConstants";
import { useParams } from "react-router";
import {
  ViewDealerAction,
  getDealerOrders,
} from "../../../redux/actions/DealerAction";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import TaxSelectionForm from "./InvoiceTax";
import { useSearchParams } from "react-router-dom";
import { provinces } from "../../../constants/InvoiceConstants";

function SendInvoiceModal({
  open,
  onClose,
  selectedOrderId,
  userInfo,
  monthVal,
}) {
  const [activeStep, setActiveStep] = useState(0);
  const [clientInfo, setClientInfo] = useState({
    email: "",
    name: "",
    salesPerson: "",
    phone: "",
    date: new Date().toISOString().slice(0, 10),
    address: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
  });
  const [searchParams] = useSearchParams();
  const month = searchParams.get("month") || 0;

  const [loading, setLoading] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    loading: dealerLoading,
    dealer,
    success: detailSuccess,
  } = useSelector((state) => state.viewDealer);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedTaxes, setSelectedTaxes] = useState([]);
  const [invoiceData, setInvoiceData] = useState({
    totalAmount: 0,
    totalTax: 0,
    subTotal: 0,
  });

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id && userInfo.token) {
      dispatch(ViewDealerAction(id, userInfo.token));
    }
  }, [dispatch, id, userInfo.token]);

  useEffect(() => {
    if (detailSuccess && dealer) {
      setClientInfo((prev) => ({
        ...prev,
        email: dealer.email || "",
        name: dealer.name || "",
        phone: dealer.phone || "",
      }));
    }
  }, [detailSuccess, dealer]);

  useEffect(() => {
    const fetchInvoicePdf = async () => {
      if (open && selectedOrderId) {
        try {
          setPdfLoading(true);

          const res = await axios.get(`${API_URI}/api/app/invoice-generator`, {
            headers: {
              Authorization: `Bearer ${userInfo.token}`,
            },
            params: {
              orderIds: selectedOrderId,
              clientInfo: JSON.stringify(clientInfo),
              selectedTaxes: selectedTaxes,
              dealerId:id
            },
          });

          const pdfBase64 = res.data.pdf;
          if (pdfBase64) {
            // Convert base64 PDF to Blob
            const pdfBlob = new Blob(
              [Uint8Array.from(atob(pdfBase64), (c) => c.charCodeAt(0))],
              { type: "application/pdf" }
            );

            const pdfBlobUrl = URL.createObjectURL(pdfBlob);
            setPdfUrl(pdfBlobUrl);
            setInvoiceData({
              totalAmount: res.data.totalAmount,
              totalTax: res.data.totalTax,
              subTotal: res.data.subtotal,
            });
          } else {
            throw new Error("PDF data is missing in the response");
          }
        } catch (error) {
          console.error("Error generating invoice:", error);
          setError("Failed to generate PDF.");
        } finally {
          setPdfLoading(false);
        }
      }
    };

    fetchInvoicePdf();
  }, [open, selectedOrderId, userInfo.token, selectedTaxes]);

  const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setClientInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleSendInvoice = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${API_URI}/api/app/invoice-get-data`,
        { orderIds: selectedOrderId, clientInfo, selectedTaxes },
        {
          headers: {
            Authorization: `Bearer ${userInfo.token}`,
          },
        }
      );

      if (response.status === 200) {
        if (pdfUrl) {
          const pdfBlob = await fetch(pdfUrl).then((res) => res.blob());
          fileDownload(
            pdfBlob,
            `Invoice-${clientInfo.name}-${new Date()
              .toISOString()
              .slice(0, 10)}.pdf`
          );
        }
        const variables = {
          id,
          monthVal,
          loadMore: false,
          month,
        };
        dispatch(getDealerOrders(variables));
        onClose();
      } else {
        throw new Error(
          "Failed to generate invoice data. Check app password and email"
        );
      }
    } catch (error) {
      console.error("Error sending invoice data:", error);
      setError(
        "Failed to send the invoice. Please check app email and password and try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const steps = ["Select Tax", "Send Invoice"];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          ...style,
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "90vh",
          width: "90vw",
          maxWidth: "1200px",
        }}
      >
        <Stepper
          activeStep={activeStep}
          sx={{
            mb: 3,
            width: "100%",
            backgroundColor: "background.default",
            p: 2,
            borderRadius: 2,
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {activeStep === 0 && (
          <Box sx={{ p: 2 }}>
            <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
              Select Applicable Taxes
            </Typography>
            <TaxSelectionForm
              selectedTaxes={selectedTaxes}
              setSelectedTaxes={setSelectedTaxes}
              invoiceData={invoiceData}
              pdfLoading={pdfLoading}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                mt: 3,
                width: "100%",
              }}
            >
              <Button
                onClick={handleNext}
                variant="contained"
                sx={{
                  ml: "30%",
                }}
              >
                Next
              </Button>
            </Box>
          </Box>
        )}

        {activeStep === 1 && (
       <Box
       sx={{
         display: "flex", 
         flexDirection: "row", 
         width: "100%",
         height: "100%",
         gap: 2, 
         overflow: "hidden", 
       }}
     >
       {/* Left side: Send Invoice Form */}
       <Box
         sx={{
           width: "50%", 
           height: "100%",
           display: "flex",
           flexDirection: "column",
           padding: "24px",
           overflow: "auto", 
         }}
       >
         <Typography
           variant="h6"
           component="h2"
           sx={{ color: "black", mb: 2 }}
         >
           Send Invoice
         </Typography>
     
         <Box
           sx={{
             display: "grid",
             gridTemplateColumns: "1fr 1fr",
             gap: 2,
           }}
         >
           {/* Form Fields */}
           <TextField
             fullWidth
             label="Email"
             name="email"
             value={clientInfo.email}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="Client Name"
             name="name"
             value={clientInfo.name}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="Salesperson"
             name="salesPerson"
             value={clientInfo.salesPerson}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="Phone Number"
             name="phone"
             value={clientInfo.phone}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             type="date"
             label="Date"
             name="date"
             value={clientInfo.date}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="Address"
             name="address"
             value={clientInfo.address}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="Street"
             name="street"
             value={clientInfo.street}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             label="City"
             name="city"
             value={clientInfo.city}
             onChange={handleChange}
           />
           <TextField
             fullWidth
             select
             label="Province"
             name="province"
             value={clientInfo.province}
             onChange={handleChange}
           >
             {provinces.map((province) => (
               <MenuItem key={province} value={province}>
                 {province}
               </MenuItem>
             ))}
           </TextField>
           <TextField
             fullWidth
             label="Postal Code"
             name="postalCode"
             value={clientInfo.postalCode}
             onChange={handleChange}
           />
         </Box>
     
         {error && (
           <Alert severity="error" sx={{ mt: 2 }}>
             {error}
           </Alert>
         )}
     
         <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
           <Button onClick={onClose} sx={{ mr: 2 }}>
             Cancel
           </Button>
           <Button
             onClick={handleSendInvoice}
             disabled={loading}
             variant="contained"
             sx={{ mr: 2 }}
           >
             {loading ? <CircularProgress size={24} /> : "Send"}
           </Button>
           <Button
             onClick={handleBack}
             variant="contained"
             color="secondary"
           >
             Previous
           </Button>
         </Box>
       </Box>
     
       {/* Right side: PDF Preview with loader */}
       <Box
         sx={{
           width: "50%", 
           height: "100%",
           paddingLeft: 3,
           display: "flex",
           justifyContent: "center",
           alignItems: "center",
           overflow: "hidden", 
           padding:4
         }}
       >
         {pdfLoading ? (
           <CircularProgress size={50} />
         ) : pdfUrl ? (
           <iframe
             src={pdfUrl}
             title="Invoice PDF"
             style={{ width: "100%", height: "100%", border: "none" }}
           />
         ) : (
           <Typography variant="h6">PDF is not available.</Typography>
         )}
       </Box>
     </Box>
     
          
        )}
      </Box>
    </Modal>
  );
}

SendInvoiceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedOrderId: PropTypes.number.isRequired,
  userInfo: PropTypes.object.isRequired,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
};

export default SendInvoiceModal;
