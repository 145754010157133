import axios from "axios";
import {
    ADD_CREDIT,
  GET_CREDIT_FAILURE,
  GET_CREDIT_REQUEST,
  GET_CREDIT_SUCCESS,
  WITHDRAW_CREDIT,
} from "../../constants/CreditSystemConstants";
import { API_URI } from "../../constants/GlobalConstants";

export const getCreditData = (dealerId,page,limit) => async (dispatch, getState) => {
  dispatch({ type: GET_CREDIT_REQUEST });

  const { userInfo } = getState().userSignIn;

  try {
    const response = await axios.get(
        `${API_URI}/api/credit-system/${dealerId}?page=${page}&limit=${limit}`,
        {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      }
    );
    dispatch({
      type: GET_CREDIT_SUCCESS,
      payload: response.data.creditData,
    });
  } catch (error) {
    dispatch({
      type: GET_CREDIT_FAILURE,
      payload: error.message || "Failed to fetch credit data",
    });
  }
};


export const addCredit = (dealerId, amount, description) => async (dispatch,getState) => {
    const { userInfo } = getState().userSignIn;

    try {
      const response = await axios.post(`${API_URI}/api/credit-system/${dealerId}/deposit`, {
        amount,
        description,
      },{
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
  
      dispatch({
        type: ADD_CREDIT,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error adding credit:', error);
    }
  };
  
  // Withdraw credit (debit)
  export const withdrawCredit = (dealerId, amount, description) => async (dispatch,getState) => {
    const { userInfo } = getState().userSignIn;

    try {
      const response = await axios.post(`${API_URI}/api/credit-system/${dealerId}/withdraw`, {
        amount,
        description,
      },{
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
  
      dispatch({
        type: WITHDRAW_CREDIT,
        payload: response.data,
      });
    } catch (error) {
      console.error('Error withdrawing credit:', error);
    }
  };