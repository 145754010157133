import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { fetchInvoiceData } from "../../redux/actions/InvoiceActions";
import { API_URI } from "../../constants/GlobalConstants";

const DeleteConfirmationModal = ({
  open,
  onClose,
  onConfirm,
  invoiceId,
  token,
  showSnackbar,
  setDeleteLoading
}) => {
  const dispatch = useDispatch();

  const handleDeleteInvoice = async () => {
    try {
      setDeleteLoading(true)
      const response = await axios.delete(
        `${API_URI}/api/invoices/invoices/${invoiceId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      showSnackbar(
        response.data.message || "Invoice deleted successfully!",
        "success"
      );

      dispatch(fetchInvoiceData(invoiceId, token));
      onConfirm();
    } catch (error) {
      showSnackbar(
        error.response?.data?.message ||
          "An error occurred while deleting the invoice.",
        "error"
      );
    } finally {
      setDeleteLoading(false)
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        Are you sure you want to delete this invoice? This action cannot be
        undone.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDeleteInvoice} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
